import React from 'react';
import './tripActivity.scss';
import Tripactivity from '../../../static/tripActivity';
import EmptyState from '../../emptyState/emptyState';

const TripActivity = () => {
    return ( 
        <div className="trip-activityx">
            <div className="title">Trips Activity</div>
            <EmptyState />
            {/* {Tripactivity.map(({ tripDate, tripStatus, driver, tripDetail }, index) => {
                return (
                    <div className="activity" key={index}>
                       <p className="trip-date"><i className="fas fa-circle"></i>{tripDate}</p>
                       <p className="trip-action">{driver} updated the trip status to: {tripStatus}.</p>
                       <p className="trip-detail">Trip: {tripDetail}</p>
                    </div>
                )
            })} */}
        </div>
     );
}
 
export default TripActivity;