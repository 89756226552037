import React from "react";
import './smallLoader.css';

const SmallLoader = () => {
  return (
    <div class="lds-dual-ring"></div>
  );
};

export default SmallLoader;
