import React from 'react';
import './userModal.scss';
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { logout } from '../../../redux/actions/auth'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

const UserModal = ({setShowUserModal}) => {
    const currentUser = useSelector(({userData}) => userData.currentUser)
    console.log(currentUser?.data)
    const { userClass } = currentUser?.data

    const dispatch = useDispatch();
    const history = useHistory();

    const handleLogout = () => {
        dispatch(logout(history))
    }

    return ( 
        <div className="user-modal">
            <div className="top-head">
                {/* <div className="img" style={{backgroundImage: `url(${currentUser ? currentUser?.data?.profilePicture : null})`, backgroundSize: 'cover'}}>
                
                </div> */}
                {/* <div className="rightx">
                    <p className="user-name">{currentUser ? currentUser?.data?.name : 'user'}</p>
                    <p className="user-email">{currentUser ? currentUser?.data?.email : 'email'}</p>
                    <p className="btny">View Profile</p>
                </div> */}
            </div>
            <ul className="actions">
               <Link onClick={() => setShowUserModal(false)} style={{textDecoration: 'none', color: '#113E82'}} to='/dashboard/ticket'><li><i className="fas fa-inbox"></i>Inbox</li></Link>
               {/* <Link style={{textDecoration: 'none', color: '#113E82'}} to='/dashboard/notifications'><li><i className="fas fa-bell"></i>Notification</li></Link> */}
               {userClass === "superAdmin" && <Link onClick={() => setShowUserModal(false)} style={{textDecoration: 'none', color: '#113E82'}} to='/dashboard/settings'><li><i className="fas fa-cog"></i>Settings</li></Link>}
               <li onClick={handleLogout}><i className="fas fa-sign-out-alt"></i>Log out</li>
            </ul>
        </div>
     );
}
 
export default UserModal;