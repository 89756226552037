import React from 'react';
import './overview.scss';
import { getTripAnalytics } from '../../../services/tripServices';
import { useQuery } from 'react-query';
import Total from '../../../assets/totalTripsIcon.svg';
import Completed from '../../../assets/completedTripsIcon.svg';
import Active from '../../../assets/activeTripsIconx.svg';
import Cancelled from '../../../assets/cancelledTripsIcon.svg';


const Overview = () => {
    
    const { data, error, isLoading } = useQuery('getTripAnalytics', getTripAnalytics)
    if(isLoading) {
        console.log('data is loading')
    }else if(data) {
        console.log('getTripAnalytics', data.data.data)
    } else {
        console.log(error)
    }
    
    return ( 
        <div className="overview">
            
                    <div className="box" style={{backgroundImage: 'linear-gradient(0deg, #FFBB00 0%, #FFD04D 100%)'}}>
                       <div className="info">
                          <p className="title">Total Trips</p>
                          <p className="count">{data?.data?.data?.totalTrips}</p>
                       </div>
                       <img className='icon' src={Total} alt="icc" />
                    </div>
                    <div className="box" style={{backgroundImage: 'linear-gradient(180deg, #40D192 0%, #37AB78 100%)'}}>
                       <div className="info">
                          <p className="title">Completed Trips</p>
                          <p className="count">{data?.data?.data?.completedTrips}</p>
                       </div>
                       <img className='icon reducable' src={Completed} alt="icc" />
                    </div>
                    <div className="box" style={{backgroundImage: 'linear-gradient(0deg, #113E82 0%, #7598CE 100%)'}}>
                       <div className="info">
                          <p className="title">Active Trips</p>
                          <p className="count">{data?.data?.data?.onGoingTrips}</p>
                       </div>
                       <img className='icon reducable' src={Active} alt="icc" />
                    </div>
                    <div className="box" style={{backgroundImage: 'linear-gradient(180deg, #F3402B 0%, #E61700 100%)'}}>
                       <div className="info">
                          <p className="title">Cancelled Trips</p>
                          <p className="count">{data?.data?.data?.cancelledTrips}</p>
                       </div>
                       <img className='icon' src={Cancelled} alt="icc" />
                    </div>

                
        </div>
     );
}
 
export default Overview;
