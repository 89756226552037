import React, { useState } from 'react';
// import AddCustomer from '../request-components/add-customer/addCustomer';
import './vehicles.scss';
// import CustomerList from '../../static/customers';
import Overview from '../../components/dashboard-components/overview/overview';
// import ViewCustomer from './view-customer/viewCustomer';
import { useQuery } from 'react-query';
import { getAllVehicles } from '../../services/vehicleServices';
import Loader from '../../components/loader/loader';
import axios from 'axios';
import { baseUrl } from '../../config.json'
import { toast, ToastContainer } from 'react-toastify'
import AddVehicle from './addVehicle/addVehicle';
import { useSelector } from 'react-redux';
import EditVehicle from './editVehicle/editVehicle';

const config = {
  headers: {
     "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
     }
}


const Vehicles = () => {
    const [vehicleView, setVehicleView] = useState(false);
    const [editVehicleView, setEditVehicleView] = useState(false);

    const currentUser = useSelector(({userData}) => userData.currentUser)
    console.log(currentUser?.data)
    const { userClass } = currentUser?.data



    const [vType, setVType] = useState('');
    const [cat, setCat] = useState('');
    const [mippk, setMiPpk] = useState('');
    const [mappk, setMaPpk] = useState('');
    const [commission, setCommission] = useState('');
    const [constant, setConstant] = useState('');
    const [desc, setDesc] = useState('');
    const [vId, setVId] = useState('');

    const { data, error, isLoading } = useQuery('getAllVehicles', getAllVehicles);
    if(isLoading) {
      console.log('loading')
    } else if (data) {
      console.log('getAllVehicles', data.data)
    } else {
      console.log(error)
    }


    const deleteVehicle = (id) => {
      axios.delete(`${baseUrl}/admin/delete-vehicle/${id}`, config)
      .then(res => toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER
      }))
      .then(res => window.location.reload())
      .catch(err => toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER
      }))

    }

    const handleEdit = async (vehicleType, category, minpricePerKilometer, maxpricePerKilometer, wehaulCommission, wehaulConstantValue, description, id) => {
      await setVType(vehicleType)
      await setCat(category)
      await setMiPpk(minpricePerKilometer)
      await setMaPpk(maxpricePerKilometer)
      await setCommission(wehaulCommission)
      await setConstant(wehaulConstantValue)
      await setDesc(description)
      await setVId(id)
      setEditVehicleView(true)
    }

    

    return ( 
        <div className="customers">
            <Overview />

            <div className="heading">
                <div className="left-items">
                    <div className="title"><i onClick={() => window.location.href = '/dashboard'} className="fas fa-home"></i><span>Vehicles</span></div>
                    <p className="request-count">{data?.data?.data.length}</p>
                    {/* <p className="export-btn">Export</p> */}
                </div>
                {userClass === "superAdmin" && <div className="right-items">
                    <button onClick={() => setVehicleView(true)} className="add-v">Add Vehicle</button>
                </div>}
            </div>

            <table>
        <tr className="label-row">
          <th>Name</th>
          <th>Price/KM</th>
          <th>Wehaul Constant</th>
          <th>Commission</th>
          <th>Description</th>
          <th>Action</th>
        </tr>
        {isLoading ? <Loader />  : data?.data?.data.map(
          ({
            vehicleType,
            pricePerKilometer,
            description,
            wehaulConstantValue,
            wehaulCommission,
            category,
            _id: id
          }, index) => {
            return (
                <tr className="content-row" key={id}>
                  <td className="lefty pre"><p>{vehicleType}</p></td>
                  <td>{pricePerKilometer.minPrice} - {pricePerKilometer.maxPrice}</td>
                  <td>{wehaulConstantValue}</td>
                  <td>{wehaulCommission}%</td>
                  <td>{description}</td>
                  <td>
                    <i onClick={() => handleEdit(vehicleType, category, pricePerKilometer.minPrice, pricePerKilometer.maxPrice, wehaulCommission, wehaulConstantValue, description, id)} style={{cursor: 'pointer', fontSize: '1.1em', color: '#113E82',margin: '0 5px', backgroundColor: '#e0f5f5', padding: '6px', borderRadius: '2px'}} className="fas fa-edit"></i>
                    {userClass === "superAdmin" && <i onClick={() => deleteVehicle(id)} style={{cursor: 'pointer', fontSize: '1.1em', color: '#113E82',margin: '0 5px', backgroundColor: '#e0f5f5', padding: '6px', borderRadius: '2px'}} className="fas fa-trash"></i>}
                    
                  </td>
                </tr>
                )
          })}
        
      </table>
      {vehicleView && <AddVehicle setVehicleView={setVehicleView} />}
      {editVehicleView && <EditVehicle setEditVehicleView={setEditVehicleView} eId={vId} eVt={vType} eCat={cat} emiPpk={mippk} emaPpk={mappk} eCom={commission} eConst={constant} eDesc={desc}/>}
        </div>
     );
}
 
export default Vehicles;