import React, {useState} from 'react';
import './payout.scss';
import {baseUrl} from '../../../config.json';
import { toast } from 'react-toastify';
import EmptyState from '../../emptyState/emptyState';
import ButtonLoader from '../../buttonLoader/buttonLoader';
import axios from 'axios';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ExportJsonCsv } from 'react-export-json-csv';
import { CSVLink } from 'react-csv';

const Payouts = ({data}) => {
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const currentUser = useSelector(({userData}) => userData.currentUser)
    console.log(currentUser?.data)
    const { userClass } = currentUser?.data


    const confirmPayout = async (id) => {
        setLoading(true)
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}` }
          
      };
      try {
          await fetch(`${baseUrl}/admin/finance/confirm-payout/${id}`, requestOptions)
          .then(res => res.json())
          .then(res => 
                toast.success(res.message, {
                          position: toast.POSITION.TOP_CENTER
                      })
              )
              .then(res => window.location.reload())
      } catch (error) {
          toast.error(error?.message, {
                     position: toast.POSITION.TOP_CENTER
                 })
                 setLoading(false)
      }
      
  }
    const rejectPayout = async (id) => {
        setLoading2(true)
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}` }
          
      };
      try {
          await fetch(`${baseUrl}/admin/finance/reject-payout/${id}`, requestOptions)
          .then(res => res.json())
          .then(res => 
                toast.info(res.message, {
                          position: toast.POSITION.TOP_CENTER
                      })
              )
          .then(res => window.location.reload())
      } catch (error) {
        setLoading2(false)
          toast.error(error?.message, {
                     position: toast.POSITION.TOP_CENTER
                 })
                
      }

      
  }



const headers = [
    {
        key: 'name',
        name: 'Name',
    },
    {
        key: 'email',
        name: 'Email',
    },
    {
        key: 'phoneNumber',
        name: 'Phone Number',
    },
    {
        key: 'amount',
        name: 'Amount',
    },
    {
        key: 'status',
        name: 'Status',
    },
    {
        key: 'date',
        name: 'Date Requested',
    },
    {
        key: 'userAccountInformation.bankName',
        name: 'Bank Name',
    },
    {
        key: 'userAccountInformation.accountNumber',
        name: 'Account Number',
    },
    {
        key: 'userAccountInformation.accountName',
        name: 'Account Name',
    },
    {
        key: 'userAccountInformation.accountType',
        name: 'Account Type',
    },
  ];
    
    

    return ( 
        <div className="withdrawals">
            {data?.length === 0 ? <EmptyState /> : 
            <div className="cover">
            <p className='top-title'>Recent Withdrawal</p>
            <div className="btn">
            {/* <CSVLink data={data} headers={headers}>
                Download me
            </CSVLink> */}
            <ExportJsonCsv  headers={headers} items={data}>Export</ExportJsonCsv>
            </div>
            
            <table>
                <tr className='label-row'>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>UserType</th>
                    <th>Wallet Bal.</th>
                    <th>Amount</th>
                    <th>Date</th>
                    <th>Action</th>
                </tr>
                    
                    {data?.map(({ walletBalance, name, phoneNumber, userType, status, amount, date, wehaulAmount, pickUpDate, origin, destination, vehicleType, user, userId, _id: id }, index) => {
                        return (
                            <tr className='content-row' key={id}>
                               <td>{name}</td>
                               <td>{phoneNumber}</td>
                               <td>{userType}</td>
                               {/* {status === 'unpaid' && <td><p className="unpaid">{status}</p></td>}
                               {status === 'paid' && <td><p className="paid">{status}</p></td>} */}
                               <td>N{walletBalance.toLocaleString()}</td>
                               <td>{amount}</td>
                               <td>{moment().calendar(date)}</td>
                               <td>
                                   {status === 'unpaid' ?
                                   userClass === "superAdmin" &&
                                   <div>
                                       <button onClick={() => confirmPayout(id)} className="approve">{loading ? <ButtonLoader /> : <i className="fas fa-check"></i>}</button>
                                       <button onClick={() => rejectPayout(id)} className="decline">{loading2 ? 'wait' : <i className="fas fa-times"></i>}</button>
                                   </div>
                                   :
                                   <button className={status === 'rejected' ? 'rejected button' : 'approved button'}>{status}</button>
                                   }
                               </td>
                               {/* <td>{wehaulAmount}</td> */}
                            </tr>
                        )
                    })}
            </table>
            </div>
            }
            
            
        </div>
     );
}
 
export default Payouts;