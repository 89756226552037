import React from 'react';
import './sidebarCopy.scss';
import Logo from '../../assets/logo.svg';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SidebarCopy = ({setMobileNavOpen}) => {
    const currentUser = useSelector(({userData}) => userData.currentUser)
    console.log(currentUser?.data)
    const { userClass } = currentUser?.data

    const { pathname } = useLocation();
    return (
        <div className="wrapp">
            <div className="side-bar-copy">
            <div className="close" onClick={() => setMobileNavOpen(false)}>
                <i className="fas fa-times"></i>
            </div>
            <div className="logo">
                <img src={Logo} alt="" />
            </div>

            <div className="nav">
                <NavLink onClick={() => setMobileNavOpen(false)} activeClassName='active' className='list-item' exact to='/dashboard'><i className="fas fa-home"></i><span className='label'>Dashboard</span></NavLink>
                {userClass === "superAdmin" && <NavLink onClick={() => setMobileNavOpen(false)} activeClassName='active' className='list-item' exact to='/dashboard/admins'><i className="fas fa-user-tie"></i><span className='label'>Admins</span></NavLink>}
                <NavLink onClick={() => setMobileNavOpen(false)} activeClassName='active' className='list-item' exact to='/dashboard/sms'><i className="fas fa-envelope"></i><span className='label'>SMS</span></NavLink>
                <NavLink onClick={() => setMobileNavOpen(false)} activeClassName='active' className='list-item' exact to='/dashboard/drivers'><i className="fas fa-user-tie"></i><span className='label'>Drivers</span></NavLink>
                <NavLink onClick={() => setMobileNavOpen(false)} activeClassName='active' className='list-item' exact to='/dashboard/customers'><i className="fas fa-user-tie"></i><span className='label'>Customers</span></NavLink>
                <NavLink onClick={() => setMobileNavOpen(false)} activeClassName='active' className='list-item' exact to='/dashboard/enterprise'><i className="fas fa-user-tie"></i><span className='label'>Enterprise</span></NavLink>
                <NavLink onClick={() => setMobileNavOpen(false)} activeClassName='active' className='list-item' exact to='/dashboard/merchant'><i className="fas fa-user-tie"></i><span className='label'>Merchant</span></NavLink>
                <NavLink onClick={() => setMobileNavOpen(false)} isActive={() => ['/dashboard/request/make-request', '/dashboard/request/drivers', '/dashboard/request'].includes(pathname)} activeClassName='active' className='list-item' exact to='/dashboard/request'><i className="fas fa-chalkboard-teacher"></i><span className='label'>Request</span></NavLink>
                {/* <NavLink activeClassName='active' className='list-item' exact to='/dashboard/customers'><i className="fas fa-users"></i><span className='label'>Customers</span></NavLink> */}
                <NavLink onClick={() => setMobileNavOpen(false)} isActive={() => ['/dashboard/trips', '/dashboard/trips/tripId'].includes(pathname)} activeClassName='active' className='list-item' exact to='/dashboard/trips'><i style={{marginLeft: '3px', marginRight: '23px'}} className="fas fa-map-marker-alt"></i><span className='label'>Trips</span></NavLink>
                <NavLink onClick={() => setMobileNavOpen(false)} activeClassName='active' className='list-item' exact to='/dashboard/referrals'><i style={{marginLeft: '3px', marginRight: '23px'}} className="fas fa-list"></i><span className='label'>Referrals</span></NavLink>
                <NavLink onClick={() => setMobileNavOpen(false)} activeClassName='active' className='list-item' exact to='/dashboard/finance'><i style={{marginRight: '23px'}} className="fas fa-wallet"></i><span className='label'>Finance</span></NavLink>
                <NavLink onClick={() => setMobileNavOpen(false)} activeClassName='active' className='list-item' exact to='/dashboard/routes'><i style={{marginRight: '23px'}} className="fas fa-route"></i><span className='label'>Routes</span></NavLink>
                {/* <NavLink onClick={() => setMobileNavOpen(false)} activeClassName='active' className='list-item' exact to='/dashboard/chat'><i style={{marginRight: '23px'}} className="far fa-comment-dots"></i><span className='label'>Chat</span></NavLink> */}
                <NavLink onClick={() => setMobileNavOpen(false)} activeClassName='active' className='list-item' exact to='/dashboard/vehicles'><i style={{marginRight: '23px'}} className="far fa-bell"></i><span className='label'>Vehicles</span></NavLink>
                <NavLink onClick={() => setMobileNavOpen(false)} activeClassName='active' className='list-item' exact to='/dashboard/countries'><i style={{marginRight: '23px'}} className="far fa-box"></i><span className='label'>Countries</span></NavLink>
                <NavLink onClick={() => setMobileNavOpen(false)} activeClassName='active' className='list-item' exact to='/dashboard/live-tracking'><i style={{marginRight: '23px'}} className="fas fa-location-arrow"></i><span className='label'>Live Tracking</span></NavLink>
                <NavLink onClick={() => setMobileNavOpen(false)} activeClassName='active' className='list-item' exact to='/dashboard/ticket'><i style={{marginRight: '23px'}} className="fas fa-headset"></i><span className='label'>Support</span></NavLink>
                {userClass === "superAdmin" && <NavLink onClick={() => setMobileNavOpen(false)} activeClassName='active' className='list-item' exact to='/dashboard/settings'><i style={{marginRight: '23px'}} className="fas fa-cog"></i><span className='label'>Settings</span></NavLink>}
            </div>
           
        </div>
        </div>
        
    )
}

export default SidebarCopy;