import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useState } from 'react';

const Pagination = ({postsPerpage, totalPosts, paginate }) => {
  const [num, setNum] = useState(1)
  const pageNumbers = [];

  for(let i = 1; i <= Math.round(totalPosts / postsPerpage); i++) {
    pageNumbers.push(i)
  }

  const handlePaginate = (number) => {
    paginate(number)
    setNum(number)
  }
    
  return (
    <Flex mt={3}  alignItems="center" justifyContent={"space-between"}>
      {num === 1 && <Text>Showing 1 - 20 of {totalPosts}</Text>}
      {num === 2 && <Text>Showing 21 - 40 of {totalPosts}</Text>}
      {num === 3 && <Text>Showing 41 - 60 of {totalPosts}</Text>}
      {num === 4 && <Text>Showing 61 - 80 of {totalPosts}</Text>}
      {num === 5 && <Text>Showing 81 - 100 of {totalPosts}</Text>}
      {num === 6 && <Text>Showing 101 - 120 of {totalPosts}</Text>}
      {num === 7 && <Text>Showing 121 - 140 of {totalPosts}</Text>}
      <Flex>
        {pageNumbers.map(number => (
        <Text border="1px solid gray" bg={number === num ? "dodgerblue" : "transparent"} color={"#333"} textAlign={"center"} width={"30px"} cursor={"pointer"} onClick={() => handlePaginate(number)} key={number}>{number}</Text>
      ))}
      </Flex>
      
    </Flex>
  )
}
  
export default Pagination;
