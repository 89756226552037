import axios from 'axios';
import { useState, useEffect } from 'react';
import './profile.scss';
import {baseUrl} from "../../../config.json";
import AppLoader from '../../../components/appLoader/appLoader';
import AutoComplete from './autoComplete/autocomplete';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { getAllVehicles } from '../../../services/routeServices';
import ButtonLoader from '../../../components/buttonLoader/buttonLoader';

const config = {
  headers: {
     "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
     }
}

const Profile = (props) => {
    const [deets, setDeets] = useState(null);
    const [loading, setLoading] = useState(true);

    const { data, error, isLoading } = useQuery('getAllVehicles', getAllVehicles)
    if(isLoading) {
      console.log('loading all vehicles')
    } else if(data) {
        console.log('gotten all vehicles', data.data.data)
    } else {
        console.log(error)
    }

    console.log(props)
    const id = props.location.query.id

    useEffect(() => {
        axios.get(`${baseUrl}/admin/driver-details/${id}`, config)
        .then(res => setDeets(res?.data?.data))
        // .then(res => console.log(res?.data?.data))
        .then(res => setLoading(false))
        .catch(err => console.log(err))
    }, [])

    const [loadex, setLoadex] = useState(false);
    const [loadingy, setLoadingy] = useState(false);
    
  const [pickUpAd, setPickUpAd] = useState("");
  const [destAd, setDestAd] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng:null
});
  const [coordinates2, setCoordinates2] = useState({
    lat: null,
    lng:null
});

 
  const formData = {
    "name": pickUpAd,
    "lat": coordinates.lat,
    "lng": coordinates.lng
}

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadex(true);
    console.log("helooooooo", formData)

    try {
                 const { data } = await axios.post(`${baseUrl}/admin/driver/${deets?._id}/set-location`, formData, config);
                     console.log(data)
                     toast.success(data?.message, {
                        position: toast.POSITION.TOP_CENTER
                     })
                     setLoadex(false)
                    //  window.location.reload()
                     
               } catch(error) {
                     toast.error(error?.response?.data?.message, {
                        position: toast.POSITION.TOP_CENTER
                     })
                     setLoadex(false)
               }
  }

  const [plateNumber, setPlateNumber] = useState(deets?.vehicleInformationAndLicense?.plateNumber);
  const [vehicleType, setVehicleType] = useState(deets?.vehicleInformationAndLicense?.vehicleType);
  const [vLicenseNumber, setVLicenseNumber] = useState(deets?.vehicleInformationAndLicense?.vehicleLicenseNumber);
  const [vIssueDate, setVIssueDate] = useState(deets?.vehicleInformationAndLicense?.issueDate);
  const [vExpiryDate, setVExpiryDate] = useState(deets?.vehicleInformationAndLicense?.expiryDate);
  const [vLicenseImg, setVLicenseImg] = useState(deets?.vehicleLicenseImage);
  const [loadexy, setLoadexy] = useState(false);
  // const [vImg, setVImg] = useState(vehicleImages);

  const vformData = new FormData();
  vformData.append("vehicleInformationAndLicense[plateNumber]", plateNumber);
  vformData.append("vehicleInformationAndLicense[vehicleType]", vehicleType);
  vformData.append("vehicleInformationAndLicense[issueDate]", vIssueDate);
  vformData.append("vehicleInformationAndLicense[expiryDate]", vExpiryDate);
  vformData.append("vehicleInformationAndLicense[vehicleLicenseNumber]", vLicenseNumber);
  vformData.append("vehicleLicenseImage", vLicenseImg);
  // vformData.append("vehicleImages", vImg);

  const vehicletempData = vformData

  const handleVehicleLicenseSumbit = async e => {
    e.preventDefault();
    setLoadingy(true)
    console.log(vehicletempData)

    try {
      const res = await axios.put(`${baseUrl}/admin/driver/${deets?._id}/update-details`, vehicletempData, config)
      console.log(res.data)
      setLoadingy(false)
      toast.success(res.data.message, {
         position: toast.POSITION.TOP_CENTER
      })
      // window.location.reload(false);
   } catch (error) {
      console.log(error.response.data.message)
      setLoadingy(false)
      toast.error(error.response.data.message, {
         position: toast.POSITION.TOP_CENTER
      })
   }

  }

    return (
        <div className="profile">
            {loading ? <AppLoader /> :
            <>
            
            <div className="cover">
      <div className="top-head">
        <i className="fas fa-home"></i>
        <p className="top-head-left">Settings</p>
      </div>

      <p className="pg-title">Account Settings</p>
      <div className="img-deet-wrap" style={{backgroundColor: "fff"}}>
      <div className="img-deet">
        <div className="img" style={{position: "relative", backgroundImage: `url(${deets? deets?.profilePicture : null})`, backgroundSize: 'cover', backgroundPosition: "center"}}>
        {/* <input style={{
          backgroundColor: "red",
          width: "120px",
          height: "120px",
          borderRadius: "50%",
          position: "absolute",
          opacity: "0",
          zIndex: "2",
          cursor: "pointer"
        }} type="file" required /> */}
        {/* <i
        style={{backgroundColor: "#35aa78",
                borderRadius: "50%",
                padding: "8px",
                color: "#fff",
                zIndex: "1",
                fontSize: "1.3em",
                position: "absolute",
                top: "60%",
                right: "0",
                cursor: "pointer"
              }}
        className="far fa-edit"></i>
        */}
        </div>
        
        <div className="deet">
          <p className="name">{deets?.name}</p>
          <p className="account-number">{deets?.phoneNumber}</p>
          <p className="mail">{deets?.email}</p>
        </div>
      </div>
      {/* {ppUploadBtn && <button onClick={uploadImg}>{loadingx && <ButtonLoader />}Update profile image</button>} */}
      </div>
            <br />
            <p className="location">{deets?.location?.name ? deets?.location?.name : null}</p><br />

              <AutoComplete setCoordinates={setCoordinates}  setPickUpAd={setPickUpAd} />

              <br />

              <p style={{padding: "5px 10px", marginTop: "5px", fontSize: "14px", width: "fit-content", borderRadius: "6px", background: "#113E82", color: "#fff", padding: ".8rem 1rem", border: "none"}} onClick={handleSubmit}>{!loadex ? "Set Location" : "Setting..."}</p>
            

      <div className="form">
        <div className="personal-info">
          <div className="title">Personal Information</div>
          <form className="inputs">
            <div className="input">
              <label htmlFor="full-name">Full Name</label>
              <br />
              <i className="far fa-user"></i>
              <input id="full-name" value={deets?.name} type="text" />
            </div>
            <div className="input">
              <label htmlFor="phone-number">Phone Number</label>
              <br />
              <i className="fas fa-phone-alt"></i>
              <input id="phone-number" value={deets?.phoneNumber} type="tel" />
            </div>
            <div className="input">
              <label htmlFor="email">Email Address</label>
              <br />
              <i className="far fa-envelope"></i>
              <input id="email" value={deets?.email} type="email" />
            </div>
            <div className="input">
              <label htmlFor="address">Address</label>
              <br />
              <i className="fas fa-location-arrow"></i>
              <input id="address" value={deets?.address} type="text" />
            </div>
          </form>
          <br />
          <br />
          {/* <button onClick={handlePersonalSubmit} type="submit">{loading4 && <ButtonLoader />} Save</button> */}
          
        </div>

        <div className="business-info">
          <div className="title">Drivers License</div>
          <form>
          <div className="all-inputs">
            <div className="left-inputs">
              <div className="input">
                <label htmlFor="plate-number">Driver's license number</label>
                <br />
                <input required value={deets?.driverLicenseInformation?.driverLicenseNumber} id="plate-number" type="text" />
              </div>

              <div className="input">
                <label htmlFor="vehicle-type">Issue Date</label>
                <br />
                <input required value={deets?.driverLicenseInformation?.issueDate} id="vehicle-type" type="date" />
              </div>

              <div className="input">
                <label htmlFor="cac-reg">Expiry Date</label>
                <input required value={deets?.driverLicenseInformation?.expiryDate} min={new Date().toISOString().split('T')[0]} type="date" id="cac-reg" />
              </div>
            </div>
            
            <div className="right-inputs">

              <div className="files">
              <div className="img-upload field-div">
                <div className="selector">
                  <div className="action">
                    <input type="file" className="mvdwn" id="file" />
                    <i className="fas fa-upload"></i>
                    <p>Upload Drivers License</p>
                  </div>
                  <div  style={{height: '142px', borderRadius: '0 0 5px 5px', backgroundImage: `url(${deets ? deets?.driverLicenseImage : null})`, backgroundSize: 'cover'}} className="preview"></div>
                </div>
              </div>

              </div>

            </div>
          </div>
          {/* <button type="submit">{loading && <ButtonLoader />} Save</button> */}
          </form>
          
        </div>

        {/* <Documents /> */}
        

        <div className="business-info">
          <div className="title">Vehicle Information & License</div>
          <form onSubmit={handleVehicleLicenseSumbit}>
          <div className="all-inputs">
            <div className="left-inputs">
              <div className="input">
                <label htmlFor="plate-number">Plate Number</label>
                <br />
                <input onChange={e => setPlateNumber(e.target.value)} required id="plate-number" value={deets?.vehicleInformationAndLicense?.plateNumber} type="text" />
              </div>


              <div className="input">
          <label htmlFor="vehicle-type">Vehicle Type</label>
          <br />
          <select 
          className="field" 
          onChange={e => setVehicleType(e.target.value)} 
        //   
        value={deets?.vehicleType}
          required type="text" 
          name="vehicle-type">
            <option>{deets?.vehicleInformationAndLicense?.vehicleType}</option>
            {data?.data?.data.filter(d => d.vehicleType !== "bike").map(({pricePerKilometer, vehicleType, _id: id, wehaulCommission, wehaulConstant}) => {
              return (
                <option key={id} value={vehicleType}>{vehicleType}</option>
              )
            })}
          </select>
        </div>

              

              <div className="input">
                <label htmlFor="cac-reg">Vehicle license number</label>
                <input onChange={e => setVLicenseNumber(e.target.value)} required type="text" value={deets?.vehicleInformationAndLicense?.vehicleLicenseNumber} id="cac-reg" />
              </div>
            </div>

            <div className="right-inputs">
              <div className="input" style={{display: "flex", justifyContent: "space-between"}}>
                <div style={{width: "48%"}}>
                <label htmlFor="phone-number">Issue Date</label>
                <input onChange={e => setVIssueDate(e.target.value)} required value={deets?.vehicleInformationAndLicense?.issueDate} type="date" id="phone-number" />
                </div>
                <div style={{width: "48%"}}>
                <label htmlFor="phone-number">Expiry Date</label>
                <input onChange={e => setVExpiryDate(e.target.value)} required value={deets?.vehicleInformationAndLicense?.expiryDate} min={new Date().toISOString().split('T')[0]} type="date" id="phone-number" />
                </div>
              </div>

              <div className="files">
              <div className="img-upload field-div">
                <div className="selector">
                  <div className="action">
                    <input type="file" id="file" />
                    <i className="fas fa-upload"></i>
                    <p>Upload Vehicle License</p>
                  </div>
                  <div  style={{height: '142px', borderRadius: '0 0 5px 5px', backgroundImage: `url(${deets ? deets?.vehicleLicenseImage : null})`, backgroundSize: 'cover'}} className="preview"></div>
                </div>
              </div>

              <div className="img-upload field-div">
                <div className="selector">
                  <div className="action">
                    <input type="file" id="file" />
                    <i className="fas fa-upload"></i>
                    <p>Upload Vehicle Image</p>
                  </div>
                  <div  style={{height: '142px', borderRadius: '0 0 5px 5px', backgroundImage: `url(${deets ? deets?.vehicleImages[0] : null})`, backgroundSize: 'cover'}} className="preview"></div>
                </div>
              </div>
              </div>
              

            </div>
          </div>
          <button type="submit">{loadingy && <ButtonLoader />} Save</button>
          </form>
          
        </div>

        <div className="acct-details">
        <div className="title">Account Details</div>
          <form>
          <div className="inputs">
                <div className="input">
                  <label htmlFor="account-name">Account Name</label>
                  <br />
                  <i className="far fa-user"></i>
                  <input
                    id="account-name"
                    type="text"
                    required
                    value={deets?.accountInformation?.accountName}
                    // onChange={e => setAccountName(e.target.value)}
                  />
                </div>
                <div className="input">
                  <label htmlFor="account-number">Account Number</label>
                  <br />
                  <i className="fas fa-calculator"></i>
                  <input
                    id="account-number"
                    type="tel"
                    required
                    value={deets?.accountInformation?.accountNumber}
                    // onChange={e => setAccountNumber(e.target.value)}
                  />
                </div>
                <div className="input">
                  <label htmlFor="bank-name">Bank Name</label>
                  <br />
                  <i className="fas fa-university"></i>
                  <select 
                    style ={{marginTop: "10px",
                      background: "#F9FBFF",
                      border: "1px solid #40D192",
                      borderRadius: "6px",
                      padding: "14px 30px",
                      boxSizing: "border-box",
                      width: "100%",
                      outline: "none"}}
                    id="bank-name"
                    type="text"
                    required
                    value={deets?.accountInformation?.bankName}
                    // onChange={e => setBankName(e.target.value)}
                  >
                    <option>{deets?.accountInformation?.bankName}</option>
                    {/* {banksData.map(({name}, index) =>{
                      return (
                        <option key={index} value={name}>{name}</option>
                      )
                    })} */}
                  </select>
                </div>
                <div className="input">
                  <label htmlFor="account-type">Account Type</label>
                  <br />
                  <i className="fas fa-money-check-alt"></i>
                  <select
                  style ={{marginTop: "10px",
                  background: "#F9FBFF",
                  border: "1px solid #40D192",
                  borderRadius: "6px",
                  padding: "14px 30px",
                  boxSizing: "border-box",
                  width: "100%",
                  outline: "none"}}
                  name="account-type"
                  id="account-type"
                  required
                  value={deets?.accountInformation?.accountType}
                //   onChange={e => setAccountType(e.target.value)}
                  >
                    <option>{deets?.accountInformation?.accountType}</option>
                    {/* <option value="savings">Savings</option>
                    <option value="current">Current</option> */}
                  </select>
                </div>
              </div><br />
              {/* <button type="submit">{loading3 && <ButtonLoader />} Save</button> */}
          </form>
              
            </div>
      </div>
      </div>
              
          </>}
        </div>
    )
}

export default Profile;