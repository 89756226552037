import React, { useState } from "react";
import { Link } from "react-router-dom";
import TripView from "../../trip-view/tripView";
import EmptyState from "../../../emptyState/emptyState";
import { useHistory } from "react-router";
import ReactStars from "react-rating-stars-component";
import axios from "axios";
import {baseUrl, devUrl} from '../../../../config.json';
import { toast } from "react-toastify";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Avatar,
  Badge,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
} from "@chakra-ui/react";
import Dots from './dots.svg';
import PaginatedTable from "../../../paginatedTable/paginatedTable";

const config = {
  headers: {
     "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
     }
}

const CancelledTrips = ({ data }) => {
  const [tripViewShown, setTripViewShown] = useState(false);
  const [tripId, setTripId] = useState("");
  const [driverId, setDriverId] = useState("");
  const [loader, setLoader] = useState(false);
  const [cLoading, setCLoading] = useState(false);

  const [clicked, setClicked] = useState(false);

  const toggle = (index, driverId, tripId) => {
    if(clicked === index) {
      return setClicked(null)
    }
    setClicked(index)
    setDriverId(driverId)
    setTripId(tripId)
  }

  const history = useHistory();

  const getTripId = (id) => {
    console.log(id);
    setTripId(id);
    setTripViewShown(true);
  };

 

  const cancelTrip = async (id) => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}` }
      
  };
  try {
      await fetch(`${baseUrl}/admin/trip/${id}/cancel-trip`, requestOptions)
      .then(res => res.json())
      .then(res => 
            toast.success(res.message, {
                      position: toast.POSITION.TOP_CENTER
                  })
          )
      // if(expressAvailability) {
      //     window.location.reload();
      // }
  } catch (error) {
      toast.error(error?.response?.data?.message, {
                 position: toast.POSITION.TOP_CENTER
             })
  }
  
}

  const handleChatRedirect = async (id, name, picture) => {
    console.log(id)
    await history.push(`dashboard/chat/${id}`, {chatRecipient: id, name, picture})
    window.location.reload()
}

const ratingChanged = async (newRating) => {
  console.log(newRating);
  const formData = {
    rating: newRating
  }
  setLoader(true)
  try {
    const {data} = await axios.post(`${baseUrl}/customer/trip/${tripId}/rate-driver/${driverId}`, formData, config)
    console.log(data)
    toast.success(data?.message)
    window.location.reload()
  } catch (error) {
    console.log(error?.response?.data?.message)
  }
};

const { isOpen, onOpen, onClose } = useDisclosure()

 const [receiptLoader, setReceptLoader] = useState(true);
  const [receiptData, setReceptData] = useState(null);

const getReceiptDetails = async(id) => {
  try {
    const {data} = await axios.get(`${devUrl}/admin/request/${id}/request-details`, config)
    // console.log(data?.data)
    setReceptData(data?.data)
    setReceptLoader(false)
  } catch(err) {
    console.log(err?.response?.data?.message)
  }
}

const getTripIdx = async(id) => {
  await setTripId(id);
  await onOpen()
  // console.log("workcc",id);
  getReceiptDetails(id)
};



const rowTitles = [
  "Pick-up",
  "Drop-off",
  "Driver",
  "Status",
  "Vehicle",
  "Trip Fee",
  "Customer",
  "Pickup Date",
  "Action"
]

const tableBody = data?.filter(i => i.tripStatus === "cancelled").map(
  (
    {
      request,
      acceptedBy,
      tripFee,
      pickUpAddress,
      destinationAddress,
      vehicleType,
      receiverDetails,
      pickUpDate,
      tripStatus,
      estPrice,
      qualifiedBikeRiders,
      createdBy,
      responseCount,
      _id: id
    },
    index
  ) => {
    return (
      <Tr className="content-row" key={index}>
        <Td className="lefty pre">{request?.pickUpAddress?.slice(0,40)}{request?.pickUpAddress.length > 40 && ".."}</Td>
        <Td className="lefty">{request?.destinationAddress?.slice(0,40)}{request?.destinationAddress.length > 40 && ".."}</Td>
        <Td className="lefty pre">{acceptedBy?.name}</Td>
        {tripStatus === "completed" && (
          <Td>
            {/* <p className="active-status">{tripStatus}</p> */}
            <Badge colorScheme={"green"}>{tripStatus}</Badge>
          </Td>
        )}
        {tripStatus === "on-going" && (
          <Td>
            <Badge colorScheme={"blue"}>{tripStatus}</Badge>
          </Td>
        )}
        {tripStatus === "pending" && (
          <Td>
            <Badge colorScheme={"yellow"}>{tripStatus}</Badge>
          </Td>
        )}
        {tripStatus === "rejected" && (
          <Td>
            <p className="cancelled-status">{tripStatus}</p>
          </Td>
        )}
        {tripStatus === "cancelled" && (
          <Td>
            <Badge colorScheme={"red"}>{tripStatus}</Badge>
          </Td>
        )}
        
        
        
        <Td>{request?.vehicleType}</Td>
        <Td>{tripFee?.toLocaleString()}</Td>
        <Td>{request?.createdBy?.name}</Td>
        <Td>{request?.pickUpDate?.slice(0,10)}</Td>
        
        <Td>
          <Menu size={"xs"}>
            <MenuButton>
              <Image cursor={"pointer"} ml="1.5rem" src={Dots} />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => getTripId(id)}>View</MenuItem>
             
              {tripStatus === 'pending' && 
              <>
              <MenuDivider />
              <MenuItem onClick={() => cancelTrip(id)}>Cancel Request</MenuItem>
              </>}
              {tripStatus === 'completed' && 
              <>
              <MenuDivider />
              <MenuItem onClick={() => getTripIdx(id)}>Generate Receipt <i style={{marginLeft: ".5rem"}} className="fas fa-download"></i></MenuItem>
              </>}
            </MenuList>
          </Menu>
          
          {/* <p onClick={() => getRequestId(id)} style={{ backgroundColor: "#113E82", color: "#fff", padding: "5px", borderRadius: "6px", marginBottom: "7px" }}>View</p>
          {vehicleType === 'bike' && tripStatus !== 'cancelled' && <Link to={{pathname: "/dashboard/request/bike-drivers",state: {reqId: id, reqData: qualifiedBikeRiders}}}><div><i style={{ backgroundColor: "#FFCE45" }} className="fas fa-biking"></i></div></Link>}
          {vehicleType !== 'bike' && <div onClick={() => history.push('/dashboard/request/other-drivers', {reqId: id, userClass: createdBy?.userClass, userId: createdBy?.id})}><p style={{ backgroundColor: "#FFCE45", color: "#fff", padding: "5px", borderRadius: "6px", marginBottom: "7px" }}>Responses</p></div>} */}
          
          {/* {vehicleType !== 'bike' ?
          tripStatus === 'active' && 
          <div onClick={() => handleCancel(id)}>
            <p style={{ backgroundColor: "#FF1B00", color: "#fff", padding: "5px", borderRadius: "6px"  }}>Cancel</p>
          </div> : null} */}
        </Td>
      </Tr>
    );
  }
)

  return (
    <div className="all-trips">


{data?.data?.data.length === 0 ? (
        <EmptyState />
      ) : (
          <PaginatedTable tableBody={tableBody} rowTitles={rowTitles} />
        )}

      {tripViewShown && (
        <TripView tripId={tripId} setTripViewShown={setTripViewShown} />
      )}
    </div>
  );
};

export default CancelledTrips;
