import React from 'react';
import './wallet.scss';
import Balanceicon from '../../../assets/balanceIcon.svg';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Wallet = () => {
    const currentUser = useSelector(({userData}) => userData.currentUser)
    return ( 
        <div className="walletx">
            <div className="balance">
               <img className='bal-icon' src={Balanceicon} alt="balance icon" />
               <div className="info">
                   <p className="title">Total Revenue</p>
                   {/* <p className="balance-val">N{currentUser? currentUser?.data?.walletBalance.toLocaleString() : null}</p> */}
               </div>
               
            </div>
            {/* <Link to="/dashboard/wallet">
              <button><i className="fas fa-wallet"></i>Fund Wallet</button>
            </Link>  */}
        </div>
     );
}
 
export default Wallet;