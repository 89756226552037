import React, { useState } from 'react';
import ButtonLoader from '../../../components/buttonLoader/buttonLoader';
import './addVehicle.scss';
import axios from 'axios';
import { baseUrl } from '../../../config.json';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import LineLoader from '../../../components/lineLoader/lineLoader';
import { useSelector } from 'react-redux';

const AddVehicle = ({setVehicleView}) => {
    const [loading, setLoading] = useState(false);
    const [vehicleType, setVehicleType] = useState("");
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState("");
    const [ minPricePerKilometer, setMinPricePerKilometer] = useState("");
    const [ maxPricePerKilometer, setMaxPricePerKilometer] = useState("");
    const [ wehaulCommission, setWehaulCommission] = useState("");
    const [ wehaulConstantValue, setWehaulConstantValue] = useState("");


    const config = {
        headers: {
           "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
           }
     }

const formData = {
    vehicleType,
    category,
    pricePerKilometer: {minPrice: minPricePerKilometer, maxPrice: maxPricePerKilometer},
    wehaulCommission,
    wehaulConstantValue,
    description
}



  const addVehicle = async (e) => {
      e.preventDefault();
      setLoading(true)
      

      try {
          const {data} = await axios.post(`${baseUrl}/admin/add-vehicle`, formData, config)
          console.log(data.data)
          toast.success(data.message)
          setLoading(false)
          window.location.reload();
      } catch (error) {
        toast.error(error?.response?.data?.message)
        setLoading(false)
      }
  }

    

    return ( 
        <div className="create-ticket">
            <div className="wrapperv">
                <div className="modalv">
                    <i onClick={() => setVehicleView(false)} className="fas fa-times"></i>
                    <form onSubmit={addVehicle}>
                        <div className="input">
                            <label htmlFor="subject">Vehicle Type</label><br />
                            <input onChange={e => setVehicleType(e.target.value)} name="subject" type="text" />
                        </div>
                        <div className="input">
                            <label htmlFor="cat">Category</label><br />
                            <select onChange={e => setCategory(e.target.value)} name="cat">
                                <option value="bike">Bike</option>
                                <option value="others">Others</option>
                                <option value="trucks">Trucks</option>
                                
                            </select>
                        </div>
                        <div className="input">
                            <label htmlFor="subject">Minimum Price/Km</label><br />
                            <input onChange={e => setMinPricePerKilometer(e.target.value)} name="subject" type="tel" />
                        </div>
                        <div className="input">
                            <label htmlFor="subject">Maximum Price/Km</label><br />
                            <input onChange={e => setMaxPricePerKilometer(e.target.value)} name="subject" type="tel" />
                        </div>
                        <div className="input">
                            <label htmlFor="subject">Wehaul Commission</label><br />
                            <input onChange={e => setWehaulCommission(e.target.value)} name="subject" type="tel" />
                        </div>
                        <div className="input">
                            <label htmlFor="subject">Wehaul Constant</label><br />
                            <input onChange={e => setWehaulConstantValue(e.target.value)} name="subject" type="tel" />
                        </div>
                        <div className="input">
                            <label htmlFor="desc">Description</label><br />
                            {/* <p className="info">Please enter the details of your request. A member of our support staff will respond as soon as possible.</p> */}
                            <textarea onChange={e => setDescription(e.target.value)} name="desc" id="" cols="30" rows="1"></textarea>
                        </div>
                        <div className="btn">
                            <button style={{cursor: "pointer"}} type="submit">{loading && <ButtonLoader />}Add Vehicle</button>
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
     );
}
 
export default AddVehicle;