import axios from 'axios';
import {baseUrl} from '../config.json';

const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
       }
 }

export async function getAllDrivers(page) {
    return axios.get(`${baseUrl}/admin/retrieve-drivers?page=${page}`, config);
}