import React, { useState } from "react";
import "./enterprise.scss";
import Overview from "../../components/dashboard-components/overview/overview";
import { useQuery } from "react-query";
import Loader from "../../components/loader/loader";
import axios from "axios";
import { baseUrl } from "../../config.json";
import { toast } from "react-toastify";
import AddDriver from "./add-enterprise/addEnterprise";
import ViewEnterprise from "./view-enterprise/viewEnterprise";
import { getAllEnterprise } from "../../services/enterpriseServices";
import AddEnterprise from "./add-enterprise/addEnterprise";
import { useSelector } from "react-redux";
import {
  Avatar,
  ChakraProvider,
  Table,
  TableContainer,
  Tbody,
  Thead,
  Tr,
} from "@chakra-ui/react";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`,
  },
};

const Enterprise = () => {
  const [driverView, setDriverView] = useState(false);
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);

  const currentUser = useSelector(({ userData }) => userData.currentUser);
  console.log(currentUser?.data);
  const { userClass } = currentUser?.data;

  const { data, error, isLoading } = useQuery(
    "getAllEnterprise",
    getAllEnterprise
  );
  if (isLoading) {
    console.log("loading");
  } else if (data) {
    console.log("getAllEnterprise", data.data);
  } else {
    console.log(error);
  }

  const getDriverDetails = async (id) => {
    await setUserId(id);
    setDriverView(true);
    console.log("hi", id);
  };

  const deleteDriver = (id) => {
    // setUserId(id);
    axios
      .delete(`${baseUrl}/admin/delete-enterprise/${id}`, config)
      .then((res) =>
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        })
      )
      .then((res) => window.location.reload())
      .catch((err) =>
        toast.error("An error occured, please try again", {
          position: toast.POSITION.TOP_CENTER,
        })
      );
  };

  const verify = async (id) => {
    setLoading(true);
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`,
      },
      // body: JSON.stringify({ title: 'React PUT Request Example' })
    };
    try {
      await fetch(`${baseUrl}/admin/verify-enterprise/${id}`, requestOptions)
        .then((res) => res.json())
        .then((res) =>
          toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER,
          })
        )
        .then((res) => setLoading(false))
        .then((res) => window.location.reload());
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const banDriver = async (id) => {
    //   const id = e.target.id
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`,
      },
      // body: JSON.stringify({ title: 'React PUT Request Example' })
    };
    try {
      await fetch(`${baseUrl}/admin/ban-enterprise/${id}`, requestOptions)
        .then((res) => res.json())
        .then((res) =>
          toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER,
          })
        )
        .then((res) => window.location.reload());
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    //  const id = e.target.id
    //  await axios.put(`${baseUrl}/enterprise/driver/${id}/ban-driver`, config)
    //        .then(res => console.log(res.data))
    //        .catch(err => console.log(err))
    //  try {
    //     const res = await axios.put(`${baseUrl}/enterprise/driver/${id}/ban-driver`, config);
    //     toast.success(res.data.data.message, {
    //         position: toast.POSITION.TOP_CENTER
    //     })
    //  } catch (error) {
    //    toast.error(error?.response?.data?.message, {
    //        position: toast.POSITION.TOP_CENTER
    //    })
    //  }
  };

  // const banCustomer = (id) => {
  //     // setUserId(id)

  //     axios.put(`${baseUrl}/admin/ban-customer/${id}`, config)
  //     .then(res => toast.success(res.data.data, {
  //       position: toast.POSITION.TOP_CENTER
  //     }))
  //     .catch(err => toast.error("An error occured, please try again", {
  //       position: toast.POSITION.TOP_CENTER
  //     }))
  // }

  return (
    <ChakraProvider>
      <div className="customers">
        <Overview />

        <div className="heading">
          <div className="left-items">
            <div className="title">
              {/* <i
                onClick={() => (window.location.href = "/dashboard")}
                className="fas fa-home"
              ></i> */}
              <span>Enterprise</span>
            </div>
            <p className="request-count">{data?.data?.enterprises.length}</p>
            {/* <p className="export-btn">Export</p> */}
          </div>
          <div className="right-items">
            <AddEnterprise />
          </div>
        </div>

        <TableContainer>
          <Table textAlign="left" variant="simple">
            <Thead>
              <Tr>
                {/* <th></th> */}
                <th>Name</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Wallet</th>
                <th>Action</th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading ? (
                <Loader />
              ) : (
                data?.data?.enterprises.map(
                  (
                    {
                      name,
                      phoneNumber,
                      profilePicture,
                      walletBalance,
                      email,
                      isVerified,
                      isBanned,
                      _id: id,
                    },
                    index
                  ) => {
                    return (
                      <tr className="content-row" key={index}>
                        {/* <td><Avatar src={profilePicture} name="Liam nussie" /></td> */}
                        <td>
                          <p>
                            {name}
                            {isBanned && (
                              <i
                                style={{
                                  cursor: "pointer",
                                  fontSize: "1.1em",
                                  color: "#F3402B",
                                  margin: "0 5px",
                                  backgroundColor: "#e0f5f5",
                                  padding: "6px",
                                  borderRadius: "2px",
                                }}
                                className="fas fa-ban"
                              ></i>
                            )}
                          </p>
                        </td>
                        <td>{phoneNumber}</td>
                        <td>{email}</td>
                        <td>N{walletBalance.toLocaleString()}</td>
                        <td className="flx">
                          {!isVerified ? (
                            <span
                              onClick={() => verify(id)}
                              style={{
                                cursor: "pointer",
                                fontSize: "1.1em",
                                color: "green",
                                margin: "0 5px",
                                backgroundColor: "#e0f5f5",
                                padding: "6px",
                                borderRadius: "2px",
                              }}
                            >
                              Verify
                              <i
                                style={{ marginLeft: "5px" }}
                                className="fas fa-check"
                              ></i>
                            </span>
                          ) : (
                            <span
                              onClick={() => verify(id)}
                              style={{
                                cursor: "pointer",
                                fontSize: "1.1em",
                                color: "red",
                                margin: "0 5px",
                                backgroundColor: "#e0f5f5",
                                padding: "6px",
                                borderRadius: "2px",
                              }}
                            >
                              Unverify
                              <i
                                style={{ marginLeft: "5px" }}
                                className="fas fa-times"
                              ></i>
                            </span>
                          )}
                          <i
                            onClick={() => getDriverDetails(id)}
                            style={{
                              cursor: "pointer",
                              fontSize: "1.1em",
                              color: "#113E82",
                              margin: "0 5px",
                              backgroundColor: "#e0f5f5",
                              padding: "6px",
                              borderRadius: "2px",
                            }}
                            className="fas fa-eye"
                          ></i>
                          {userClass === "superAdmin" && (
                            <i
                              onClick={() => deleteDriver(id)}
                              style={{
                                cursor: "pointer",
                                fontSize: "1.1em",
                                color: "#113E82",
                                margin: "0 5px",
                                backgroundColor: "#e0f5f5",
                                padding: "6px",
                                borderRadius: "2px",
                              }}
                              className="fas fa-trash"
                            ></i>
                          )}
                          {!isBanned && (
                            <i
                              onClick={() => banDriver(id)}
                              style={{
                                cursor: "pointer",
                                fontSize: "1.1em",
                                color: "#F3402B",
                                margin: "0 5px",
                                backgroundColor: "#e0f5f5",
                                padding: "6px",
                                borderRadius: "2px",
                              }}
                              className="fas fa-ban"
                            ></i>
                          )}
                          {isBanned && (
                            <i
                              onClick={() => banDriver(id)}
                              style={{
                                cursor: "pointer",
                                fontSize: "1.1em",
                                color: "green",
                                margin: "0 5px",
                                backgroundColor: "#e0f5f5",
                                padding: "6px",
                                borderRadius: "2px",
                              }}
                              className="fas fa-lock-open"
                            ></i>
                          )}
                        </td>
                      </tr>
                    );
                  }
                )
              )}
            </Tbody>
          </Table>
        </TableContainer>

       
        {driverView && (
          <ViewEnterprise userId={userId} setCustomerView={setDriverView} />
        )}
      </div>
    </ChakraProvider>
  );
};

export default Enterprise;
