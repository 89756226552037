import React from 'react';
import './deleteModal.scss';
import ButtonLoader from '../buttonLoader/buttonLoader';


const DeleteModal = ({setShowDeleteModal, loading, text, requestFunction}) => {
    return ( 
        <div className="delete-modal">
            <div className="wrapper">
                <div className="modal">
                    <div className="top-head">
                      <i className="fas fa-exclamation-triangle"></i><p className="title">Are you sure ?</p>
                    </div>
                    <div className="body">
                        <p className="text">This action is permanent, Do you want to proceed?</p>
                        <div className="btns">
                            <button onClick={() => setShowDeleteModal(false)} className="cancel">CANCEL</button>
                            <button onClick={requestFunction} className="continue">{loading ? <ButtonLoader /> : text}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default DeleteModal;