import { Box, Flex, Input, Text } from "@chakra-ui/react";
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableContainer,
  } from '@chakra-ui/react'
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {devUrl} from '../../../config.json';

const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
    }
}

const LeadsTable = ({refresh}) => {
    const [leads, setLeads] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchvalue, setSearchValue] = useState("");

    useEffect(() => {
        axios.get(`${devUrl}/admin/retrieve-leads`, config)
        // .then(res => console.log(res?.data?.data))
        .then(res => setLeads(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error?.response?.data?.message))
    }, [refresh])
    return (
        <Box>
            <Flex margin=".7rem 0" justifyContent={"flex-end"}>
                <Input onChange={e => setSearchValue(e.target.value)} bg="#fff" maxW={"500px"} type="tel" placeholder="Search by Phone.." _placeholder={{fontSize: ".9rem", color: "#828282"}} />
            </Flex>
            <TableContainer>
                <Table size={"sm"} className="l" fontSize={".9rem"} mt={4} borderRadius="8px" variant='simple' bg="#fff">
                    <Thead>
                    <Tr>
                        <Th>Name</Th>
                        <Th>Email</Th>
                        <Th>Phone</Th>
                        <Th>Country</Th>
                        <Th>Action</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                    {leads?.filter(i => i.phoneNumber.toLowerCase().includes(searchvalue.toLowerCase())).map(({name, phoneNumber, country, email, _id: id}, index) => {
                        return (
                            <Tr key={index}>
                                <Td>{name}</Td>
                                <Td>{email ? email : "-"}</Td>
                                <Td>{phoneNumber}</Td>
                                <Td>{country}</Td>
                                <Td><Link to={{pathname: "/dashboard/request/make-request", state: {name, phoneNumber, id}}}><Text cursor={"pointer"} maxW="fit-content" padding={".4rem .8rem"} fontSize=".8rem" borderRadius="6px" color={"#fff"} bg="#29805a">Create Request</Text></Link></Td>
                            </Tr>
                        )
                    })}
                    </Tbody>
                    
                </Table>
            </TableContainer>
        </Box>
    )
}

export default LeadsTable;