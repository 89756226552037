import './coupon.scss';
import { useState } from 'react';
import {baseUrl} from '../../config.json';
import axios from 'axios';
import { toast } from 'react-toastify';
import ButtonLoader from '../../components/buttonLoader/buttonLoader';
import { getAllVehicles } from '../../services/vehicleServices';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getAllCoupons } from '../../services/tripServices';
import EmptyState from '../../components/emptyState/emptyState';

const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
       }
 }

const Coupon = () => {

    const currentUser = useSelector(({userData}) => userData.currentUser)
    console.log(currentUser?.data)
    const { userClass } = currentUser?.data


    const [loading, setLoading] = useState(false);
    const [couponCode, setCouponCode] = useState("");
    const [expirationDate, setExpirationDate] = useState("");
    const [amount, setAmount] = useState("");
    const [couponType, setCouponType] = useState("");

    const { data, error, isLoading } = useQuery('getAllCoupons', getAllCoupons);
    if(isLoading) {
      console.log('loading')
    } else if (data) {
      console.log('getAllCoupons', data.data)
    } else {
      console.log(error)
    }


    const datax = {
        couponCode: couponCode.toUpperCase(),
        amount,
        expirationDate,
        couponType
    }

    const createCoupon = async (e) => {
        e.preventDefault();
        setLoading(true)

        try {
            const res = await axios.post(`${baseUrl}/admin/coupon/create`, datax, config )
            console.log(res.data)
            toast.success(res.data.message)
            setLoading(false)
        } catch (error) {
            console.log(error?.response?.data?.message)
            toast.error(error?.response?.data?.message)
            setLoading(false)
        }
    }

    return (
        <div className="sms">
            {/* <p>{baseUrl + "/admin/sms/" + recipient + dv}</p> */}
            <p className="title">COUPONS</p>
            <form onSubmit={createCoupon}>
                <div className="input">
                    <label>Coupon Code:</label>
                    <input type="text" onChange={e => setCouponCode(e.target.value)} style={{textTransform: "uppercase"}} />
                </div>
                <div className="input">
                    <label>Coupon Amount:</label>
                    <input type="tel" onChange={e => setAmount(e.target.value)}/>
                </div>
                <div className="input">
                    <label>Expiration Date:</label>
                    <input type="date" onChange={e => setExpirationDate(e.target.value)} />
                </div>
                <div className="input">
                    <label>Coupon Type:</label>
                    <select onChange={e => setCouponType(e.target.value)}>
                        <option selected disabled>Select</option>
                        <option value="amount">Amount</option>
                    </select>
                </div>
                {userClass === "superAdmin" && <button type="submit">{loading && <ButtonLoader />}CREATE COUPON</button>}
            </form>

            {!isLoading &&
            data?.data?.data.length === 0 ? <EmptyState /> : 
      <table>
        <tr className="label-row">
          <th>Coupon Code</th>
          <th>Coupon Type</th>
          <th>Amount</th>
          <th>Date Created</th>
          <th>Expiration Date</th>
          <th>Action</th>
        </tr>
        {data?.data?.data.map(
          (
            { couponCode, couponType, amount, expirationDate, createdAt },
            index
          ) => {
            return (
              <tr className="content-row" key={index}>
               <td>{couponCode}</td>
               <td>{couponType}</td>
               <td>{amount}</td>
               <td>{createdAt.slice(0,10)}</td>
               <td>{expirationDate.slice(0,10)}</td>
               <td><p className="delete">Delete</p></td>
              </tr>
            );
          }
        )}
      </table>}
        </div>
    )
}

export default Coupon;