import axios from 'axios';
import React, {useState, useEffect} from 'react';
import './ticket.scss';
import {baseUrl} from '../../config.json';
import LineLoader from '../../components/lineLoader/lineLoader';
import EmptyState from '../../components/emptyState/emptyState';
import CreateTicket from './createTicket/createTicket';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import DeleteModalx from './deleteModal/deleteModalx';

const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
       }
 }

const Ticket = () => {
    const [tickets, setTickets] = useState([]);
    // const [ticketId, setTicketId] = useState();
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [ticketModal, setTicketModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);



    const history = useHistory();

    useEffect(() => {
        (async () => {
            const {data} = await axios.get(`${baseUrl}/admin/ticket/retrieve-tickets`, config)
            console.log(data)
            setTickets(data.data)
            setLoading(false)
        })()
    }, []);

    const deleteTicket = async (id) => {
        try {
            const {data} = await axios.delete(`${baseUrl}/admin/ticket/${id}/delete-ticket`, config)
            console.log(data)
            toast.success(data.message)
            window.location.reload()
        } catch (error) {
            toast.error(error?.response?.data?.message)
        }
    }


    return (
        <div className="ticket">
            <div className="top">
                <p className="title">Support</p>
                {/* <p onClick={() => setTicketModal(true)} className="create"><i className="fas fa-plus"></i>Create new ticket</p> */}
            </div>
            
            {loading ? <LineLoader /> :
            tickets.length === 0 ? <EmptyState /> :
            <div className="tickets">
                {tickets.map(({subject, description, _id: id, status}) => {
                    return (
                        <div  key={id}>
                        <i onClick={() => deleteTicket(id)} className="fas fa-trash-alt"></i>
                        <div onClick={() => history.push(`/dashboard/ticket/${id}`)} className="item">
                            <p className="sub">{subject}</p>
                            <p className="desc">{description.slice(0,20)}...</p>
                            <p className={status === "open" ? "status" : "statusC"}>{status}</p>
                        </div>
                        </div>
                    )
                })}
            </div>}

            {ticketModal && <CreateTicket setTicketModal={setTicketModal} />}
            {/* {showDeleteModal && <DeleteModalx text="This action cannot be undone, sure to proceed?" ticketId={ticketId} requestFunction={deleteTicket} setShowDeleteModal={setShowDeleteModal} loading={loading2} />} */}
        </div>
    )
}

export default Ticket
