import React from "react";
import "./newModal.scss";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { baseUrl } from "../../../config.json";
import axios from "axios";
import LineLoader from "../../lineLoader/lineLoader";
import routesx from "../temp";
import { toast } from "react-toastify";

const config = {
  headers: {
     "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
     }
}

const NewModal = ({ driverId, setCustomerView }) => {
  const [pType, setPType] = useState("");
  const [driver, setDriver] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rLoader, setRLoader] = useState(false);

  const currentUser = useSelector(({ userData }) => userData.currentUser);

  const { walletBalance, _id: id, email } = currentUser.data;

  useEffect(() => {
    const res = axios
      .get(`${baseUrl}/wehaul/driver/${driverId}`)
      .then((res) => setDriver(res?.data?.data))
      .then((res) => setLoading(false))
      .catch((err) => console.log(err));
  });

  const handleRoutez = (e) => {
    let newArrayz = [...selected, e.target.value];
    if (selected.includes(e.target.value)) {
      newArrayz = newArrayz.filter((d) => d !== e.target.value);
    }
    setSelected(newArrayz);
  };

  const datar = {
    "routes": selected
}

  const addNewRoutes = async (e) => {
    e.preventDefault();
    setRLoader(true);
    try {
      const res = await axios.put(
        `${baseUrl}/wehaul/driver/${driverId}/add-preferred-routes`,
        datar,
        config
      );
      console.log(res.data);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setRLoader(false);
      //  window.location.reload();
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setRLoader(false);
    }
  };

  return (
    <div className="new-modal">
      <div className="wrapper">
        <div className="modal">
        <i
                  className="fas fa-times"
                  onClick={() => setCustomerView(false)}
                ></i>
          {loading ? (
            <LineLoader />
          ) : (
            <>
              <div className="top-head">
                {/* <i
                  className="fas fa-times"
                  onClick={() => setCustomerView(false)}
                ></i> */}
                <div className="left">
                  {/* <div className="pic" style={{backgroundImage: `url(${driver?.vehicleInsuranceImage})`}}>

                  </div> */}
                  <p className="title">{driver?.name}</p>
                  <p className="para">{driver?.phoneNumber}</p>
                </div>
                <div className="right">
                  <p className="label">Vehicle Type</p>
                  <p className="value">{driver?.vehicleBrand + " " + driver?.vehicleModel}</p>
                </div>
              </div>
              <p>Referred by: <strong>{driver?.referralCode}</strong></p><br />
              <p>Vehicle Location: <strong>{driver?.vehicleLocation}</strong></p>
              <form className="body">
                <a target="_blank" href={driver?.carriagePermitImage}><div className="img" style={{backgroundImage: `url(${driver?.carriagePermitImage})`}}></div></a>
                <a target="_blank" href={driver?.vehicleInsuranceImage}><div className="img" style={{backgroundImage: `url(${driver?.vehicleInsuranceImage})`}}></div></a>
              </form>
              
              <br />
              
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewModal;
