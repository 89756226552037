import React, { useState } from "react";
// import AddCustomer from '../request-components/add-customer/addCustomer';
import "./drivers.scss";
import CustomerList from "../../static/customers";
import Overview from "../../components/dashboard-components/overview/overview";
// import ViewCustomer from './view-customer/viewCustomer';
import { useQuery } from "react-query";
import { getAllCustomers } from "../../services/customerServices";
import Loader from "../../components/loader/loader";
import axios from "axios";
import { baseUrl } from "../../config.json";
import { toast, ToastContainer } from "react-toastify";
import { getAllDrivers } from "../../services/driverServices";
import { useEffect } from "react";
import ViewCustomer from "../../components/customers-components/view-customer/viewCustomer";
import ViewDriver from "./view-driver/viewDriver";
import AddCustomer from "../../components/request-components/add-customer/addCustomer";
import AddDriver from "./add-driver/addDriver";
import moment from "moment";
import { ExportJsonCsv } from "react-export-json-csv";
import { useSelector } from "react-redux";
import { ChakraProvider, Table, TableContainer, Tbody, Thead, Tr } from "@chakra-ui/react";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`,
  },
};

const Drivers = () => {
  const [driverView, setDriverView] = useState(false);
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const currentUser = useSelector(({ userData }) => userData.currentUser);
  console.log(currentUser?.data);
  const { userClass } = currentUser?.data;

  const { data, isLoading, error } = useQuery(
    ["getAllDriversx", page],
    ({ queryKey }) => getAllDrivers(queryKey[1])
  );
  if (isLoading) {
    console.log("loading");
  } else if (data) {
    console.log("getAllDrivers", data.data);
  } else {
    console.log(error);
  }

  const headers = [
    {
      key: "name",
      name: "Name",
    },
    {
      key: "email",
      name: "Email",
    },
    {
      key: "phoneNumber",
      name: "Phone Number",
    },
    {
      key: "createdAt",
      name: "Date Joined",
    },
  ];

  const headers2 = [
    {
      key: "name",
      name: "Name",
    },
    {
      key: "email",
      name: "Email",
    },
  ];

  const getDriverDetails = async (id) => {
    await setUserId(id);
    setDriverView(true);
    console.log("hi", id);
  };

  const deleteDriver = (id) => {
    // setUserId(id);
    axios
      .delete(`${baseUrl}/admin/delete-driver/${id}`, config)
      .then((res) =>
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        })
      )
      .then((res) => window.location.reload())
      .catch((err) =>
        toast.error("An error occured, please try again", {
          position: toast.POSITION.TOP_CENTER,
        })
      );
  };

  const verify = async (id) => {
    setLoading(true);
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`,
      },
      // body: JSON.stringify({ title: 'React PUT Request Example' })
    };
    try {
      await fetch(`${baseUrl}/admin/verify-driver/${id}`, requestOptions)
        .then((res) => res.json())
        .then((res) =>
          toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER,
          })
        )
        .then((res) => setLoading(false))
        .then((res) => window.location.reload());
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const banDriver = async (id) => {
    //   const id = e.target.id
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`,
      },
      // body: JSON.stringify({ title: 'React PUT Request Example' })
    };
    try {
      await fetch(`${baseUrl}/admin/ban-driver/${id}`, requestOptions)
        .then((res) => res.json())
        .then((res) =>
          toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER,
          })
        )
        .then((res) => window.location.reload());
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    //  const id = e.target.id
    //  await axios.put(`${baseUrl}/enterprise/driver/${id}/ban-driver`, config)
    //        .then(res => console.log(res.data))
    //        .catch(err => console.log(err))
    //  try {
    //     const res = await axios.put(`${baseUrl}/enterprise/driver/${id}/ban-driver`, config);
    //     toast.success(res.data.data.message, {
    //         position: toast.POSITION.TOP_CENTER
    //     })
    //  } catch (error) {
    //    toast.error(error?.response?.data?.message, {
    //        position: toast.POSITION.TOP_CENTER
    //    })
    //  }
  };

  // const banCustomer = (id) => {
  //     // setUserId(id)

  //     axios.put(`${baseUrl}/admin/ban-customer/${id}`, config)
  //     .then(res => toast.success(res.data.data, {
  //       position: toast.POSITION.TOP_CENTER
  //     }))
  //     .catch(err => toast.error("An error occured, please try again", {
  //       position: toast.POSITION.TOP_CENTER
  //     }))
  // }

  return (
    <ChakraProvider>
      <div className="customers">
        <Overview />

        <div className="heading" style={{overflowX: "scroll !important"}}>
          <div className="left-items">
            <div className="title">
              {/* <i
                onClick={() => (window.location.href = "/dashboard")}
                className="fas fa-home"
              ></i> */}
              <span>Drivers</span>
            </div>
            <p className="request-count">{data?.data?.drivers.length}</p>
            {/* <p style={{backgroundColor: "rgba(75, 112, 170, .3)", padding: ".7rem", borderRadius: "5px", fontWeight: "600"}} className='next'>{page > 1 && <i onClick={() => setPage(page - 1)} style={{transform: "translateY(1px)", cursor: "pointer", marginRight: "20px"}} className="fas fa-angle-left"></i>} {page} <i onClick={() => setPage(page + 1)} style={{transform: "translateY(1px)", cursor: "pointer", marginLeft: "20px"}} className="fas fa-angle-right"></i></p> */}
            {/* <p className="export-btn">Export</p> */}
            <ExportJsonCsv headers={headers} items={data?.data?.drivers}>
              Export
            </ExportJsonCsv>
            <div className="input">
              <input
                onChange={(e) => setSearchValue(e.target.value)}
                type="tel"
                placeholder="Search by phone"
              />
              <i className="fas fa-search"></i>
            </div>
            {/* <ExportJsonCsv headers={headers2} items={data?.data?.drivers}>Export Emails</ExportJsonCsv> */}
          </div>
          <div className="right-items">
            <AddDriver />
          </div>
        </div>

        <TableContainer>
          <Table textAlign="left" variant="simple">
            <Thead>
              <Tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Date Joined</th>
              <th>Vehicle Type</th>
              <th>Action</th>
              </Tr>
            </Thead>
            <Tbody>
            {isLoading ? (
            <Loader />
          ) : (
            data?.data?.drivers
              .filter((d) => d?.phoneNumber.includes(searchValue))
              .map(
                (
                  {
                    name,
                    phoneNumber,
                    profilePicture,
                    email,
                    isVerified,
                    vehicleInformationAndLicense,
                    createdAt,
                    isBanned,
                    _id: id,
                  },
                  index
                ) => {
                  return (
                    <tr className="content-row" key={index}>
                      {/* <td>{index + 1}</td> */}
                      {/* <td><div className='img'><img src={profilePicture} alt="pp" /></div></td> */}
                      <td style={{textAlign: "left !important"}}>
                        <p>
                          {name}
                          {isBanned && (
                            <i
                              style={{
                                cursor: "pointer",
                                fontSize: "1.1em",
                                color: "#F3402B",
                                margin: "0 5px",
                                backgroundColor: "#e0f5f5",
                                padding: "6px",
                                borderRadius: "2px",
                              }}
                              className="fas fa-ban"
                            ></i>
                          )}
                        </p>
                      </td>
                      <td>{phoneNumber}</td>
                      <td>{email}</td>
                      {/* <td>{moment().add(10, 'days').calendar(createdAt)}</td> */}
                      <td>{createdAt.slice(0, 10)}</td>
                      {/* <td></td> */}
                      <td>{vehicleInformationAndLicense?.vehicleType}</td>
                      <td className="flx">
                        {!isVerified ? (
                          <span
                            onClick={() => verify(id)}
                            style={{
                              cursor: "pointer",
                              fontSize: "1.1em",
                              color: "green",
                              margin: "0 5px",
                              backgroundColor: "#e0f5f5",
                              padding: "6px",
                              borderRadius: "2px",
                            }}
                          >
                            Verify
                            <i
                              style={{ marginLeft: "5px" }}
                              className="fas fa-check"
                            ></i>
                          </span>
                        ) : (
                          <span
                            onClick={() => verify(id)}
                            style={{
                              cursor: "pointer",
                              fontSize: "1.1em",
                              color: "red",
                              margin: "0 5px",
                              backgroundColor: "#e0f5f5",
                              padding: "6px",
                              borderRadius: "2px",
                            }}
                          >
                            Unverify
                            <i
                              style={{ marginLeft: "5px" }}
                              className="fas fa-times"
                            ></i>
                          </span>
                        )}
                        <i
                          onClick={() => getDriverDetails(id)}
                          style={{
                            cursor: "pointer",
                            fontSize: "1.1em",
                            color: "#113E82",
                            margin: "0 5px",
                            backgroundColor: "#e0f5f5",
                            padding: "6px",
                            borderRadius: "2px",
                          }}
                          className="fas fa-eye"
                        ></i>
                        {userClass === "superAdmin" && (
                          <i
                            onClick={() => deleteDriver(id)}
                            style={{
                              cursor: "pointer",
                              fontSize: "1.1em",
                              color: "#113E82",
                              margin: "0 5px",
                              backgroundColor: "#e0f5f5",
                              padding: "6px",
                              borderRadius: "2px",
                            }}
                            className="fas fa-trash"
                          ></i>
                        )}
                        {!isBanned && (
                          <i
                            onClick={() => banDriver(id)}
                            style={{
                              cursor: "pointer",
                              fontSize: "1.1em",
                              color: "#F3402B",
                              margin: "0 5px",
                              backgroundColor: "#e0f5f5",
                              padding: "6px",
                              borderRadius: "2px",
                            }}
                            className="fas fa-ban"
                          ></i>
                        )}
                        {isBanned && (
                          <i
                            onClick={() => banDriver(id)}
                            style={{
                              cursor: "pointer",
                              fontSize: "1.1em",
                              color: "green",
                              margin: "0 5px",
                              backgroundColor: "#e0f5f5",
                              padding: "6px",
                              borderRadius: "2px",
                            }}
                            className="fas fa-lock-open"
                          ></i>
                        )}
                      </td>
                    </tr>
                  );
                }
              )
          )}
            </Tbody>
          </Table>
        </TableContainer>


        {driverView && (
          <ViewDriver userId={userId} setCustomerView={setDriverView} />
        )}
      </div>
    </ChakraProvider>
  );
};

export default Drivers;
