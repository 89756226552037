import React from 'react';
import './makeRequest.scss';

import { Link } from 'react-router-dom';
import AddCustomer from './add-customer/addCustomer';
import RequestForms from './request-forms/requestForms';
import Request from './request';

const MakeRequest = () => {
    return ( 
        <div className="make-request">
            

            <div className="heading">
                <div className="left-items">
                    <div className="title"><Link to='/dashboard/request' style={{textDecoration: 'none'}}><i className="fas fa-home"></i></Link><span>Requests</span></div>
                </div>
            </div>
            <RequestForms />
        </div>
     );
}
 
export default MakeRequest;