import React, {useState} from "react";
import "./requestPreview.scss";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import ButtonLoader from "../../buttonLoader/buttonLoader";
import { useDispatch } from "react-redux";
import { userActionTypes } from "../../../redux/constants/userActionTypes";

const RequestPreview = ({
  formData,
  previewTemp,
  requestFunction,
  vehicleType,
  icon,
  setShowPreview,
  lowerBoundValue,
  loading
//   setPhoneNumber,
//   setPickUpAd,
//   setDestAd,
//   setPickUpRoute,
//   setDestRoute,
//   setPickUpDate,
//   setPickUpTime,
//   setReqTitle,
//   setTitledDesc,
}) => {

  const currentUser = useSelector(({ userData }) => userData.currentUser);
  console.log(currentUser.data);

  const { walletBalance } = currentUser.data

  const dispatch = useDispatch();

  // const [loading,setLoading] = useState(false)

  const history = useHistory();

  const FinalSubmit = () => {
    dispatch({type: userActionTypes.BIKEREQLOADER, payload: true})
    // if(vehicleType  !== "Bike") {
    //   if(walletBalance >= lowerBoundValue) {
        requestFunction(formData, history);
        console.log(formData);
    //   } else {
    //     toast.warn('wallet balance insufficient', {
    //       position: toast.POSITION.TOP_CENTER
    //     })
    //   }
    // } else {
    //   requestFunction(formData, history);
    //   console.log(formData);
    // }
  };

  return (
    <div className="request-preview">
      <div className="wrapper">
        <div className="req-modal">
          <i onClick={() => setShowPreview(false) } className="fas fa-times"></i>
          <div className="top-head">
            <p className="title">
              Confirm {vehicleType} <br /> Request
            </p>
            <i className={icon}></i>
          </div>
          <div className="body">
            {previewTemp.map(({ label, value }, index) => {
              return (
                <div className="info" key={index}>
                  <p className="label">{label}</p>
                  <p className="value">{value}</p>
                </div>
              );
            })}

          </div>
          <div className="btn">
            {/* <Link to='/dashboard/request/drivers'> */}
          <button onClick={FinalSubmit}>{loading && <ButtonLoader />}Confirm</button>
            {/* </Link> */}
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default RequestPreview;
