import React, { useState } from "react";
import "./routeNew.scss";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import AutoComplete from "../../components/autoComplete/autocomplete";
import axios from 'axios';
import { toast } from 'react-toastify';
import {Link} from 'react-router-dom';
import { useDispatch } from 'react-redux';


const RouteNew = ({routes}) => {

const [loading, setLoading] = useState(false);

  const currentUser = useSelector(({ userData }) => userData.currentUser);
  console.log(currentUser.data);
 


   

const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
       }
}

const dispatch = useDispatch();


// const makeBikeRequest = async (formData, history) => {
//    if(!localStorage.BIKE_DRIVERS_DATA) {
//       try {
//          const { data } = await axios.post(`https://wehaul-nig.herokuapp.com/api/v1/customer/request/make-bike-request`, formData, config);
//              toast.success(data.data.message, {
//                 position: toast.POSITION.TOP_CENTER
//              })
//              console.log(data.data)
//              dispatch({type: userActionTypes.BIKEREQLOADER, payload: false})
//              localStorage.setItem("BIKE_DRIVERS_DATA", JSON.stringify(data?.data?.qualifiedBikeRiders))
//              localStorage.removeItem('asibikdri')
//              history.push('/dashboard/request/drivers', {requestData: data?.data})
//        } catch(error) {
//           if(error) {
//              toast.error(error.response.data.message, {
//                 position: toast.POSITION.TOP_CENTER
//              })
//              dispatch({type: userActionTypes.BIKEREQLOADER, payload: false})
//           }
//        }
//    } else {
//       toast.warn(CustomToast,{
//          position: toast.POSITION.TOP_CENTER
//       })
//    }
   
// }

 

  const [pickUpAd, setPickUpAd] = useState("");
  const [destAd, setDestAd] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng:null
});
  const [coordinates2, setCoordinates2] = useState({
    lat: null,
    lng:null
});


 
  const formData = {
    "name": pickUpAd,
    "lat": coordinates.lat,
    "lng": coordinates.lng,
    "description": `${pickUpAd} is ${pickUpAd}`
}

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("helooooooo", formData)

    try {
                 const { data } = await axios.post(`https://app.wehaul247.com/api/v1/admin/add-route`, formData, config);
                     console.log(data)
                     toast.success(data?.message, {
                        position: toast.POSITION.TOP_CENTER
                     })
                     setLoading(false)
                     
               } catch(error) {
                     toast.error(error?.response?.data?.message, {
                        position: toast.POSITION.TOP_CENTER
                     })
                     setLoading(false)
               }
  }
  
//   function SubmitButton() {
//     if(receiversPhone && receiversName && pickUpAd && destAd && pickUpDate && pickUpTime && reqTitle && goodsImage && titledDesc) {
//       return <button type="submit">Create Request</button>
//     } else {
//       return <button disabled type="submit">Create Request</button>
//     }
//   }

 

  return (
    <div className="bike-request">
      <Link style={{color: "#113E82",textDecoration: "none"}} to="/dashboard/routes">
      <div className="back" style={{color: "#113E82", marginTop: "20px", cursor: "pointer"}}><i style={{marginRight: "5px"}} className="fas fa-arrow-left"></i>Back</div>
      </Link>
      <form onSubmit={handleSubmit}>

        <AutoComplete setCoordinates={setCoordinates}  setPickUpAd={setPickUpAd} />

        <button style={{padding: "5px 10px", marginTop: "5px", fontSize: "14px"}} type="submit">{!loading ? "Add Route" : "Adding..."}</button>
      </form>

      
    </div>
  );
};

export default RouteNew;
