import React, { useState } from 'react';
import ButtonLoader from '../../../components/buttonLoader/buttonLoader';
import './addRoute.scss';
import axios from 'axios';
import { baseUrl } from '../../../config.json';
import { toast } from 'react-toastify';
import AutoComplete from "../../../components/autoComplete/autocomplete";

const AddRoute = ({setShowModal}) => {
    const [loading, setLoading] = useState(false);
    const [coordinates, setCoordinates] = useState({
        lat: null,
        lng:null
    });
      const [coordinates2, setCoordinates2] = useState({
        lat: null,
        lng:null
    });
    const [pickUpAd, setPickUpAd] = useState("");
    

    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    // const [price, setPrice] = useState('');

    const data = {
        name,
        "description": desc
    }

    const config = {
        headers: {
           "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
           }
     }

    const addNewRoute = async e => {
        e.preventDefault();
        setLoading(true);
        console.log(data)
        try {
           const res = await axios.post(`${baseUrl}/admin/add-route`, data, config)
           console.log(res.data)
           toast.success(res.data.message, {
               position: toast.POSITION.TOP_CENTER
           })
           window.location.reload();
        } catch (error) {
          console.log(error)
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_CENTER
        })
        }
    }

    return ( 
        <div className="add-route">
            <div className="wrapper">
                <div className="modal">
                    <div className="top-content">
                        <p className="title">Create Route</p>
                        {/* <i className="fas fa-route"></i> */}
                    </div>
                    <div className="bottom-content">
                        <form onSubmit={addNewRoute}>
                        <AutoComplete setCoordinates={setCoordinates}  setPickUpAd={setPickUpAd} vehicleData={data?.data?.data[3]} />
                            {/* <input type="text" placeholder="Enter Area" onChange={e => setName(e.target.value)} /> */}
                             <br />
                             <input type="text" placeholder="Describe Area" onChange={e => setDesc(e.target.value)} />
                            {/* <select name="" id="" onChange={e => setFrom(e.target.value)}>
                                <option>From</option>
                                <option value="lagos mainland">Lagos Mainland</option>
                                <option value="lagos island">Lagos Island</option>
                                <option value="ikeja">ikeja</option>
                                <option value="festac">festac</option>
                                <option value="ikotun">ikotun</option>
                                <option value="oshodi">oshodi</option>
                                <option value="ajah">ajah</option>
                            </select> <br /> */}
                            <button type="submit">{loading && <ButtonLoader />} Add Route</button>
                            <button onClick={() => setShowModal(false)} style={{backgroundColor: "#40D192", margin: "10px 0", padding: "8px", width: "fit-content"}}>back</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default AddRoute;