const routesx =[
    "agege motor road, lagos, nigeria",
    "agege pen cinema bridge, ogba road, ikeja, nigeria",
    "iba junction bus stop, lagos, nigeria",
    "ojo barracks, school of music, barracks, nigeria",
    "ajegunle, lagos, nigeria",
    "ajao estate, lagos, nigeria",
    "apapa, lagos, nigeria",
    "sango ota, agege, nigeria",
    "surulere, lagos, nigeria",
    "ebute metta, lagos, nigeria",
    "bariga, oworonshoki, nigeria",
    "berger bus-stop, ojodu berger, nigeria",
    "ibafo, nigeria",
    "mowe, loburo, nigeria",
    "ogba, lagos, nigeria",
    "maryland, lagos, nigeria",
    "satellite town, lagos, nigeria",
    "somolu, lagos, nigeria",
    "ilupeju, ikeja, nigeria",
    "ketu, lagos, nigeria",
    "ikoyi, lagos, nigeria",
    "victoria island, lagos, nigeria",
    "ikeja, nigeria",
    "yaba bus park, lagos, nigeria",
    "epe, nigeria",
    "ikorodu, nigeria",
    "lagos island, lagos, nigeria",
    "lekki phase 1, lekki, nigeria",
    "ajah, lagos, nigeria",
    "badagry, nigeria",
    "agbara estate, lagos - badagry expy, agbara, nigeria",
    "festac town, lagos, nigeria",
    "isolo general hospital, isolo rd, ikeja, nigeria",
    "mushin, lagos, nigeria",
    "ogudu gra estate, lagos, nigeria",
    "oshodi, lagos, nigeria",
    "gbagada, lagos, nigeria",
    "oworonshoki, oworonshoki, nigeria",
    "ojota bus stop, ikorodu road, lagos, nigeria",
    "ikosi ketu, lagos, nigeria",
    "akowonjo road, lagos, nigeria",
    "igando, lagos, nigeria",
    "ikotun, lagos, nigeria",
    "idimu road, lagos, nigeria",
    "egbeda, akowonjo road, lagos, nigeria",
    "ipaja, lagos, nigeria",
    "ayobo, lagos, nigeria",
    "oke odo, lagos, nigeria",
    "agbado - ijaiye road, lagos, nigeria",
    "aboru - abesan link bridge, lagos, nigeria"
]

export default routesx;