import React, { useState, useEffect } from 'react';
import Payment from '../../payments/payment';
import { useSelector } from "react-redux";
import './withdrawModal.scss';
import ButtonLoader from '../../buttonLoader/buttonLoader';
import {baseUrl} from '../../../config.json';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import {getAllMerchants} from '../../../services/merchantServices'
import {getAllDrivers} from '../../../services/driverServices'
import {getAllCustomers} from '../../../services/customerServices'
import {getAllEnterprise} from '../../../services/enterpriseServices'

const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
       }
 }

const WithdrawModal = ({setShowWithdrawModal}) => {
    const [withdrawAmount, setWithdrawAmount] = useState('');
    const [reason, setReason] = useState('');
    const [loading, setLoading] = useState(false);
    const [def, setDef] = useState('customers')
    const [dataShown, setDataShown] = useState([])

    const history = useHistory();

    const currentUser = useSelector(({ userData }) => userData.currentUser);
    console.log(currentUser.data);

    const { email, _id } = currentUser.data

   

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            "amount": withdrawAmount,
            "userClass": "customer",
            "description": "fund"
        }

        const id = ""

        try {
            const res = await axios.post(`${baseUrl}/admin/finance/fund-user-wallet/${id}`, data, config)
            console.log(res.data)
            setLoading(false)
            toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER
            })
            window.location.reload()
        } catch (error) {
            console.log(error.response.data.message)
            setLoading(false)
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_CENTER
            })
        }
    }

    return ( 
        <div className="amount-modal">
            <div className="wrapper">
                <div className="modal">
                <i onClick={() => setShowWithdrawModal(false)} className="fas fa-times"></i>
                    <div className="top-head">
                        <p className="title">Amount to Fund</p>
                    </div>
                    <div className="body">
                        <form onSubmit={handleSubmit}>
                            <input required value={withdrawAmount} onChange={e => setWithdrawAmount(e.target.value.trim())} type="tel" placeholder="Enter Amount" />
                            <label htmlFor="reason">User to fund</label>
                            <select required onChange={e => setReason(e.target.value)} name="reason" id="reason">
                                <option selected disabled></option>
                                <option value="customer">customer</option>
                                <option value="merchant">merchant</option>
                                <option value="driver">driver</option>
                                <option value="Enterprise">Enterprise</option>
                            </select>
                            <div className="btn">
                                <button>{loading && <ButtonLoader />}Fund User</button>
                            </div>
                        </form>
                    </div>
                    <div className="user-sec">
                        {loading ? "loading users" : 
                        dataShown.map(i => {
                            return (
                                <p>hello</p>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default WithdrawModal;