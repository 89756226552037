import React from 'react';
import './deleteModalx.scss';
import ButtonLoader from '../../../components/buttonLoader/buttonLoader';


const DeleteModalx = ({setShowDeleteModal, loading, text, requestFunction}) => {
    return ( 
        <div className="delete-modal">
            <div className="wrapper">
                <div className="modal">
                    <div className="top-head">
                      <i className="fas fa-exclamation-triangle"></i><p className="title">Are you sure ?</p>
                    </div>
                    <div className="body">
                        <p className="text">{text}</p>
                        <div className="btns">
                            <button onClick={() => setShowDeleteModal(false)} className="cancel">CANCEL</button>
                            <button onClick={requestFunction} className="continue">{loading ? <ButtonLoader /> : "PROCEED"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default DeleteModalx;