import React from "react";
import "./vedit.scss";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { baseUrl } from "../../../config.json";
import axios from "axios";
import LineLoader from "../../lineLoader/lineLoader";
import { useQuery } from "react-query";
import { getAllVehicles } from "../../../services/routeServices";
import { toast } from "react-toastify";
import routesx from "../temp";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`,
  },
};

const Vedit = ({ driverId, setCustomerView }) => {
  const [vType, setVType] = useState("");
  const [loading, setLoading] = useState();
  const [loading2, setLoading2] = useState();

  const [selected, setSelected] = useState([]);
  const [rLoader, setRLoader] = useState(false);
  const [driver, setDriver] = useState([]);

  const currentUser = useSelector(({ userData }) => userData.currentUser);

  const { walletBalance, _id: id, email } = currentUser.data;

  const { data, error, isLoading } = useQuery("getAllVehicles", getAllVehicles);
  if (isLoading) {
    console.log("loading");
  } else if (data) {
    console.log("getAllVehicles", data.data);
  } else {
    console.log(error);
  }

  useEffect(() => {
    const res = axios
      .get(`${baseUrl}/wehaul/driver/${driverId}`)
      .then((res) => setDriver(res?.data?.data))
      .then((res) => setLoading2(false))
      .catch((err) => console.log(err));
  });

  const datax = {
    vehicleType: vType,
  };

  const update = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.put(
        `${baseUrl}/wehaul/driver/${driverId}/update-details`,
        datax
      );
      console.log(data.data);
      toast.success(data.message);
      setLoading(false);
      // window.location.reload();
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const handleRoutez = (e) => {
    let newArrayz = [...selected, e.target.value];
    if (selected.includes(e.target.value)) {
      newArrayz = newArrayz.filter((d) => d !== e.target.value);
    }
    setSelected(newArrayz);
  };

  const datar = {
    routes: selected,
  };

  const addNewRoutes = async (e) => {
    e.preventDefault();
    setRLoader(true);
    try {
      const res = await axios.put(
        `${baseUrl}/wehaul/driver/${driverId}/add-preferred-routes`,
        datar,
        config
      );
      console.log(res.data);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setRLoader(false);
       window.location.reload();
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setRLoader(false);
    }
  };

  return (
    <div className="new-modal">
      <div className="wrapper">
        <div className="modal">
          <i
            onClick={() => setCustomerView(false)}
            className="fas fa-times"
          ></i>
          {loading2 ? <LineLoader /> : <>
          <form onSubmit={update}>
            <p className="title">Update Vehicle Type <span>{driver?.vehicleType}</span></p>
            <div className="input">
              <select onChange={(e) => setVType(e.target.value)}>
                <option selected disabled></option>
                {data?.data?.data.map(({ vehicleType }, index) => {
                  return (
                    <option key={index} value={vehicleType}>
                      {vehicleType}
                    </option>
                  );
                })}
              </select>
            </div>
            <br />
            <button type="submit">{loading ? "UPDATING.." : "UPDATE"}</button>
          </form>

          <div className="select">
            <div className="personal-info">
              <p className="title">Preferred Routes</p>

              <div
                style={{ height: "100px", overflowY: "scroll" }}
                className="routes"
              >
                {driver?.routes?.map((i, index) => {
                  return <p key={index}>{i}</p>;
                })}
              </div>
              <br />

              <div className="routes">
                <p style={{ marginBottom: ".7rem" }}>Add New Routes</p>
                {routesx.map((i, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "13px",
                        alignItems: "center",
                      }}
                      className="route"
                      key={index}
                    >
                      <input
                        style={{ transform: "scale(1.7)", marginRight: "30px" }}
                        type="checkbox"
                        value={i}
                        onChange={(e) => handleRoutez(e)}
                      />
                      <p style={{ fontWeight: "600" }} className="item">
                        {i}
                      </p>
                    </div>
                  );
                })}
              </div>
              <button
                style={{
                  padding: "1rem",
                  backgroundColor: "#113e82",
                  color: "#FFF",
                  width: "40%",
                  border: "none",
                  borderRadius: "6px",
                  outline: "none",
                }}
                onClick={addNewRoutes}
              >
                {rLoader ? "Saving" : "Save"}
              </button>
            </div>
          </div>
          </>}
        </div>
      </div>
    </div>
  );
};

export default Vedit;
