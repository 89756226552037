import React, { useState } from 'react';
import AddCustomer from '../request-components/add-customer/addCustomer';
import './offDrivers.scss';
// import CustomerList from '../../static/customers';
import Overview from '../dashboard-components/overview/overview';
// import ViewCustomer from './view-customer/viewOff';
import { useQuery } from 'react-query';
import { getAllOfflineDrivers } from '../../services/customerServices';
import Loader from '../loader/loader';
import axios from 'axios';
import { baseUrl } from '../../config.json'
import { toast, ToastContainer } from 'react-toastify'
import ViewOff from './viewOff/viewOff';
import { ExportJsonCsv } from 'react-export-json-csv';
import NewModal from './newModal/newModal';
import Vedit from './vedit/vedit';
import { getAllVehicles } from '../../services/vehicleServices';
import { useSelector } from 'react-redux';

const config = {
  headers: {
     "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
     }
}


const OffDrivers = () => {
    const [customerView, setCustomerView] = useState(false);
    const [customerView2, setCustomerView2] = useState(false);
    const [userId, setUserId] = useState('');
    const [searchValue, setSearchValue] = useState("");
    const [vt, setVt] = useState("s");

    const currentUser = useSelector(({userData}) => userData.currentUser)
    console.log(currentUser?.data)
    const { userClass } = currentUser?.data



    const { data, error, isLoading} = useQuery('get all offline drivers', getAllOfflineDrivers);
    if(isLoading) {
      console.log('loading')
    } else if (data) {
      console.log('getAllOfflineDrivers', (data?.data?.data))
    } else {
      console.log(error)
    }

    const { data: datax, error: errorx, isLoading: isLoadingx  } = useQuery('getAllVehicles', getAllVehicles);
    if(isLoadingx) {
      console.log('loading')
    } else if (datax) {
      console.log('getAllVehicles', datax.data)
    } else {
      console.log(errorx)
    }

    const headers = [
      {
        key: 'name',
        name: 'Name',
      },
      {
        key: 'phoneNumber',
        name: 'Phone Number',
      },
      {
        key: 'vehicleBrand',
        name: 'Vehicle Brand',
      },
      {
        key: 'vehicleModel',
        name: 'Vehicle Model',
      },
      {
          key: 'referralCode',
          name: 'Referrer'
      },
      {
          key: 'isSmartphone',
          name: 'Smart Phone'
      },
      {
          key: 'preferredRoutes',
          name: 'Routes'
      },
      {
          key: 'providedDocs',
          name: 'Documents'
      },
      {
        key: 'vehicleLocation',
        name: 'Location'
      }
    ]

    // const headers2 = [
    //   {
    //     key: "vehicleLocation",
    //     name: "Vehicle Locations"
    //   }
    // ]

    const getCustomerDetails = (id) => {
      setCustomerView(true)
      console.log('hi', id);
      setUserId(id)
    }

    const deleteDriver = (id) => {
      // setUserId(id);
      axios.delete(`${baseUrl}/wehaul/driver/delete/${id}`, config)
      .then(res => toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER
      }))
      .then(res => window.location.reload())
      .catch(err => toast.error('An error occured, please try again', {
        position: toast.POSITION.TOP_CENTER
      }))

    }

    const banCustomer = async (id) => {
      //   const id = e.target.id
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}` }
          // body: JSON.stringify({ title: 'React PUT Request Example' })
      };
      try {
          await fetch(`${baseUrl}/admin/ban-customer/${id}`, requestOptions)
          .then(res => res.json())
          .then(res => 
                toast.success(res.message, {
                          position: toast.POSITION.TOP_CENTER
                      })
              )
          .then(res => window.location.reload())
      } catch (error) {
          toast.error(error?.response?.data?.message, {
                     position: toast.POSITION.TOP_CENTER
                 })
      }
      
      //  const id = e.target.id
      //  await axios.put(`${baseUrl}/enterprise/driver/${id}/ban-driver`, config)
      //        .then(res => console.log(res.data))
      //        .catch(err => console.log(err))
      //  try {
      //     const res = await axios.put(`${baseUrl}/enterprise/driver/${id}/ban-driver`, config);
      //     toast.success(res.data.data.message, {
      //         position: toast.POSITION.TOP_CENTER
      //     })
      //  } catch (error) {
      //    toast.error(error?.response?.data?.message, {
      //        position: toast.POSITION.TOP_CENTER
      //    })
      //  }
  }

    // const banCustomer = (id) => {
    //     // setUserId(id)
        
    //     axios.put(`${baseUrl}/admin/ban-customer/${id}`, config)
    //     .then(res => toast.success(res.data.data, {
    //       position: toast.POSITION.TOP_CENTER
    //     }))
    //     .catch(err => toast.error("An error occured, please try again", {
    //       position: toast.POSITION.TOP_CENTER
    //     }))
    // }

    const handleView = async (id) => {
      await setUserId(id);
      setCustomerView(true)
    }
    const edit = async(id) => {
      await setUserId(id);
      setCustomerView2(true)
    }

    return ( 
        <div className="customers">
            <Overview />

            <div className="heading">
                <div className="left-items">
                    <div className="title"><i onClick={() => window.location.href = '/dashboard'} className="fas fa-home"></i><span>Offline Drivers</span></div>
                    <p className="request-count">{data?.data?.data.length}</p>
                    <div className="input">
                        <i className="fas fa-search"></i>
                        <input onChange={e => setSearchValue(e.target.value.toUpperCase())} placeholder="Search by phone" type="text" />
                    </div>
                    <ExportJsonCsv headers={headers} items={data?.data?.data}>Export</ExportJsonCsv>
                    {/* <p className="export-btn">Export</p> */}
                </div>
                {/* <div className="right-items">
                    <AddCustomer />
                </div> */}
                <div className="input">
                  <label>vehicle type</label>
                  <select onChange={e => setVt(e.target.value)}>
                    <option selected disabled>Select</option>
                    {!isLoadingx &&
                        datax?.data?.data.map(({vehicleType}, index) => {
                            return (
                                <option key={index} value={vehicleType}>{vehicleType}</option>
                            )
                        })}
                  </select>
                </div>
                
                {/* <ExportJsonCsv headers={headers2} items={data?.data?.data}>Export</ExportJsonCsv> */}
            </div>

            <table>
        <tr className="label-row">
          <th>S/N</th>
          <th>Name</th>
          <th>Phone Number</th>
          <th>Referrer</th>
          <th>Date Joined</th>
          <th>Vehicle</th>
          <th>Vehicle Type</th>
          <th>Vehicle Location</th>
          <th>Action</th>
        </tr>
        {/* {isLoading ? <Loader />  : data?.data?.data.filter(i => i.phoneNumber?.toUpperCase().includes(searchValue.toUpperCase())).map( */}
        {isLoading ? <Loader />  : data?.data?.data.filter(i => i.phoneNumber?.toUpperCase().includes(searchValue.toUpperCase())).map(
          ({
            name,
            phoneNumber,
            vehicleBrand,
            vehicleModel,
            referralCode,
            createdAt,
            vehicleLocation,
            vehicleType,
            vehicleInformationAndLicense,
            _id: id
          }, index) => {
            return (
                <tr className="content-row" key={index}>
                  <td>{index + 1 }</td>
                  <td className="lefty pre"><p>{name}</p></td>
                  <td>{phoneNumber}</td>
                  <td>{referralCode}</td>
                  <td>{createdAt?.slice(0,10)}</td>
                  <td>{vehicleBrand} {vehicleModel}</td>
                  <td>{vehicleType}</td>
                  <td>{vehicleLocation}</td>
                  <td>
                    <i onClick={() => handleView(id)} style={{cursor: 'pointer', fontSize: '1.1em', color: '#113E82',margin: '0 5px', backgroundColor: '#e0f5f5', padding: '6px', borderRadius: '2px'}} className="fas fa-eye"></i>
                    <i onClick={() => edit(id)} style={{cursor: 'pointer', fontSize: '1.1em', color: '#113E82',margin: '0 5px', backgroundColor: '#e0f5f5', padding: '6px', borderRadius: '2px'}} className="fas fa-edit"></i>
                    {userClass === "superAdmin" && <i onClick={() => deleteDriver(id)} style={{cursor: 'pointer', fontSize: '1.1em', color: '#113E82',margin: '0 5px', backgroundColor: '#e0f5f5', padding: '6px', borderRadius: '2px'}} className="fas fa-trash"></i>}
                    {/* {!isBanned && <i onClick={() => banCustomer(id)} style={{cursor: 'pointer', fontSize: '1.1em', color: '#F3402B',margin: '0 5px', backgroundColor: '#e0f5f5', padding: '6px', borderRadius: '2px'}} className="fas fa-ban"></i>}
                    {isBanned && <i onClick={() => banCustomer(id)} style={{cursor: 'pointer', fontSize: '1.1em', color: 'green',margin: '0 5px', backgroundColor: '#e0f5f5', padding: '6px', borderRadius: '2px'}} className="fas fa-lock-open"></i>} */}
                  </td>
                </tr>
                )
          })}
        
      </table>
      {customerView && <NewModal driverId={userId} setCustomerView={setCustomerView} />}
      {customerView2 && <Vedit driverId={userId} setCustomerView={setCustomerView2} />}
        </div>
     );
}
 
export default OffDrivers;