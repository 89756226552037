import React, { useState } from "react";
import AddCustomer from "../../components/request-components/add-customer/addCustomer";
import "./merchant.scss";
import CustomerList from "../../static/customers";
import Overview from "../../components/dashboard-components/overview/overview";
import ViewCustomer from "../../components/customers-components/view-customer/viewCustomer";
import { useQuery } from "react-query";
import { getAllMerchants } from "../../services/merchantServices";
import Loader from "../../components/loader/loader";
import axios from "axios";
import { baseUrl } from "../../config.json";
import { toast, ToastContainer } from "react-toastify";
import AddMerchant from "../../components/request-components/add-merchant/addMerchant";
import ViewMerchant from "./view-merchant/viewMerchant";
import { useSelector } from "react-redux";
import {
  Avatar,
  ChakraProvider,
  Table,
  TableContainer,
  Tbody,
  Thead,
  Tr,
} from "@chakra-ui/react";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`,
  },
};

const Merchants = () => {
  const [customerView, setCustomerView] = useState(false);
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);

  const currentUser = useSelector(({ userData }) => userData.currentUser);
  console.log(currentUser?.data);
  const { userClass } = currentUser?.data;

  const { data, error, isLoading } = useQuery(
    "getAllMerchants",
    getAllMerchants
  );
  if (isLoading) {
    console.log("loading");
  } else if (data) {
    console.log("getAllMerchants", data.data);
  } else {
    console.log(error);
  }

  const getCustomerDetails = (id) => {
    setCustomerView(true);
    console.log("hi", id);
    setUserId(id);
  };

  const deleteCustomer = (id) => {
    // setUserId(id);
    axios
      .delete(`${baseUrl}/admin/delete-merchant/${id}`, config)
      .then((res) =>
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        })
      )
      .then((res) => window.location.reload())
      .catch((err) =>
        toast.error("An error occured, please try again", {
          position: toast.POSITION.TOP_CENTER,
        })
      );
  };

  const banCustomer = async (id) => {
    //   const id = e.target.id
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`,
      },
      // body: JSON.stringify({ title: 'React PUT Request Example' })
    };
    try {
      await fetch(`${baseUrl}/admin/ban-merchant/${id}`, requestOptions)
        .then((res) => res.json())
        .then((res) =>
          toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER,
          })
        )
        .then((res) => window.location.reload());
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    //  const id = e.target.id
    //  await axios.put(`${baseUrl}/enterprise/driver/${id}/ban-driver`, config)
    //        .then(res => console.log(res.data))
    //        .catch(err => console.log(err))
    //  try {
    //     const res = await axios.put(`${baseUrl}/enterprise/driver/${id}/ban-driver`, config);
    //     toast.success(res.data.data.message, {
    //         position: toast.POSITION.TOP_CENTER
    //     })
    //  } catch (error) {
    //    toast.error(error?.response?.data?.message, {
    //        position: toast.POSITION.TOP_CENTER
    //    })
    //  }
  };

  const verify = async (id) => {
    setLoading(true);
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`,
      },
      // body: JSON.stringify({ title: 'React PUT Request Example' })
    };
    try {
      await fetch(`${baseUrl}/admin/verify-merchant/${id}`, requestOptions)
        .then((res) => res.json())
        .then((res) =>
          toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER,
          })
        )
        .then((res) => setLoading(false))
        .then((res) => window.location.reload());
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  // const banCustomer = (id) => {
  //     // setUserId(id)

  //     axios.put(`${baseUrl}/admin/ban-customer/${id}`, config)
  //     .then(res => toast.success(res.data.data, {
  //       position: toast.POSITION.TOP_CENTER
  //     }))
  //     .catch(err => toast.error("An error occured, please try again", {
  //       position: toast.POSITION.TOP_CENTER
  //     }))
  // }

  return (
    <div className="customers">
      <Overview />

      <div className="heading">
        <div className="left-items">
          <div className="title">
            {/* <i
              onClick={() => (window.location.href = "/dashboard")}
              className="fas fa-home"
            ></i> */}
            <span>Merchants</span>
          </div>
          <p className="request-count">{data?.data?.merchants?.length}</p>
          <p className="export-btn">Export</p>
        </div>
        <div className="right-items">
          <AddMerchant />
        </div>
      </div>

      <TableContainer>
        <Table textAlign="left" variant="simple">
          <Thead>
            <Tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Earnings</th>
              <th>Action</th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              <Loader />
            ) : (
              data?.data?.merchants.map(
                (
                  {
                    name,
                    phoneNumber,
                    email,
                    walletBalance,
                    isVerified,
                    isBanned,
                    _id: id,
                  },
                  index
                ) => {
                  return (
                    <tr className="content-row" key={index}>
                      <td>
                        <p>
                          {name}
                          {isBanned && (
                            <i
                              style={{
                                cursor: "pointer",
                                fontSize: "1.1em",
                                color: "#F3402B",
                                margin: "0 5px",
                                backgroundColor: "#e0f5f5",
                                padding: "6px",
                                borderRadius: "2px",
                              }}
                              className="fas fa-ban"
                            ></i>
                          )}
                        </p>
                      </td>
                      <td>{phoneNumber}</td>
                      <td>{email}</td>
                      <td>N{walletBalance.toLocaleString()}</td>
                      <td className="flx">
                        {!isVerified ? (
                          <span
                            onClick={() => verify(id)}
                            style={{
                              cursor: "pointer",
                              fontSize: "1.1em",
                              color: "green",
                              margin: "0 5px",
                              backgroundColor: "#e0f5f5",
                              padding: "6px",
                              borderRadius: "2px",
                            }}
                          >
                            Verify
                            <i
                              style={{ marginLeft: "5px" }}
                              className="fas fa-check"
                            ></i>
                          </span>
                        ) : (
                          <span
                            onClick={() => verify(id)}
                            style={{
                              cursor: "pointer",
                              fontSize: "1.1em",
                              color: "red",
                              margin: "0 5px",
                              backgroundColor: "#e0f5f5",
                              padding: "6px",
                              borderRadius: "2px",
                            }}
                          >
                            Unverify
                            <i
                              style={{ marginLeft: "5px" }}
                              className="fas fa-times"
                            ></i>
                          </span>
                        )}
                        <i
                          onClick={() => getCustomerDetails(id)}
                          style={{
                            cursor: "pointer",
                            fontSize: "1.1em",
                            color: "#113E82",
                            margin: "0 5px",
                            backgroundColor: "#e0f5f5",
                            padding: "6px",
                            borderRadius: "2px",
                          }}
                          className="fas fa-eye"
                        ></i>
                        {userClass === "superAdmin" && (
                          <i
                            onClick={() => deleteCustomer(id)}
                            style={{
                              cursor: "pointer",
                              fontSize: "1.1em",
                              color: "#113E82",
                              margin: "0 5px",
                              backgroundColor: "#e0f5f5",
                              padding: "6px",
                              borderRadius: "2px",
                            }}
                            className="fas fa-trash"
                          ></i>
                        )}
                        {!isBanned && (
                          <i
                            onClick={() => banCustomer(id)}
                            style={{
                              cursor: "pointer",
                              fontSize: "1.1em",
                              color: "#F3402B",
                              margin: "0 5px",
                              backgroundColor: "#e0f5f5",
                              padding: "6px",
                              borderRadius: "2px",
                            }}
                            className="fas fa-ban"
                          ></i>
                        )}
                        {isBanned && (
                          <i
                            onClick={() => banCustomer(id)}
                            style={{
                              cursor: "pointer",
                              fontSize: "1.1em",
                              color: "green",
                              margin: "0 5px",
                              backgroundColor: "#e0f5f5",
                              padding: "6px",
                              borderRadius: "2px",
                            }}
                            className="fas fa-lock-open"
                          ></i>
                        )}
                      </td>
                    </tr>
                  );
                }
              )
            )}
          </Tbody>
        </Table>
      </TableContainer>

      {customerView && (
        <ViewMerchant userId={userId} setCustomerView={setCustomerView} />
      )}
    </div>
  );
};

export default Merchants;
