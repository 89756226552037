import React from 'react';
import './pendingTrips.scss';
import {useQuery} from 'react-query';
import { getAllTrips } from '../../../services/tripServices';
import EmptyState from '../../emptyState/emptyState';

const PendTrips = [
    {
        title: 'Request title in details goes in here',
        date: '08.02.2021'
    },
    {
        title: 'Request title in details goes in here',
        date: '08.02.2021'
    },
    {
        title: 'Request title in details goes in here',
        date: '08.02.2021'
    },
    {
        title: 'Request title in details goes in here',
        date: '08.02.2021'
    },
    {
        title: 'Request title in details goes in here',
        date: '08.02.2021'
    },
]

const PendingTrips = () => {
    const { data, error, isLoading } = useQuery('getAllTrips', getAllTrips)
    if(isLoading) {
        console.log('loading all trips')
    } else if (data) {
        console.log('get all trips', data.data.data)
    } else {
        console.log(error)
    }
    return ( 
        <div className="pending-trips">
            <p className='title'>Pending Trips</p>
            {data?.data?.data.length === 0 ? <EmptyState /> :
            <div className="items">
                {data?.data?.data.filter(req => req.tripStatus === 'pending').map(({ request, _id: id}, index) => {
                return (
                    <div className="item" key={index}>
                        <div className="leftp">
                            <p className="title-text">{request?.requestTitle}</p>
                            <p className="date-text">{request?.pickUpDate}</p>
                        </div>
                        <div className="rightp">
                            <p>Pending</p>
                        </div>
                    </div>
                );
            })}
            </div>}
            
            <div className="btny">
                <button>Load More</button>
            </div>
            
        </div>
     );
}
 
export default PendingTrips;