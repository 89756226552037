import React from 'react';
import emptyImage from '../../assets/emptyState.svg';

const EmptyState = () => {
    return ( 
        <div className="empty-state" style={{textAlign: 'center', margin: '80px auto'}}>
          <img style={{width: '20%'}} src={emptyImage} alt="" />
          <p style={{marginTop: '10px', color: '#113E82', fontWeight: '600', fontSize: '15px'}} className="text">No records found</p>
        </div>
     );
}
 
export default EmptyState;
