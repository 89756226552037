import React, { useState } from 'react';
import './addCustomer.scss';
import CustomerModal from './customer-modal/customerModal';

const AddCustomer = () => {
   const [modalShown, setModalShown] = useState(false);
    return ( 
        <div className="add-customer">
           <p className='add-action' onClick={() => setModalShown(true)}><i className="fas fa-plus"></i>Add New Customer</p>
           {modalShown && <CustomerModal setModalShown={setModalShown} />}
            
        </div>
     );
}
 
export default AddCustomer;