import React, { useState } from "react";
import "./otherVehiclesRequest.scss";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
// import { makeOtherRequest } from '../../../services/requestServices';
import RequestPreview from "../requestPreview/requestPreview";
import { getAllVehicles } from "../../../services/routeServices";
import AutoComplete from "../../autoComplete/autocomplete";
import axios from 'axios';
import TLoader from "../../tLoader/tloader";
import { DistanceMatrixService } from "@react-google-maps/api";
import Compressor from "compressorjs";
import {baseUrl, devUrl, herokuBaseUrl} from '../../../config.json';
import AutoComplete2 from "../../autoComplete2/autocomplete2";
import { useLocation } from "react-router-dom";

const config = {
  headers: {
     "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
     }
}

const dTypes = ["normal", "food", "fragile", "animal", "medical", "other"]


const OtherVehiclesRequest = ({routes}) => {
  const [showPreview, setShowPreview] = useState(false);
  const [showTripEst, setShowtripEst] = useState(false);
  const [resolved, setResolved] = useState(false);
  const [loader, setLoader] = useState(false);

  const { state } = useLocation()
  // console.log(state)
  

  const bloader = useSelector(({userData}) => userData.bikereqloader);
  console.log("this is the bike loader", bloader)

   const [compressedFile, setCompressedFile] = useState(null);
   const { data, error, isLoading } = useQuery('getAllVehicles', getAllVehicles)
    if(isLoading) {
      console.log('loading all vehicles')
    } else if(data) {
        console.log('gotten all vehicles', data.data.data)
    } else {
        console.log(error)
    }

    const handleVehicle = (pricePerKilometer) => {
        console.log(pricePerKilometer)
    }

  // const currentUser = useSelector(({userData})=>  userData.currentUser);
  // console.log(currentUser.data);
  // const { name, email, pickUpAddress, businessInformation, accountInformation } = currentUser.data

  const [distance, setDistance] = useState('');
  const [distanceVal, setDistanceVal] = useState('');
  const [duration, setDuration] =  useState('')

  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng:null
  });
  const [coordinates2, setCoordinates2] = useState({
    lat: null,
    lng:null
  });

  const [receiversName, setReceiversName] = useState('');
  const [receiversPhone, setReceiversPhone] = useState('');
  const [sendersName, setSendersName] = useState('');
  const [sendersPhone, setSendersPhone] = useState('');
  const [cusName, setCusName] = useState(''); 
  const [phoneNumber, setPhoneNumber] = useState('');
  const [pickUpAd, setPickUpAd] = useState('');
  const [destAd, setDestAd] = useState('');
  const [pickUpRoute, setPickUpRoute] = useState('');
  const [destRoute, setDestRoute] = useState('');
  const [goodsQuantity, setGoodsQuantity] = useState('');
  const [loaders, setLoaders] = useState('');
  const [goodsWeight, setGoodsWeight] = useState('');
  const [pickUpDate, setPickUpDate] = useState('');
  const [pickUpTime, setPickUpTime] = useState('');
  const [reqTitle, setReqTitle] = useState('');
  const [titledDesc, setTitledDesc] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [goodsImage, setGoodsImage] = useState([]);
  const [goodsImagePrev, setGoodsImagePrev] = useState([]);
  const [finalFormData, setFinalFormData] = useState(null);
  const [dType, setDType] = useState("");
  const [expressDelivery, setExpressDelivery] = useState("");
  

  const [loadingEst, setLoadingEst] = useState(false);

  const setImgPreview = e => {
    
    const blob = URL.createObjectURL(e.target.files[0])
    setGoodsImagePrev(blob)

    setGoodsImage(e.target.files[0]);
  }

  

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      pickUpAddress: pickUpAd,
      destinationAddress: destAd,
      deliveryType: dType,
      isExpress: expressDelivery,
      requestTitle: reqTitle,
      goodsImage: [...goodsImage, JSON.stringify(compressedFile)],
      vehicleType: vehicleType,
      goodsWeight,
      goodsQuantity,
      goodsDescription: titledDesc,
      loaders,
      pickUpDate: pickUpDate,
      pickUpTime: pickUpTime,
      receiverDetails: {
            name: receiversName,
            phone: receiversPhone
         },
      senderDetails: {
        name: state?.name || sendersName,
        phone: state?.phoneNumber || sendersPhone
      },
      pickUpCoordinates: {
        "lat": coordinates.lat,
        "lng": coordinates.lng
      },
      destinationCoordinates: {
        "lat": coordinates2.lat,
        "lng": coordinates2.lng
      }
    }

    setFinalFormData(formData);

    setShowPreview(true)

    
  };

  const resolveTrip = (response) => {
    console.log(response, "this shows it works")
    setShowtripEst(true);
    setDistance(response?.rows[0]?.elements[0]?.distance?.text);
    setDistanceVal(response?.rows[0]?.elements[0]?.distance?.value);
    setDuration(response?.rows[0]?.elements[0]?.duration?.text);
}

//   const resolveTrip = async e => {
//     setShowtripEst(true)
//     setLoadingEst(true)
//     try {
//       const res = await axios.get(`https://maps.googleapis.com/maps/api/distancematrix/json?units=metric&origins=${coordinates.lat},${coordinates.lng}&destinations=${coordinates2.lat},${coordinates2.lng}&key=AIzaSyDqaACTEI6Is63g-O3c3QIXKgqGYNId-x0`)
//       console.log(res.data);
//       setDistance(res?.data?.rows[0]?.elements[0]?.distance?.text);
//       setDistanceVal(res?.data?.rows[0]?.elements[0]?.distance?.value);
//       setDuration(res?.data?.rows[0]?.elements[0]?.duration?.text);
//       setLoadingEst(false);
//       setResolved(true)

//     } catch (error) {
//         console.log(error)
//         toast.error('unable to retrieve trip estimate, try again later.', {
//           position: toast.POSITION.TOP_CENTER
//       })
//     }
     
// }

function SubmitButton() {
  if(receiversPhone && receiversName && pickUpAd && dType && expressDelivery && destAd && pickUpDate && pickUpTime && reqTitle && goodsImage && titledDesc) {
    return <button onClick={handleSubmit} type="submit">Create Request</button>
  } else {
    return <button disabled type="submit">Create Request</button>
  }
}

// function ResolveButton() {
//   if( receiversPhone && receiversName && pickUpRoute && pickUpAd && destAd && destRoute && pickUpDate && pickUpTime && reqTitle && goodsImage && titledDesc) {
//     return <p className='get-trip-est' onClick={resolveTrip}>get trip estimate</p>
//   } else if(distance && duration) {
//     return null
//   } else {
//     return null
//   }
// }

const lowerBoundValue = (data?.data?.data[0]?.pricePerKilometer.minPrice * (distanceVal/1000)).toFixed();

const upperBoundValue = (data?.data?.data[0]?.pricePerKilometer.maxPrice * data?.data?.data[0]?.wehaulConstantValue * (distanceVal/1000)).toFixed();

const previewTemp = [
  {
    label: "Receiver's Name",
    value: receiversName,
  },
  {
    label: "Receiver's Phone Number",
    value: receiversPhone,
  },
  {
    label: "Pick up Address",
    value: pickUpAd,
  },
  {
    label: "Destination Address",
    value: destAd,
  },
  {
    label: "Delivery Type",
    value: dType
  },
  {
    label: "Express Delivery",
    value: expressDelivery
  },
  {
    label: "Pickup Contact Name",
    value: state?.name || sendersName,
  },
  {
    label: "Pickup Contact Phone",
    value: state?.phoneNumber || sendersPhone,
  },
  {
    label: "Pickup Date",
    value: pickUpDate,
  },
  {
    label: "Pickup Time",
    value: pickUpTime,
  },
  {
    label: "Request title",
    value: reqTitle,
  },
  {
    label: "Vehicle Type",
    value: vehicleType
  },
  {
    label: 'Trip Estimate',
    value: `N${parseInt(lowerBoundValue).toLocaleString()} - N${parseInt(upperBoundValue).toLocaleString()}`
  }
];

const handleCompressedUpload = (e) => {
  const blob = URL.createObjectURL(e.target.files[0])
  setGoodsImagePrev(blob)

  setGoodsImage([...goodsImage, blob])
  const image = e.target.files[0];
  new Compressor(image, {
    quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
    success: (compressedResult) => {
      // setppUploadBtn(true);
      // compressedResult has the compressed file.
      // Use the compressed file to upload the images to your server.        
      setCompressedFile(compressedResult)
      console.log(compressedResult)
    },
  });
};



function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const makeOtherRequest = async (formData, history) => {
  setLoader(true)
  try {
    const res = await axios.post(`${baseUrl}/admin/request/make-request/customer/${state?.id}`, formData, config);
    toast.success(res.data.message, {
     position: toast.POSITION.TOP_CENTER
  })
  setLoader(false)
      console.log(res.data.message)
      window.location.href = '/dashboard/request'
  } catch(error) {
     if(error) {
        toast.error(error.response.data.message, {
           position: toast.POSITION.TOP_CENTER
        })
        setLoader(false)
     }
  }
}

return (
    <div className="other-vehicles-request">
      <form onSubmit={handleSubmit}>

      <div className="field-div">
          <label htmlFor="sender-name">Pickup Contact Name</label>
          <br />
          <input
            type="text"
            className="sender-name field"
            name="sender-name"
            value={state?.name || sendersName}
            onChange={(e) => setSendersName(e.target.value)}
            required
          ></input>
        </div>

        <div className="field-div">
          <label htmlFor="phone-number">Pickup Contact Phone Number</label>
          <br />
          <input
            className="field"
            type="tel"
            value={state?.phoneNumber || sendersPhone}
            name="phone-number"
            onChange={(e) => setSendersPhone(e.target.value)}
            required
          />
        </div>

      <AutoComplete2 setCoordinates={setCoordinates} setCoordinates2={setCoordinates2} setPickUpAd={setPickUpAd} setDestAd={setDestAd} vehicleData={data?.data?.data[3]} />

        {/* <div className="field-div">
          <label htmlFor="pickup-route">Pickup Route</label>
          <br />
          
          <select
            className="field select"
            type="text"
            value={pickUpRoute}
            name="pickup-route"
            onChange={(e) => setPickUpRoute(e.target.value.name)}
            required
          >
            <option>--select route--</option>
            {routes?.map((obj) => {
              return (
                <option value={obj} key={obj.id}>{obj.name}</option>
              )
            })}
          </select>
        </div>

        <div className="field-div">
          <label htmlFor="destination-route">Destination Route</label>
          <br />
          <select
            className="field select"
            type="text"
            value={destRoute}
            name="destination-route"
            onChange={(e) => setDestRoute(e.target.value)}
            required
          >
            <option>--select route--</option>
            {routes?.map(({_id: id, name}) => {
              return (
                <option value={name} key={id}>{name}</option>
              )
            })}
          </select>
        </div> */}

        

        <div className="field-div">
          <label htmlFor="vehicle-type">Vehicle Type</label>
          <br />
          <select 
          className="field select" 
          onChange={e => setVehicleType(e.target.value)} 
          required type="text" 
          name="vehicle-type">
            <option selected disabled>--select vehicle type--</option>
            {data?.data?.data.map(({pricePerKilometer, vehicleType, _id: id, wehaulCommission, wehaulConstant}) => {
              return (
                <option onClick={() => handleVehicle(pricePerKilometer)} key={id} value={vehicleType}>{capitalizeFirstLetter(vehicleType)}</option>
              )
            })}
          </select>
        </div>

        <div className="field-div">
          <label htmlFor="pickup-date">Pickup Date</label>
          <br />
          <input className="field" min={new Date().toISOString().split('T')[0]} onChange={e => setPickUpDate(e.target.value)} required type="date" name="pickup-date" />
        </div>

        <div className="field-div">
          <div className="t">
            <label htmlFor="pickup-time">Pickup-time</label>
            <br />
            <input className="field" onChange={e => setPickUpTime(e.target.value)} required type="time" name="pickup-time" />
          </div>
        </div>

        <div className="field-div">
          <label htmlFor="customer-name">Receiver's name</label>
          <br />
          <input
            type="text"
            className="customer-name field"
            name="customer-name"
            value={receiversName}
            onChange={(e) => setReceiversName(e.target.value)}
            required
          ></input>
        </div>

        <div className="field-div">
          <label htmlFor="phone-number">Receiver's Phone Number</label>
          <br />
          <input
            className="field"
            type="tel"
            value={receiversPhone}
            name="phone-number"
            onChange={(e) => setReceiversPhone(e.target.value)}
            required
          />
        </div>

        
       

        <div className="field-div">
          <label htmlFor="req-title">What are you picking up ?</label>
          <br />
          <input className="field" onChange={e => setReqTitle(e.target.value)} required type="text" name="req-title" />
        </div>

        <div className="field-div">
          <label htmlFor="req-title">Goods Quantity (Units)</label>
          <br />
          <input className="field" onChange={e => setGoodsQuantity(e.target.value)} required type="text" name="req-title" />
        </div>
        <div className="field-div">
          <label htmlFor="req-title">Goods Weight (kg)</label>
          <br />
          <input className="field" onChange={e => setGoodsWeight(e.target.value)} required type="text" name="req-title" />
        </div>
        


        <div className="field-div">
          <label htmlFor="goods-desc">Goods Description</label>
          <br />
          <textarea
            className="field"
            name="goods-desc"
            id=""
            cols="30"
            rows="10"
            onChange={e => setTitledDesc(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="field-div">
          <label htmlFor="req-title">Loaders</label>
          <br />
          <select className="field" onChange={e => setLoaders(e.target.value)} required name="req-title">
            <option selected disabled>Select Loaders</option>
            <option value={0}>0</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
          </select>
        </div>

        <div className="field-div">
          <label htmlFor="d-type">Delivery Type</label>
          <select className="field" onChange={e => setDType(e.target.value)} required name="d-type">
              <option selected disabled>Select delivery type</option>
              {dTypes.map((d, index) => {
                return (
                  <option key={index} value={d}>{capitalizeFirstLetter(d)}</option>
                )
              })}  
          </select>

          <div className="field-div">
            <label htmlFor="express">Express Delivery?</label>
            <div className="field">
            
            <input onChange={e => setExpressDelivery(e.target.value)} value="true" name="opt" type="radio" />
            <label style={{margin: "-5px 20px 0 7px", transform: "translateY(-5px)"}} htmlFor="yes">Yes</label>
            
            <input onChange={e => setExpressDelivery(e.target.value)} value="false" name="opt" type="radio" />
            <label style={{margin: "-5px 20px 0 7px", transform: "translateY(-5px)"}} htmlFor="yes">No</label>
          </div>
          </div>
          
        </div>



        <div className="img-upload field-div">
          <div className="selector">
            <div className="action">
              <input onChange={e => handleCompressedUpload(e)} type="file" id="file" />
              <i className="fas fa-upload"></i>
              <p>Upload goods image here</p>
            </div>
            <div  style={{height: '142px', borderRadius: '0 0 5px 5px', backgroundImage: `url(${goodsImagePrev})`, backgroundSize: 'cover'}} className="preview"></div>
          </div>
        </div>


        {showTripEst ? 
        <div className="field-div trip-estimate">
          <p className="titlex">Trip Estimate</p>
          {loadingEst ? <TLoader /> :
          <div className="deets">
            <p className="info"><i className="fas fa-map-marker-alt"></i> <span className="value">{pickUpAd}</span></p>
            <p className="info"><i className="fas fa-map-marker-alt"></i> <span className="value">{destAd}</span></p>
            <p className="info"><i className="fas fa-route"></i><span className="value">{distance}</span></p>
            <p className="info"><i className="fas fa-stopwatch"></i><span className="value">{duration}</span></p>
            <p className="range">N{parseInt(lowerBoundValue).toLocaleString()} - N{parseInt(upperBoundValue).toLocaleString()}</p>
          </div>
          }
        </div> :
        null
        }

        {/* <ResolveButton /> */}
        
        <DistanceMatrixService
          options={{
            destinations: [{ lat: coordinates2.lat, lng: coordinates2.lng }],
            origins: [{ lng: coordinates.lng, lat: coordinates.lat }],
            travelMode: "DRIVING",
          }}
          callback={resolveTrip}
        />

        <div className="field-div boton">
          <SubmitButton />
        </div>
      </form>
      {showPreview && (
        <RequestPreview
          icon="fas fa-biking"
          requestFunction={makeOtherRequest}
          vehicleType={vehicleType}
          formData={finalFormData}
          setShowPreview={setShowPreview}
          lowerBoundValue={lowerBoundValue}
          previewTemp={previewTemp}
          setPhoneNumber={setPhoneNumber}
          setPickUpAd={setPickUpAd}
          setDestAd={setDestAd}
          setPickUpRoute={setPickUpRoute}
          setDestRoute={setDestRoute}
          setPickUpDate={setPickUpDate}
          setPickUpTime={setPickUpTime}
          setReqTitle={setReqTitle}
          setTitledDesc={setTitledDesc}
          loading={loader}
        />
      )}
    </div>
  );
};

export default OtherVehiclesRequest;
