import { Box, Button, Divider, Flex, FormControl, FormLabel, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Spinner, Text, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react'
import Test from './test.svg'
import axios from 'axios';
import {baseUrl} from '../../config.json'
import { toast } from 'react-toastify';
import Logo from "./wehaulogo.png";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useHistory } from 'react-router-dom';
import RequestFormx from './requestFormx/requestFormx';
import { ExportJsonCsv } from 'react-export-json-csv';


const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
       }
 }

const RequestForm = () => {
    const [loading, setLoading] = useState(true)
    const [loadingx, setLoadingx] = useState(false)
    const [trigger, setTrigger] = useState("qwerty")
    const [loadinga, setLoadinga] = useState(false)
    const [requests, setRequests] = useState([])
    const [focus, setFocus] = useState("")
    const [base, setBase] = useState("")
    const [view, setView] = useState("amount")
    const [amount, setAmount] = useState("")
    const [fileLink, setFileLink] = useState("")

    const [quoteLink, setQuoteLink] = useState("")
    const [invoiceLink, setInvoiceLink] = useState("")
    const [receiptLink, setReceiptLink] = useState("")

    let newArray;

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
    const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure();

    const history = useHistory()

    useEffect(() => {
        axios.get(`${baseUrl}/request-forms`, config)
        // .then(res => console.log(res?.data?.data))
        .then(res => setRequests(res?.data?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error?.response?.data?.message))
    }, [loadingx, trigger])

    const startTrip = async(id) => {
        setFocus(id)
        setLoadingx(true)
        try {
            const {data} = await axios.put(`${baseUrl}/request-forms/${id}/start-trip`, {}, config)
            console.log(data)
            toast.success(data.message)
        } catch(error) {
            toast.error(error?.response?.data?.message)
        } finally {
            setLoadingx(false)
        }
    }

    const endTrip = async(id) => {
        setFocus(id)
        setLoadingx(true)
        try {
            const {data} = await axios.put(`${baseUrl}/request-forms/${id}/end-trip`, {}, config)
            console.log(data)
            toast.success(data.message)
        } catch(error) {
            toast.error(error?.response?.data?.message)
        } finally {
            setLoadingx(false)
        }
    }

    const handleModal = (i, base) => {
        setFocus(i)
        setBase(base)
        onOpen()
    }

    const handleView = (a, b, c) => {
        setQuoteLink(a)
        setInvoiceLink(b)
        setReceiptLink(c)
        onOpen3()
    }

    const inputRef = useRef(null);

    const printUploadDoc = async(e) => {
        e.preventDefault()
        setLoadinga(true)
        try {
            const res = await html2canvas(inputRef.current)
            const imgData = res.toDataURL("image/png");
            const pdf = new jsPDF();
            pdf.addImage(imgData, "JPEG", 0, 0);
            // pdf.save("wehaul-trip-receipt.pdf");

            const formData = new FormData()
            formData.append("file", imgData)
            formData.append("upload_preset", "jmrkiyu0")
            formData.append("cloud_name", "declutters")

            const {data} = await axios.post("https://api.cloudinary.com/v1_1/declutters/image/upload", formData)
            console.log(data?.url)
            setFileLink(data.url)
            setView("preview")

        } catch(error) {
            console.log(error)
        } finally {
            setLoadinga(false)
        }
    }

    const addAmount = async() => {
        setLoadinga(true)

        const datax = {
            base: base,
            amount: parseInt(amount),
            link: fileLink
        }
        try {
            const {data} = await axios.put(`${baseUrl}/request-forms/${focus?._id}/amount-link`, datax, config)
            console.log(data)
            setView("amount")
            toast.success(data.message)
            await onClose()
            if(base === "quote") {
                window.open("https://wa.me/" + focus?.sender?.phoneNumber + "?text=Thanks for your request to Wehaul Movers. Please click the link " + fileLink + " to see the quote for your request. Regards, The Wehaul Team", "_blank") || window.location.replace("https://wa.me/" + "2349160000687" + "?text=Hi, I purchased this item");
            } else if (base === "invoice") {
                window.open("https://wa.me/" + focus?.sender?.phoneNumber + "?text=Thanks for your using Wehaul Movers. Please click the link " + fileLink + " to see the invoice for your request. Regards, The Wehaul Team", "_blank") || window.location.replace("https://wa.me/" + "2349160000687" + "?text=Hi, I purchased this item");
            } else {
                window.open("https://wa.me/" + focus?.sender?.phoneNumber + "?text=Thanks for your request to Wehaul Movers. Please click the link " + fileLink + " to see the receipt for your request. Regards, The Wehaul Team", "_blank") || window.location.replace("https://wa.me/" + "2349160000687" + "?text=Hi, I purchased this item");
            }
            
        } catch(error) {
            toast.error(error?.response?.data?.message)
        } finally {
            setLoadinga(false)
        }
    }

    newArray = requests?.map((i) => ({
		reqType: i?.requestType,
        pickup: i?.pickupAddress + " " + (i?.pickupLocationType + ", " + i?.pickupFloorType),
        dropoff: i?.dropoffAddress + " " + (i?.dropoffLocationType + ", " + i?.dropoffFloorType),
        senderInfo: i?.sender?.name + ", " + i?.sender?.phoneNumber + ", " + i?.sender?.email,
        receiverInfo: i?.receiver?.name + ", " + i?.receiver?.phoneNumber + ", " + i?.receiver?.email,
        amount: i?.amount,
        dateTime: i?.pickupTime + ", " + i?.pickupDate?.slice(0,10),
        status: i?.status,
        quoteLink: i?.quoteLink,
        invoiceLink: i?.invoiceLink,
        receiptLink: i?.receiptLink,
        info: i?.additionalInfo

	}))

    const headers = [
        {
            key: 'reqType',
            name: 'REQUEST TYPE'
        },
        {
            key: 'pickup',
            name: 'PICKUP'
        },
        {
            key: 'dropoff',
            name: 'DROPOFF'
        },
        {
            key: 'senderInfo',
            name: 'SENDER'
        },
        {
            key: 'receiverInfo',
            name: 'RECEIVER'
        },
        {
            key: 'amount',
            name: 'AMOUNT'
        },
        {
            key: 'dateTime',
            name: 'DATE/TIME'
        },
        {
            key: 'status',
            name: 'STATUS'
        },
        {
            key: 'quoteLink',
            name: 'QUOTE'
        },
        {
            key: 'invoiceLink',
            name: 'INVOICE'
        },
        {
            key: 'receiptLink',
            name: 'RECEIPT'
        },
        {
            key: 'info',
            name: 'ADDITIONAL INFO'
        },
    ]

    

  return (
    <Box mt="1rem">
        <Flex justifyContent={"space-between"} alignItems={"center"} my="1.5rem">
            <Text fontWeight={"600"} fontSize={"1.3rem"}>Service Requests</Text>
            <Flex gap=".7rem">
                <ExportJsonCsv headers={headers} items={newArray}>
                    <Button textTransform="capitalize" bg={"#FFF"} border={"1px solid #013220"} _hover={{backgroundColor: "#013220", color: "#FFF"}} color="#013220" height="40px" padding=".6rem 1rem" fontSize=".85rem" borderRadius="4px">Export CSV</Button>
                </ExportJsonCsv>
                <Button onClick={() => onOpen2()} textTransform="capitalize" bg={"blue.700"} _hover={{backgroundColor: "#013220"}} color="#FFF" height="40px" padding=".6rem 1rem" fontSize=".85rem" borderRadius="4px">Request Service</Button>
            </Flex>
            {/* <Select width={"120px"}>
                <option selected value="all">All</option>
                <option value="pending">Pending</option>
                <option value="started">Started</option>
                <option value="completed">Completed</option>
            </Select> */}
        </Flex>
        

        {loading ? 
        <Flex justifyContent={"center"} mt="4rem">
            <Spinner size='md' />
        </Flex>
        :
        requests?.map((i, index) => {
            return (
                <Box key={index} bg="#FFF" position={"relative"} borderRadius={"8px"} padding={".7rem"} overflow={"hidden"} mb="1rem" boxShadow={"rgba(0, 0, 0, 0.2) 0px 18px 50px -10px"}>
                <Flex alignItems={"center"} justifyContent={"space-between"} flexWrap="wrap">
                    {/* <Text position={"absolute"} top="0" right="0" fontSize={".7rem"} bg="#4d4d4d" color={"#FFF"} px=".1rem">{i.requestType}</Text>
                    <Text position={"absolute"} bottom="0" right="0" fontSize={".7rem"} bg={i?.status === "pending" ? "yellow.500" : i?.status === "started" ? "blue.400" : "green.400"} color={"#FFF"} py=".2rem" px=".2rem" textTransform={"capitalize"}>{i.status}</Text> */}
                    <Box>
                        <Text mb=".7rem" fontWeight="600">{i?.requestType}</Text>
                        <Flex>
                            <Box width={"1px"} bg="#000" transform={"translateX(5px)"}></Box>
                            <Box>
                                <Flex alignItems={"center"} mb="1.5rem">
                                    <Box height={"8px"} width={"8px"} bg="green" zIndex={3} mr=".5rem" border={"1px solid #000"} borderRadius={"50%"}></Box>
                                    <Box>
                                        <Text fontSize={".9rem"}>{i?.pickupAddress} ({i?.pickupLocationType + ", " + i?.pickupFloorType})</Text>
                                        <Divider my=".2rem" />
                                        <Text fontSize={".9rem"}>[{i?.sender?.name}]-[{i?.sender?.phoneNumber}]-[{i?.sender?.email}]</Text>
                                    </Box>
                                </Flex>
                                <Flex alignItems={"center"}>
                                    <Box height={"8px"} width={"8px"} bg="red" zIndex={3} mr=".5rem" border={"1px solid #000"} borderRadius={"50%"}></Box>
                                    <Box>
                                        <Text fontSize={".9rem"}>{i?.dropoffAddress} ({i?.dropoffLocationType + ", " + i?.dropoffFloorType})</Text>
                                        <Divider my=".2rem" />
                                        <Text fontSize={".9rem"}>[{i?.receiver?.name}]-[{i?.receiver?.phoneNumber}]-[{i?.receiver?.email}]</Text>
                                    </Box>
                                </Flex>
                            </Box>
                        </Flex>
                        
                    </Box>
                    <Text fontWeight="500">{i?.amount ? "₦" + i?.amount?.toLocaleString() : "Amount Not Added"}</Text>
                    <Flex flexDirection={"column"} gap=".5rem">
                        <Button onClick={() => handleView(i?.quoteLink, i?.invoiceLink, i?.receiptLink)} bg={"blue.700"} border={"1px solid"} _hover={{backgroundColor: "#013220", color: "#FFF"}} color="#FFF" height="30px" padding=".4rem .7rem" fontSize=".75rem" borderRadius="4px">View Issued Docs</Button>
                        <Button onClick={() => handleModal(i, "quote")} bg="#FFF" border={"1px solid"} _hover={{backgroundColor: "#013220", color: "#FFF"}} color="blue.600" height="30px" padding=".4rem .7rem" fontSize=".75rem" borderRadius="4px">Generate Quote</Button>
                        <Button onClick={() => handleModal(i, "invoice")} bg="#FFF" border={"1px solid"} _hover={{backgroundColor: "#013220", color: "#FFF"}} color="blue.600" height="30px" padding=".4rem .7rem" fontSize=".75rem" borderRadius="4px">Generate Invoice</Button>
                        <Button onClick={() => handleModal(i, "receipt")} bg="#FFF" border={"1px solid"} _hover={{backgroundColor: "#013220", color: "#FFF"}} color="blue.600" height="30px" padding=".4rem .7rem" fontSize=".75rem" borderRadius="4px">Generate Receipt</Button>
                        <Button bg={i?.status === "pending" ? "yellow.500" : i?.status === "ongoing" ? "blue.500" : "green.400"} onClick={i?.status === "pending" ? () => startTrip(i?._id) : i?.status === "ongoing" ? () => endTrip(i?._id) : null} textTransform={"capitalize"} _hover={{backgroundColor: "#013220"}} color="#FFF" height="30px" padding=".4rem .7rem" fontSize=".75rem" borderRadius="4px">{focus === i?._id && loadingx ? <Spinner /> : i?.status === "pending" ? "Start Trip" : i?.status === "ongoing" ? "End Trip" : i?.status}</Button>
                    </Flex>
                </Flex>
                <Text mt="1.3rem" fontSize=".95rem">{i?.additionalInfo}</Text>
                </Box>
            )
        })}


        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent borderRadius={"8px"} overflow={"hidden"}>
            <ModalHeader fontSize={"1.1rem"}>{view === "amount" ? `Generate ${base}` : "Preview"}</ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody fontFamily={"Open sans"} p="1rem 2rem">
                {view === "amount" &&
                <>
                <form onSubmit={printUploadDoc}>
                    <FormControl mb="1.2rem">
                        <Input
                        type="text"
                        placeholder="Amount"
                        bg="#EAEAEB"
                        border="2px solid #CFD0D3"
                        _focus={{ boxShadow: "none" }}
                        fontSize={".9rem"}
                        height={"45px"}
                        borderRadius={"6px"}
                        _placeholder={{fontSize: ".85rem", color: "#4E5055"}}
                        value={amount} 
                        onChange={e => setAmount(e.target.value)}
                        />
                    </FormControl>
                    
                <Box ref={inputRef} mt="1rem" mx="auto" width={"80%"} border="1px solid lightgray" p="1rem" overflow={"hidden"}>
                    {base !== "receipt" &&
                    <>
                    <Flex alignItems={"center"} justifyContent={"space-between"}>
                        <Image width={"17%"} src={Logo} />
                        <Text fontSize={".7rem"} transform={"translateX(2rem)"} p=".4rem 1.7rem" borderRadius={"24px"} bg="yellow.300" fontWeight={"700"}>INVOICE</Text>
                    </Flex>
                    <Flex mt="1.3rem" alignItems={"baseline"} justifyContent={"space-between"}>
                        <Box>
                            <Text fontSize={".65rem"} fontWeight={"700"}>BILL TO:</Text>
                            <Text textTransform={"capitalize"} fontSize={".65rem"}>{focus?.sender?.name}</Text>
                        </Box>
                        <Box>
                            <Text fontSize={".65rem"}><strong>Invoice No.: </strong> #{focus?._id?.slice(0, 5)}</Text>
                            <Text fontSize={".65rem"}><strong>Date: </strong> {focus?.createdAt?.slice(0,10)}</Text>
                            <Text fontSize={".65rem"}><strong>Due Date:</strong> {focus?.pickupDate?.slice(0,10)}</Text>
                        </Box>
                    </Flex>
                    <Flex borderBlock={"3px solid black"} mt="2rem" pb=".5rem" alignItems={"center"} justifyContent={"space-between"}>
                        <Text flex="4" fontWeight={"600"} fontSize={".65rem"}>ITEM DESCRIPTION</Text>
                        <Text flex="1" fontWeight={"600"} fontSize={".65rem"}>PRICE</Text>
                        <Text flex="1" fontWeight={"600"} fontSize={".65rem"}>QTY</Text>
                        <Text flex="1" fontWeight={"600"} fontSize={".65rem"}>TOTAL</Text>
                    </Flex>
                    <Flex pb="1rem" borderBottom={"3px solid black"} mt=".5rem" alignItems={"baseline"} justifyContent={"space-between"}>
                        <Text flex="4" fontWeight={"600"} fontSize={".65rem"}>Pickup & Delivery from {focus?.pickupAddress} to {focus?.dropoffAddress}</Text>
                        <Text flex="1" fontWeight={"600"} fontSize={".65rem"}>₦{amount?.toLocaleString()}</Text>
                        <Text flex="1" fontWeight={"600"} fontSize={".65rem"} textAlign={"center"}>1</Text>
                        <Text flex="1" fontWeight={"600"} fontSize={".65rem"}>₦{amount?.toLocaleString()}</Text>
                    </Flex>
                    <Flex justifyContent={"flex-end"} mt=".3rem">
                        <Text fontWeight={"600"} fontSize={".65rem"}>Sub-Total: ₦{amount?.toLocaleString()}</Text>
                    </Flex>
                    <Box mt="2rem">
                        <Text fontWeight={"600"} fontSize={".65rem"}>Payment Method: Bank Transfer</Text>
                        <Text fontWeight={"600"} fontSize={".65rem"}>1016288455</Text>
                        <Text fontWeight={"600"} fontSize={".65rem"}>Zenith Bank</Text>
                        <Text fontWeight={"600"} fontSize={".65rem"}>Wehaul Logistics Soulutions</Text>
                    </Box>
                    </>}
                    {base === "receipt" &&
                    <>
                    <Flex alignItems={"center"} justifyContent={"space-between"}>
                        <Image width={"17%"} src={Logo} />
                        <Text fontSize={".85rem"} fontWeight={"700"}>RECEIPT</Text>
                    </Flex>
                    <Flex mt="1.6rem" alignItems={"baseline"} justifyContent={"space-between"}>
                        
                        <Box fontSize={".65rem"}>
                            <strong>Bill From</strong>
                            <Text textTransform={"capitalize"} fontSize={".65rem"}>WeHaul247 Logistics</Text>
                            <Text textTransform={"capitalize"} fontSize={".65rem"}>2 Connal Rd, Yaba, Lagos</Text>
                            <Text textTransform={"capitalize"} fontSize={".65rem"}>hello@wehaul247.com</Text>
                            <Text textTransform={"capitalize"} fontSize={".65rem"}>wehaul247.com</Text>
                        </Box>
                        <Box>
                            <Text fontSize={".65rem"}><strong>Receipt No.: </strong> #{focus?._id?.slice(0, 5)}</Text>
                            <Text fontSize={".65rem"}><strong>Receipt Date: </strong> {new Date().toISOString().slice(0, 10)}</Text>
                            <Text fontSize={".65rem"}><strong>Payment Date:</strong> {focus?.updateAt?.slice(0,10)}</Text>
                        </Box>
                    </Flex>

                    <Box mt="1.5rem">
                        <Text fontSize={".65rem"} fontWeight={"700"}>BILL TO:</Text>
                        <Text textTransform={"capitalize"} fontSize={".65rem"}>{focus?.sender?.name}</Text>
                    </Box>
                    
                    <Flex borderBlock={"3px solid goldenrod"} mt="2rem" pb=".5rem" alignItems={"center"} justifyContent={"space-between"}>
                        <Text flex="4" fontWeight={"600"} fontSize={".65rem"}>ITEM DESCRIPTION</Text>
                        <Text flex="1" fontWeight={"600"} fontSize={".65rem"}>PRICE</Text>
                        <Text flex="1" fontWeight={"600"} fontSize={".65rem"}>QTY</Text>
                        <Text flex="1" fontWeight={"600"} fontSize={".65rem"}>TOTAL</Text>
                    </Flex>
                    <Flex pb="1rem" borderBottom={"3px solid goldenrod"} mt=".5rem" alignItems={"baseline"} justifyContent={"space-between"}>
                        <Text flex="4" fontWeight={"600"} fontSize={".65rem"}>Pickup & Delivery from {focus?.pickupAddress} to {focus?.dropoffAddress}</Text>
                        <Text flex="1" fontWeight={"600"} fontSize={".65rem"}>₦{amount?.toLocaleString()}</Text>
                        <Text flex="1" fontWeight={"600"} fontSize={".65rem"} textAlign={"center"}>1</Text>
                        <Text flex="1" fontWeight={"600"} fontSize={".65rem"}>₦{amount?.toLocaleString()}</Text>
                    </Flex>
                    <Flex justifyContent={"flex-end"} mt=".3rem" mb="2rem">
                        <Text fontWeight={"600"} fontSize={".65rem"}>Total Paid: ₦{amount?.toLocaleString()}</Text>
                    </Flex>
                    </>}
                </Box>
                <br />
                <Flex justifyContent={"center"}>
                    <Button type='submit' bg={"blue.500"} _hover={{backgroundColor: "#013220"}} color="#FFF" height="30px" padding=".4rem .7rem" fontSize=".75rem" borderRadius="4px">{loadinga ? <Spinner /> : "Add Amount"}</Button>
                </Flex>
                </form>
                </>}
                {view === "preview" &&
                <>
                <Box mt="1rem" mx="auto" height={base === "receipt" ? "415px" : "395px"} width={"80%"} border="1px solid lightgray" p="1rem" backgroundImage={fileLink} backgroundSize={"cover"}>
                    
                </Box>
                <Flex justifyContent={"center"} mt="1.5rem">
                    <Button onClick={addAmount} textTransform="capitalize" bg={"blue.500"} _hover={{backgroundColor: "#013220"}} color="#FFF" height="30px" padding=".4rem .7rem" fontSize=".75rem" borderRadius="4px">{loadinga ? <Spinner /> : `send ${base}`}</Button>
                </Flex>
                <br />
                </>}
            </ModalBody>
            </ModalContent>
        </Modal>


        <Modal isOpen={isOpen3} onClose={onClose3} isCentered>
            <ModalOverlay />
            <ModalContent borderRadius={"8px"} overflow={"hidden"}>
            <ModalHeader fontSize={"1.1rem"}>Issued Docs</ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody fontFamily={"Open sans"} pb="1.5rem">
                <Text>Quote Link: <a style={{color: "blue", textDecoration: "underline"}} href={quoteLink} target="_blank" rel="noopener noreferrer">{quoteLink}</a></Text>
                <Divider my=".4rem" />
                <Text>Invoice Link: <a style={{color: "blue", textDecoration: "underline"}} href={invoiceLink} target="_blank" rel="noopener noreferrer">{invoiceLink}</a></Text>
                <Divider my=".4rem" />
                <Text>Receipt Link: <a style={{color: "blue", textDecoration: "underline"}} href={receiptLink} target="_blank" rel="noopener noreferrer">{receiptLink}</a></Text>
            </ModalBody>
            </ModalContent>
        </Modal>



        <Modal size='xxl' isOpen={isOpen2} onClose={onClose2} isCentered>
            <ModalOverlay />
            <ModalContent maxH={"95vh"} mx={[".5rem", "4rem"]} borderRadius={"8px"} overflowY={"scroll"}>
            <ModalCloseButton />
                <RequestFormx onClosex={onClose2} setTrigger={setTrigger} />
            </ModalContent>
        </Modal>
    </Box>
  )
}

export default RequestForm;