import './sms.scss';
import { useState } from 'react';
import {baseUrl} from '../../config.json';
import axios from 'axios';
import { toast } from 'react-toastify';
import ButtonLoader from '../../components/buttonLoader/buttonLoader';
import { getAllVehicles } from '../../services/vehicleServices';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Textarea } from '@chakra-ui/react';

const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
       }
 }

const Sms = () => {

    const currentUser = useSelector(({userData}) => userData.currentUser)
    console.log(currentUser?.data)
    const { userClass } = currentUser?.data


    const [loading, setLoading] = useState(false);
    const [recipient, setRecipient] = useState("");
    const [dv, setDv] = useState("");
    const [message, setMessage] = useState("");

    const { data, error, isLoading } = useQuery('getAllVehicles', getAllVehicles);
    if(isLoading) {
      console.log('loading')
    } else if (data) {
      console.log('getAllVehicles', data.data)
    } else {
      console.log(error)
    }

    const datax = {
        message
    }

    const sendSms = async (e) => {
        e.preventDefault();
        setLoading(true)

        try {
            const res = await axios.post(`${baseUrl}/admin/sms/${recipient + dv}`, datax, config )
            console.log(res.data)
            toast.success(res.data.message)
            setLoading(false)
        } catch (error) {
            console.log(error?.response?.data?.message)
            toast.error(error?.response?.data?.message)
            setLoading(false)
        }
    }

    return (
        <div className="sms">
            {/* <p>{baseUrl + "/admin/sms/" + recipient + dv}</p> */}
            <p className="title">SMS BROADCAST</p>
            <form onSubmit={sendSms}>
                <div className="input">
                    <label>Send an SMS BROADCAST to:</label>
                    <select onChange={e => setRecipient(e.target.value)}>
                        <option selected disabled>Select</option>
                        <option value="customer">Customer</option>
                        <option value="driver?vehicleType=">Online Drivers</option>
                        <option value="wehauldriver?vehicleType=">Wehaul/Offline Drivers</option>
                        <option value="merchant">Merchant</option>
                        <option value="enterprise">Enterprise</option>
                        <option value="all">All Users</option>

                    </select>
                </div>
                {recipient.includes("driver?vehicleType=")  && <div className="input">
                    <label>Are you sending SMS to drivers of a certain vehicle type ?</label>
                    <select onChange={e => setDv(e.target.value)}>
                        <option selected disabled>Select</option>
                        {!isLoading &&
                        data?.data?.data.map(({vehicleType}, index) => {
                            return (
                                <option key={index} value={vehicleType}>{vehicleType}</option>
                            )
                        })}
                        <option value="">All Vehicle Types</option>
                    </select>
                </div>}
                <div className="input">
                    <label>Enter broadcast SMS</label><br />
                    <Textarea maxW={"600px"} onChange={e => setMessage(e.target.value)} className='inc' name="" id="" cols="35" rows="10"></Textarea>
                </div>
                {userClass === "superAdmin" && <button type="submit">{loading && <ButtonLoader />}Send Message <i className='fas fa-paper-plane'></i></button>}
            </form>
        </div>
    )
}

export default Sms;