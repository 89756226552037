import React, { useEffect } from 'react';
import './viewDriver.scss';
import { useQuery } from 'react-query';
// import { getSingleCustomer } from '../../../services/customerServices';
import { baseUrl } from '../../../config.json'
import axios from 'axios';
import RippleLoader from '../../../components/rippleLoader/rippleLoader';
import Loader from '../../../components/loader/loader';
import {Link} from 'react-router-dom';

class ViewDriver extends React.Component {
    
    state = {
        userId: this.props.userId,
        userData: {
            name: '',
            address: '',
            email: '',
            phoneNumber: ''
        },
        loading: true
    }

    
    componentDidMount() {
        const config = {
            headers: {
               "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
               }
         }
        axios.get(`${baseUrl}/admin/driver-details/${this.state.userId}`, config)
        .then(res => this.setState({userData: res.data.data, loading: false}))
        .catch(err => console.log(err))
    }
    
    render() {
        const { setCustomerView, userId } = this.props
        const { name, address, email, phoneNumber, profilePicture, tripsCompleted, allTrips, vehicleInformationAndLicense } = this.state.userData
        // console.log(this.props)
        return ( 
            <>
            {this.state.loading ? <RippleLoader /> : 
                <div className="view-customer">
                <div className="wrapper">
                    <div className="view-modal">
                        <div className="head">
                            <i id='back' onClick={() => setCustomerView(false)} className='fas fa-arrow-left'></i>
                            {/* <i id='settings' className='fas fa-times'></i> */}
                        </div>
                        <div className="profile">
                            <div className="content">
                                <div className="img">
                                    <div className="img-cont" 
                                      style={{ backgroundImage: `url(${profilePicture})`, backgroundSize: "cover"}}
                                      >
                                    </div>
                                    {/* <i className='fas fa-dot'></i> */}
                                </div>
                                <p className="name">{name}</p>
                                <p className="mail">{email}</p>
                                <p className="num">{phoneNumber}</p>
                                <p className="numx">{vehicleInformationAndLicense?.vehicleType}</p>
                            </div>
    
                        </div>
                        <div className="trip-info">
                               <div className="contx">
                                   <div className="total">
                                       <p>Trips Requested</p>
                                       <p className="val">{allTrips}</p>
                                   </div>
                                   <div className="completed">
                                       <p>Trips Completed</p>
                                       <p className="val">{tripsCompleted}</p>
                                   </div>
                               </div>
                        </div>
                        <div className="foot">
                              <Link to={{pathname: "/dashboard/view-driver", query: {id: userId}}} style={{textDecoration: "none"}}><button>Profile Details</button></Link>
                        </div>
                    </div>
                </div>
                
            </div> }
            </>
         );
    }
    
}
 
export default ViewDriver;