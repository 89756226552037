import { baseUrl } from '../config.json';
import axios from 'axios';
import { useSelector } from 'react-redux'; 

const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
       }
 }

export async function getPayOutRequests() {
    return axios.get(`${baseUrl}/admin/finance/retrieve-payout-requests`, config)
}
export async function getTripPayments() {
    return axios.get(`${baseUrl}/admin/finance/retrieve-trip-payments`, config)
}
export async function getWalletFunding() {
    return axios.get(`${baseUrl}/admin/finance/retrieve-wallet-funded`, config)
}
export async function getCancellations() {
    return axios.get(`${baseUrl}/admin/finance/retrieve-cancellation-fees`, config)
}