import React, { useState } from 'react';
import './addDriver.scss';
import DriverModal from './driver-modal/driverModal';
const AddDriver = () => {
   const [modalShown, setModalShown] = useState(false);
    return ( 
        <div className="add-customer">
           <p className='add-action' onClick={() => setModalShown(true)}><i className="fas fa-plus"></i>Add New Driver</p>
           {modalShown && <DriverModal setModalShown={setModalShown} />}
            
        </div>
     );
}
 
export default AddDriver;