import React, { useState } from 'react';
import './enterpriseModal.scss';
import { useQuery } from 'react-query';
import { addNewCustomer } from '../../../../services/customerServices'
import { useHistory } from 'react-router-dom';
import { baseUrl } from '../../../../config.json';
import axios from 'axios';
import { ToastContainer, toast, Zoom, Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SmallLoader from '../../../../components/smallLoader/smallLoader';

const EnterpriseModal = ({setModalShown}) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [bizName, setBizname] = useState('');
    const [bizPhone, setBizPhone] = useState('');
    const [cac, setCac] = useState('');
    const [loading, setLoading] = useState(false);

    const history = useHistory();


    const formData = {
        name,
        email,
        phoneNumber,
        address,
        businessInformation: {
            "businessName": bizName,
            "businessPhoneNumber": bizPhone,
            "CACRegNumber": cac
          }
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true)
        const config = {
            headers: {
               "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`,
               "Content-Type": "application/json"
               }
         }
            try {
                const { data } = await axios.post(`${baseUrl}/admin/add-enterprise`, formData, config);
                
                console.log(data.message);
                toast.success(data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                setLoading(false)
                window.location.reload()
                // setModalShown(false);
             } catch (error) {
                if (error) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    })
                    console.log(error.response.data.message)
                }
                setLoading(false)
             }
    }


    return (
        <div className="customer-modal">
            <div className="wrapper">
                <div className="cus-modal">
                    <div className="head">
                        <h2>Add New Enterprise</h2>
                        <i class="fas fa-times" onClick={() => setModalShown(false)}></i>
                    </div>
                        <form onSubmit={handleSubmit}>
                            <div className="field">
                              <label htmlFor="name">Full Name</label>
                              <input required onChange={e => setName(e.target.value)} name="name" type="text" />
                            </div>
                            <div className="field">
                                <label htmlFor="email">Email Address</label>
                                <input onChange={e => setEmail(e.target.value)} name="email" type="email" />
                            </div>
                            <div className="field">
                                <label htmlFor="phone">Phone Number</label>
                                <input minLength={11} maxLength={11} required onChange={e => setPhoneNumber(e.target.value)} name="phone" type="tel" />
                            </div>
                            <div className="field">
                                <label htmlFor="address">Address</label>
                                <input type="text" onChange={e => setAddress(e.target.value)} />
                            </div>
                            <div className="field">
                                <label htmlFor="address">Business Name</label>
                                <input type="text" onChange={e => setBizname(e.target.value)} />
                            </div>
                            <div className="field">
                                <label htmlFor="address">Business Phone Number</label>
                                <input minLength={11} maxLength={11} type="tel" onChange={e => setBizPhone(e.target.value)} />
                            </div>
                            <div className="field">
                                <label htmlFor="address">CAC Reg.</label>
                                <input type="text" onChange={e => setCac(e.target.value)} />
                            </div>
                            <button type='submit'>{loading && <SmallLoader />} Add Enterprise</button>
                        </form>
                    
                </div>
            </div>
        </div>
     );
}
 
export default EnterpriseModal;