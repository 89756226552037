import React, { useState, useEffect } from 'react';
import './availableDriversXX.scss';
import Illustration from '../available-drivers/illustration.svg';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { baseUrl } from '../../../config.json';
import TLoader from '../../tLoader/tloader';
import ButtonLoader from '../../buttonLoader/buttonLoader';
import { useQuery } from 'react-query';
import { availableDrivers } from '../../../services/requestServices';
import DeleteModal from '../../deleteModal/deleteModal';
import NewModal from '../../newModal/newModal';

const AvailableDriversXX = (props) => {

    // if(window.location.reload()) {
    //     window.location.href = '/dashboard/request'
    // }

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [drivers, setDrivers] = useState([]);
    const [showConfirm, setConfirm] = useState(false);

    const [uc, setUc] = useState("");
    const [id, setId] = useState("");
    const [price, setPrice] = useState("");

    console.log(props)
    // const AvailableDrivers = props.location.state.requestData.qualifiedDrivers
    const requestId = props.location.state.reqId
    const userClass = props.location.state.userClass
    const userId = props.location.state.userId

    // const {data, isLoading, error} = useQuery('get top 101 drivers', availableDrivers)
    // if(isLoading) {

    // } else if (data) {
    //     console.log('working, ')
    // }

   

    const config = {
        headers: {
           "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
           }
     }

    // useEffect(() => {
    //    axios.get(`${baseUrl}/customer/request/${requestId}/top-10-drivers`, config)
    //         .then(res => console.log('helooo there', res.data))
    //         .then(res => setDrivers(res.data.data.qualifiedDrivers))
    //         .catch(err => console.log(err))
    // }, [])

    useEffect(() => {
        (async () => {
          const { data } = await axios.get(`${baseUrl}/admin/request/${requestId}/top-10-drivers/customer/${userId}`, config)
          console.log('hi', data);
          setDrivers(data?.data);
          setLoading(false);
        })();
      }, []);



    const selectDriver = async (userClass, id, price) => {
        console.log('bingo', userClass, id, price);
        setLoading2(true);

        const formdata = {
            amount: price,
            userClass
        }
    
        try {
          const { data } = await axios.post(`${baseUrl}/customer/request/${requestId}/select-driver/${id}/wallet`, formdata, config)
          console.log(data)
          toast.success(data.message, {
            position: toast.POSITION.TOP_CENTER
          })
          setLoading2(false)
          window.location.href = '/dashboard/trips'
        } catch (error) {
          console.log(error?.response?.data?.message)
          setLoading2(false)
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_CENTER
          })
        }
    }

    const handleAssign = async (userClass, id, price) => {
        await setUc(userClass)
        await setId(id)
        await setPrice(price)
        setConfirm(true)
    }

    return ( 
        <div className="available-drivers">
            {loading && <TLoader />}
            <div className="top-sec">
                <div className="leftx">
                    <Link to='/dashboard/request'><i className="fas fa-arrow-left"></i></Link>
                    <p className="title">Available Drivers</p>
                </div>
                <div className="rightx">
                    <img src={Illustration} alt="illustration" />
                </div>
            </div>
            {loading ? <ButtonLoader /> :
            drivers.length === 0 ? <><p style={{padding: "15px", borderRadius: "8px", marginTop: "20px", backgroundColor: "rgba(253, 0, 0, 0.2)", textAlign: "center", color: "#113E82", fontSize: "15px", fontWeight: "500"}}>Drivers who have responded with price will appear here and will be updated as responses keep coming in.</p><p style={{marginTop: "40px", textAlign: "center", fontSize: "14px", fontWeight: "500"}}>Can't get any drivers? click <span style={{color: "blue"}} onClick={() => window.location.pathname="/dashboard/ticket"}>here</span> to contact support</p></> :
            <div className="drivers">
                {drivers?.qualifiedDrivers?.map(({ price, name, userId: id, avgRating, userClass, profilePicture }, index) => {
                    // console.log('hvhshdshd', driver, drivers)
                    return (
                        <div className="item" key={index}>
                            <div className="item-left">
                                <div className="driver-img" style={{backgroundImage: `url(${profilePicture})`}}></div>
                                <div className="name-rating-vt">
                                    <p className="name">{name}</p>
                                    {/* <p>{driver?.price}</p> */}
                                    {avgRating === 1 && <span className='rating'><i className="fas fa-star"></i>{avgRating.toFixed(1)}</span>}
                                    {avgRating === 2 && <span className='rating'><i className="fas fa-star"></i><i className="fas fa-star"></i>{avgRating.toFixed(1)}</span>}
                                    {avgRating === 3 && <span className='rating'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>{avgRating.toFixed(1)}</span>}
                                    {avgRating === 4 && <span className='rating'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>{avgRating.toFixed(1)}</span>}
                                    {avgRating === 5 && <span className='rating'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>{avgRating.toFixed(1)}</span>}
                                    <span className='vt'>Average rating: {avgRating}</span>
                                </div>
                                <p className="price">N{price.toLocaleString()}</p>
                            </div>
                            <div className="item-right">
                                <button onClick={() => handleAssign(userClass, id, price)}><i className="fas fa-user-check"></i>Assign</button>
                                
                            </div>
                        </div>
                    )
                })}
            </div>}
            {/* {showConfirm && <NewModal />} */}
            {showConfirm && <NewModal setShowDeleteModal={setConfirm} type="assign" reqId={requestId} userClass={uc} driverId={id} loading={loading2} text="ASSIGN" depositAmount={price} requestFunction={() => selectDriver(uc, id, price)} customerId={drivers?.customerId} />}
        </div>
        
     );
}
 
export default AvailableDriversXX;