import React, {useState} from "react";
import Overview from "../dashboard-components/overview/overview";
import "./liveTracking.scss";
import { withGoogleMap, GoogleMap, Marker, DirectionsRenderer } from "react-google-maps";
import { useEffect } from "react";
import axios from "axios";
import {baseUrl} from "../../config.json";

const config = {
  headers: {
     "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
     }
}

const LiveTracking = () => {
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
      axios.get(`${baseUrl}/tracking/admin/location`, config)
    //   .then(res => console.log("trackings found", res))
      .then(res => setLocations(res?.data?.data))
      .then(res => setLoading(false))
      .catch(err => console.log(err))
    }, [])


  const MyMapComponent = withGoogleMap((props) => (
    <GoogleMap defaultZoom={20} defaultCenter={{ lat:locations[0]?.trackingDetails?.location[1], lng: locations[0]?.trackingDetails?.location[0] }}>
      {props.isMarkerShown && (
        loading ? <p></p> :
        locations.map(({trackingDetails, acceptedBy}, index) => {
          return (
            <Marker key={index} label={acceptedBy?.name} position={{ lat:trackingDetails?.location[1], lng: trackingDetails?.location[0] }} />
          )
        })
      )}
    </GoogleMap>
  ));

        // const DirectionsService = new google.maps.DirectionsService();

        // DirectionsService.route({
        //     origin: new google.maps.LatLng(41.8507300, -87.6512600),
        //     destination: new google.maps.LatLng(41.8525800, -87.6514100),
        //     travelMode: new google.maps.TravelMode.DRIVING,
        //   }, (result, status) => {
        //     if (status === google.maps.DirectionsStatus.OK) {
        //       this.setState({
        //         directions: result,
        //       });
        //     } else {
        //       console.error(`error fetching directions ${result}`);
        //     }
        //   });

  return (
    <div className="live-tracking">
      <Overview />

      <div className="top-head">
        <i className="fas fa-home"></i>
        <p className="top-head-left">Live Tracking</p>
      </div>
      <br />

      <div className="map-area">
          <MyMapComponent isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `500px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          />
      </div>
    </div>
  );
};

export default LiveTracking;
