import {baseUrl} from '../config.json';
import axios from 'axios';

const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
       }
 }

export async function getAllVehicles() {
    return axios.get(`${baseUrl}/admin/retrieve-vehicles`, config)
}