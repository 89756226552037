import './referrals.scss';
import { useState, useEffect } from 'react';
import axios from 'axios';
import {baseUrl} from '../../config.json';
import LineLoader from '../../components/lineLoader/lineLoader';
import { ExportJsonCsv } from 'react-export-json-csv';
import {
    ChakraProvider,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
  } from "@chakra-ui/react";


const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
       }
 }

const Referrals = () => {
    const [referrals, setReferrals] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchValue, setSearchValue] = useState("")

    useEffect(() => {
        const {data} = axios.get(`${baseUrl}/admin/referrals/all-referrals`, config)
        // .then(res => console.log(res.data.data))
        .then(res => setReferrals(res.data.data))
        .then(res => setLoading(false))
        .catch(err => console.log(err))
    }, [])

   

    const headers = [
        {
          key: 'userId',
          name: 'User ID',
        },
        {
          key: 'username',
          name: 'Name',
        },
        {
          key: 'referralCode',
          name: 'Referral Code',
        },
        {
            key: 'referralCount',
            name: 'Referral Count'
        }
      ]

      const headers2 = [
          {
              key: "name",
              name: "Reffered Names"
          },
          {
              key: "email",
              name: "Reffered Emails"
          },
      ]
      

    return (
        <div className="referrals">
            <div className="top">
                <div className="left">
                    <p className="title">Referrals ({referrals.length})</p>
                    <div className="input">
                        <i className="fas fa-search"></i>
                        <input onChange={e => setSearchValue(e.target.value.toUpperCase())} placeholder="Search by referral code" type="text" />
                    </div>
                </div>
                <div className="right">
                    <ExportJsonCsv headers={headers} items={referrals}>Export</ExportJsonCsv>
                </div>
            </div>

            <TableContainer>
            <Table variant='simple'>
                <thead>
                <Tr>
                    <th>User ID</th>
                    <th>Name</th>
                    <th>Referral Code</th>
                    <th>Referral Count</th>
                </Tr>
                </thead>
                <Tbody>
                {loading ? <LineLoader /> :
                    
                referrals.filter(i => i.referralCode.toUpperCase().includes(searchValue)).map(({userId, referralCode, username, person_referred, referralCount}) => {
                        return (
                           <tr className='content-row' key={userId}>
                               <Td>{userId}</Td>
                               <Td>{username}</Td>
                               <Td>{referralCode.slice(0,15)}</Td>
                               <Td>{referralCount}</Td>
                               <Td><ExportJsonCsv headers={headers2} items={person_referred}>Export</ExportJsonCsv></Td>
                           </tr>
                        )
                    })}
                </Tbody>
            </Table>
            </TableContainer>

            
        </div>
    )
}

export default Referrals;