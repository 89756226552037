import React, { useState } from "react";
import AddCustomer from "../request-components/add-customer/addCustomer";
import "./customers.scss";
import CustomerList from "../../static/customers";
import Overview from "../dashboard-components/overview/overview";
import ViewCustomer from "./view-customer/viewCustomer";
import { useQuery } from "react-query";
import { getAllCustomers } from "../../services/customerServices";
import Loader from "../loader/loader";
import axios from "axios";
import { baseUrl } from "../../config.json";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { ChakraProvider, Table, TableContainer, Tbody, Thead, Tr } from "@chakra-ui/react";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`,
  },
};

const Customers = () => {
  const [customerView, setCustomerView] = useState(false);
  const [userId, setUserId] = useState("");

  const currentUser = useSelector(({ userData }) => userData.currentUser);
  console.log(currentUser?.data);
  const { userClass } = currentUser?.data;

  const { data, error, isLoading } = useQuery(
    "getAllCustomers",
    getAllCustomers
  );
  if (isLoading) {
    console.log("loading");
  } else if (data) {
    console.log("getAllCustomers", data.data);
  } else {
    console.log(error);
  }

  const getCustomerDetails = (id) => {
    setCustomerView(true);
    console.log("hi", id);
    setUserId(id);
  };

  const deleteCustomer = (id) => {
    // setUserId(id);
    axios
      .delete(`${baseUrl}/admin/delete-customer/${id}`, config)
      .then((res) =>
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        })
      )
      .then((res) => window.location.reload())
      .catch((err) =>
        toast.error("An error occured, please try again", {
          position: toast.POSITION.TOP_CENTER,
        })
      );
  };

  const banCustomer = async (id) => {
    //   const id = e.target.id
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`,
      },
      // body: JSON.stringify({ title: 'React PUT Request Example' })
    };
    try {
      await fetch(`${baseUrl}/admin/ban-customer/${id}`, requestOptions)
        .then((res) => res.json())
        .then((res) =>
          toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER,
          })
        )
        .then((res) => window.location.reload());
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    //  const id = e.target.id
    //  await axios.put(`${baseUrl}/enterprise/driver/${id}/ban-driver`, config)
    //        .then(res => console.log(res.data))
    //        .catch(err => console.log(err))
    //  try {
    //     const res = await axios.put(`${baseUrl}/enterprise/driver/${id}/ban-driver`, config);
    //     toast.success(res.data.data.message, {
    //         position: toast.POSITION.TOP_CENTER
    //     })
    //  } catch (error) {
    //    toast.error(error?.response?.data?.message, {
    //        position: toast.POSITION.TOP_CENTER
    //    })
    //  }
  };

  // const banCustomer = (id) => {
  //     // setUserId(id)

  //     axios.put(`${baseUrl}/admin/ban-customer/${id}`, config)
  //     .then(res => toast.success(res.data.data, {
  //       position: toast.POSITION.TOP_CENTER
  //     }))
  //     .catch(err => toast.error("An error occured, please try again", {
  //       position: toast.POSITION.TOP_CENTER
  //     }))
  // }

  return (
    <ChakraProvider>
    <div className="customers">
      <Overview />
      {!isLoading && (
        <>
          <div className="heading">
            <div className="left-items">
              <div className="title">
                {/* <i
                  onClick={() => (window.location.href = "/dashboard")}
                  className="fas fa-home"
                ></i> */}
                <span>Customers</span>
              </div>
              <p className="request-count">{data?.data?.customers.length}</p>
              {/* <p className="export-btn">Export</p> */}
            </div>
            <div className="right-items">
              <AddCustomer />
            </div>
          </div>

          <TableContainer>
          <Table textAlign="left" variant="simple">
            <Thead>
              <Tr>
                <th>Name</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Earnings</th>
                <th>Action</th>
              </Tr>
            </Thead>
            <Tbody>
            {isLoading ? (
              <Loader />
            ) : (
              data?.data?.customers.map(
                (
                  {
                    name,
                    phoneNumber,
                    email,
                    walletBalance,
                    isBanned,
                    _id: id,
                  },
                  index
                ) => {
                  return (
                    <tr className="content-row" key={index}>
                      <td>
                        <p>
                          {name}
                          {isBanned && (
                            <i
                              style={{
                                cursor: "pointer",
                                fontSize: "1.1em",
                                color: "#F3402B",
                                margin: "0 5px",
                                backgroundColor: "#e0f5f5",
                                padding: "6px",
                                borderRadius: "2px",
                              }}
                              className="fas fa-ban"
                            ></i>
                          )}
                        </p>
                      </td>
                      <td>{phoneNumber}</td>
                      <td>{email}</td>
                      <td>N{walletBalance.toLocaleString()}</td>
                      <td>
                        <i
                          onClick={() => getCustomerDetails(id)}
                          style={{
                            cursor: "pointer",
                            fontSize: "1.1em",
                            color: "#113E82",
                            margin: "0 5px",
                            backgroundColor: "#e0f5f5",
                            padding: "6px",
                            borderRadius: "2px",
                          }}
                          className="fas fa-eye"
                        ></i>
                        {userClass === "superAdmin" && (
                          <i
                            onClick={() => deleteCustomer(id)}
                            style={{
                              cursor: "pointer",
                              fontSize: "1.1em",
                              color: "#113E82",
                              margin: "0 5px",
                              backgroundColor: "#e0f5f5",
                              padding: "6px",
                              borderRadius: "2px",
                            }}
                            className="fas fa-trash"
                          ></i>
                        )}
                        {!isBanned && (
                          <i
                            onClick={() => banCustomer(id)}
                            style={{
                              cursor: "pointer",
                              fontSize: "1.1em",
                              color: "#F3402B",
                              margin: "0 5px",
                              backgroundColor: "#e0f5f5",
                              padding: "6px",
                              borderRadius: "2px",
                            }}
                            className="fas fa-ban"
                          ></i>
                        )}
                        {isBanned && (
                          <i
                            onClick={() => banCustomer(id)}
                            style={{
                              cursor: "pointer",
                              fontSize: "1.1em",
                              color: "green",
                              margin: "0 5px",
                              backgroundColor: "#e0f5f5",
                              padding: "6px",
                              borderRadius: "2px",
                            }}
                            className="fas fa-lock-open"
                          ></i>
                        )}
                      </td>
                    </tr>
                  );
                }
              )
            )}
            </Tbody>
          </Table>
        </TableContainer>

          
          {customerView && (
            <ViewCustomer userId={userId} setCustomerView={setCustomerView} />
          )}
        </>
      )}
    </div>
    </ChakraProvider>
  );
};

export default Customers;
