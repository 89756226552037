import React from 'react';
import './cancellations.scss';
import Withdrawallist from '../../../static/withdrawals';
import RippleLoader from '../../rippleLoader/rippleLoader';
import EmptyState from '../../emptyState/emptyState';

const Cancellations = ({data}) => {
    return ( 
        <div className="withdrawals">
            {data?.length === 0 ? <EmptyState /> : 
            <div className="cover">
            <p className='top-title'>Recent Withdrawal</p>
            <table>
                <tr className='label-row'>
                    <th>Name</th>
                    <th>User Type</th>
                    <th>Origin</th>
                    <th>Destination</th>
                    <th>Cancellation Fee</th>
                    <th>Vehicle Type</th>
                    <th>Pickup Date</th>
                    <th>Amount</th>
                    <th>Action</th>
                </tr>
                    
                    {data?.map(({ amount, CancellationFee, pickUpDate, origin, destination, vehicleType, user, userType, userId, _id: id }, index) => {
                        return (
                            <tr className='content-row' key={id}>
                               <td>{user}</td>
                               <td>{userType}</td>
                               <td>{origin}</td>
                               <td>{destination}</td>
                               <td>{CancellationFee}</td>
                               <td>{vehicleType}</td>
                               <td>{pickUpDate}</td>
                               <td>N{amount.toLocaleString()}</td>
                               <td></td>
                            </tr>
                        )
                    })}
            </table>
            </div>
            }
            
            
        </div>
     );
}
 
export default Cancellations;