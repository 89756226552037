import React, { useState } from 'react';
import './addAdmin.scss';
import EnterpriseModal from './enterprise-modal/adminModal';
import DriverModal from './enterprise-modal/adminModal';
const AddEnterprise = () => {
   const [modalShown, setModalShown] = useState(false);
    return ( 
        <div className="add-customer">
           <p className='add-action' onClick={() => setModalShown(true)}><i className="fas fa-plus"></i>Add New</p>
           {modalShown && <EnterpriseModal setModalShown={setModalShown} />}
            
        </div>
     );
}
 
export default AddEnterprise;