// import { AtSignIcon, DeleteIcon } from "@chakra-ui/icons";

import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {devUrl} from '../../config.json';

const config = {
  headers: {
     "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
     }
}

const Country = () => {
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);

    const [name, setName] = useState("");
    const [code, setCode] = useState("https://res.cloudinary.com/giftguy/image/upload/v1656411425/Screenshot_2022-06-23_at_06.41.11_fgojhq.png");
    const [abbr, setAbbr] = useState("");
    const [wc, setWc] = useState("");
    const [refBonus, setRefBonus] = useState("");
    const [gateways, setGateways] = useState([]);

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        axios.get(`${devUrl}/admin/retrieve-countries`, config)
        .then(res => setCountries(res?.data?.data))
        .catch(err => console.log(err))
        .finally(setLoading(false))
    }, [loading2])

    const formData = {
      name,
      code,
      "abbreviation": abbr,
      "wehaulCommission": parseInt(wc),
      "maxReferralBonus": parseInt(refBonus),
      "paymentGateways": gateways
  }

    const createPunter = async(e) => {
        e.preventDefault();
        setLoading2(true)
        try {
            const res = await axios.post(`${devUrl}/admin/add-country`, formData, config)
            toast.success(res?.data?.message)
            onClose()
        } 
        catch(error) {
            console.log(error)
        }
        finally {
            setLoading2(false)
        }
    }

    // const toggleCountry = async(id) => {
    //     setLoading2(true)
    //     try {
    //         const res = await axios.put(`${testUrl}/admin/toggle-country-active-status/${id}`, config)
    //         toast.success(res?.data?.message)
    //         // onClose()
    //     } 
    //     catch(error) {
    //         console.log(error)
    //     }
    //     finally {
    //         setLoading2(false)
    //     }
    // }

    const toggleCountry = async (id) => {
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}` }
        
    };
    try {
        await fetch(`${devUrl}/admin/toggle-country-active-status/${id}`, requestOptions)
        .then(res => res.json())
        .then(res => 
              toast.info(res.message, {
                        position: toast.POSITION.TOP_CENTER
                    })
            )
    } catch (error) {
        toast.error(error?.response?.data?.message, {
                   position: toast.POSITION.TOP_CENTER
               })
    }
    
}

    const handleChecked = (e, val) => {
      if(e.target.checked) {
        setGateways([...gateways, val])
      }
      return;
    }



  return (
    <div style={{ padding: "2rem 1rem" }}>
      <Flex  direction="row" justifyContent="flex-end">
        <Button onClick={onOpen} fontSize="14px" color="#fff" bg="#113E82" width={200}>
          Add Country
        </Button>
      </Flex>

      {loading ? <Flex mt={50} direction='row' justifyContent='center'><Spinner /></Flex> :
      <TableContainer mt={5} boxShadow='0px 4px 25px rgba(0, 0, 0, 0.1)'>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Country Code</Th>
              <Th>Abbreviation</Th>
              <Th>WH Commision</Th>
              <Th>Max Referral Bonus</Th>
              <Th>Payment Gateways</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {countries?.map(({_id:id, wehaulCommission, maxReferralBonus, paymentGateways, isActive, name, code, abbreviation}) => {
                return (
                 <Tr key={id}>
                    <Td>{name}</Td>
                    <Td>{code}</Td>
                    <Td>{abbreviation}</Td>
                    <Td>{wehaulCommission}</Td>
                    <Td>{maxReferralBonus}</Td>
                    <Td>{paymentGateways.map((i, index) => (<Text key={index}>{i}</Text>))}</Td>
                    <Td>
                      {/* <Text mb={2} textAlign="center" bg="crimson" color="#fff" padding=".2rem .4rem" fontSize='12px' borderRadius="4px" onClick={() => deletePunter(id)} cursor='pointer'>Delete</Text> */}
                      <Text mb={2} textAlign="center" bg="#113E82" color="#fff" padding=".2rem .4rem" fontSize='12px' borderRadius="4px" cursor='pointer'>Edit</Text>
                      <Switch defaultChecked={isActive} onChange={() => toggleCountry(id)} />
                    </Td>
                </Tr>
            )})}
          </Tbody>
        </Table>
      </TableContainer>}


      <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Country</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={createPunter}>
            <ModalBody>
                        <FormControl mb={3}>
                            <Input onChange={e => setName(e.target.value)} _focus={{boxShadow: "none"}} size='lg' type="text" fontSize='md' placeholder='Name' color='gray.500' _placeholder={{color: "#718096"}} />
                        </FormControl>
                        <FormControl mb={3}>
                            <Input onChange={e => setCode(e.target.value)} _focus={{boxShadow: "none"}} size='lg' type="tel" fontSize='md' placeholder='Country Code' color='gray.500' _placeholder={{color: "#718096"}} />
                        </FormControl>
                        <FormControl mb={3}>
                                <Input onChange={e => setAbbr(e.target.value)} _focus={{boxShadow: "none"}} size='lg' type="text" fontSize='md' placeholder='Abbreviation' color='gray.500' _placeholder={{color: "#718096"}} />
                        </FormControl>
                        <FormControl mb={5}>
                            <Input onChange={e => setWc(e.target.value)} _focus={{boxShadow: "none"}} size='lg' type="tel" fontSize='md' placeholder='Wehaul Commission' color='gray.500' _placeholder={{color: "#718096"}} />
                        </FormControl>
                        <FormControl mb={5}>
                            <Input onChange={e => setRefBonus(e.target.value)} _focus={{boxShadow: "none"}} size='lg' type="tel" fontSize='md' placeholder='Referral Bonus' color='gray.500' _placeholder={{color: "#718096"}} />
                        </FormControl>
                        <Stack spacing={5} direction='row'>
                          <Checkbox colorScheme='blue' onChange={(e) => handleChecked(e,"paystack")}>
                            Paystack
                          </Checkbox>
                          <Checkbox colorScheme='blue' onChange={(e) => handleChecked(e,"stripe")}>
                            Stripe
                          </Checkbox>
                        </Stack>

            </ModalBody>

            <ModalFooter>
                <Button _focus={{boxShadow: 'none'}} variant='ghost' mr={3} onClick={onClose}>
                Close
                </Button>
                <Button type="submit" colorScheme='green'>{loading2 ? <Spinner /> : "Create"}</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Country;
