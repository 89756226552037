import React from 'react';
import './deposits.scss';
import Depositslist from '../../../static/deposits';
import EmptyState from '../../emptyState/emptyState';

const Deposits = ({data}) => {
    return ( 
        <div className="deposits">
            {data?.length === 0 ? <EmptyState /> : 
                <div className="cover">
                {/* <p className='top-title'>Recent Deposits</p> */}
                <table>
                    <tr className='label-row'>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>User Type</th>
                        <th>Balance</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Action</th>
                    </tr>
                        {data?.map(({ name, phoneNumber, email, date, userType, amount, walletBalance }, index) => {
                            return (
                                <tr className='content-row' key={index}>
                                   <td>{name}</td>
                                   <td>{phoneNumber}</td>
                                   <td>{email}</td>
                                   <td>{userType}</td>
                                   <td>N{walletBalance.toLocaleString()}</td>
                                   <td>N{amount.toLocaleString()}</td>
                                   <td>{date}</td>
                                   <td></td>
                                </tr>
                            )
                        })}
                </table>
                </div>
            }
        </div>
     );
}
 
export default Deposits;