import React from 'react';
import './trips.scss';
import { Link, BrowserRouter, Switch, Route, useRouteMatch } from 'react-router-dom';
import TripLanding from './trip-landing/tripLanding';
import TripView from './trip-view/tripView';

const Trips = () => {
    const { url } = useRouteMatch();
    return (
        <BrowserRouter>
        <div className="trip">
            <Switch>
                <Route exact path='/dashboard/trips' component={TripLanding} />
                <Route path='/dashboard/trips/tripId' component={TripView} />
            </Switch>
        </div>
        </BrowserRouter>
     );
}
 
export default Trips;