import React, { useEffect } from 'react';
import './viewOff.scss';
import Avatar from '.././../header/avatar.svg';
import { useQuery } from 'react-query';
// import { getSingleCustomer } from '../../../services/customerServices';
import { baseUrl } from '../../../config.json'
import axios from 'axios';
import RippleLoader from '../../rippleLoader/rippleLoader';
import Loader from '../../loader/loader';

class ViewOff extends React.Component {
    
    state = {
        userId: this.props.userId,
        userData: {
            name: '',
            address: '',
            email: '',
            phoneNumber: ''
        },
        loading: true
    }

    
    componentDidMount() {
        const config = {
            headers: {
               "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
               }
         }
        axios.get(`${baseUrl}/admin/customer-details/${this.state.userId}`, config)
        .then(res => this.setState({userData: res.data.data, loading: false}))
        .catch(err => console.log(err))
    }
    
    render() {
        const { setCustomerView, userId } = this.props
        const { name, address, email, phoneNumber } = this.state.userData
        // console.log(this.props)
        return ( 
            <>
            {this.state.loading ? <RippleLoader /> : 
                <div className="view-customer">
                <div className="wrapper">
                    <div className="view-modal">
                        <div className="head">
                            {/* <i id='back' onClick={() => setCustomerView(false)} className='fas fa-arrow-left'></i> */}
                            {/* <i id='settings' className='fas fa-times'></i> */}
                        </div>
                        <div className="profile">
                            <div className="content">
                                <div className="img">
                                    <div className="img-cont" 
                                    //   style={{ backgroundImage: `url(${userImage})`}}
                                      >
                                    </div>
                                    {/* <i className='fas fa-dot'></i> */}
                                </div>
                                <p className="name">jhsjhs</p>
                                <p className="mail">liam@dsfs.com</p>
                                <p className="num">039339399930</p>
                            </div>
    
                        </div>
                        <div className="trip-info">
                               <div className="contx">
                                   <div className="total">
                                       <p>Trip Requested</p>
                                       <p className="val">23</p>
                                   </div>
                                   <div className="completed">
                                       <p>Trip Completed</p>
                                       <p className="val">18</p>
                                   </div>
                               </div>
                        </div>
                        <div className="foot">
                              <button>go</button>
                        </div>
                    </div>
                </div>
                
            </div> }
            </>
         );
    }
    
}
 
export default ViewOff;