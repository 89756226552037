import axios from "axios";
import "./ticketView.scss";
import React, { useState, useEffect, useRef } from "react";
import { baseUrl } from "../../../config.json";
import LineLoader from "../../../components/lineLoader/lineLoader";
import { toast } from "react-toastify";
import BattonLoader from "../../../components/buttonLoader copy/buttonLoader";
import moment from "moment";
import { useSelector } from "react-redux";
import DeleteModalx from "../deleteModal/deleteModalx";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`,
  },
};

const TicketView = (props) => {
  const ticketId = props.match.params.ticketId;

  const currentUser = useSelector(({ userData }) => userData.currentUser);
  const { name } = currentUser.data;

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [response, setResponse] = useState("");
  const [trigger, setTrigger] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading3, setLoading3] = useState(false);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `${baseUrl}/admin/ticket/${ticketId}/ticket-details`,
        config
      );
      console.log(data.data);
      setTicket(data.data);
      setLoading(false);
      scrollToBottom();
    })();
  }, [trigger]);

  const body = {
    message: response,
  };

  const sendResponse = async (e) => {
    e.preventDefault();
    setLoading2(true);

    try {
      const { data } = await axios.post(
        `${baseUrl}/admin/ticket/${ticketId}/respond`,
        body,
        config
      );
      console.log(data);
    //   toast.success(data.message);
      setLoading2(false);
      setTrigger(response);
      setResponse("");
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading2(false);
    }
  };

//   const closeTicket = async (e) => {
//     e.preventDefault();
//     setLoading3(true);

//     try {
//       const { data } = await axios.put(
//         `${baseUrl}/customer/ticket/${ticketId}/respond`,
//         config
//       );
//       console.log(data);
//       toast.success(data.message);
//       setLoading3(false);
//       setResponse("");
//     } catch (error) {
//       toast.error(error?.response?.data?.message);
//       setLoading3(false);
//     }
//   };

  const closeTicket = async (e) => {
             setLoading3(true)
            const requestOptions = {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}` }
          };
          try {
              const res = await fetch(`${baseUrl}/admin/ticket/${ticketId}/close-ticket`, requestOptions)
              .then(res => res.json())
              .then(res => console.log(res))
              .then(res => toast.success('Ticket closed successfully'))
              .then(res => setLoading3(false))
              .then(res => window.location.href = '/dashboard/ticket')
          } catch (error) {
              toast.error(error?.response?.formdata?.message)
              setLoading3(false)
          }
      }


  return (
    <div className="ticket-view">
      <div className="chat-window">
        {loading ? (
          <LineLoader />
        ) : (
          <div className="content">
            <div className="item">
              <i className="fas fa-headset"></i>
              <p className="sub">{ticket.subject}</p>
              <div className="descx">
                <p className="desc">{ticket.description}</p>
              </div>
            </div>
            <div className="messages">
              {ticket.messages.map(({ sender, sentAt, text }, index) => {
                return (
                  <div
                    id={sender === name ? "mine" : "admin"}
                    className="message-item"
                    key={index}
                  >
                    <div className={sender === "Admin" ? "message ad-message" : "message"}>
                      <p className="txt">{text}</p>
                      <p className="time">{moment(sentAt).calendar()}</p>
                    </div>
                  </div>
                );
              })}
              <div className="close-div" ref={messagesEndRef}>
                  {ticket.status === 'closed' ? <p style={{color: "gray", fontSize: "13px", fontWeight: "500", marginBottom: "10px"}}>Ticket has been closed</p> : <p onClick={() => setShowDeleteModal(true)} className="close">Close Ticket</p>}
              </div>
            </div>
          </div>
        )}
      </div>
      <form onSubmit={sendResponse} className="chat-box">
        <div className="input">
          <input
            onChange={(e) => setResponse(e.target.value)}
            value={response}
            name=""
            id=""
            cols="30"
            rows="1"
            placeholder="Enter your message..."
          ></input>
          <button>
            {loading2 ? (
              <BattonLoader />
            ) : (
              <i className="fab fa-telegram-plane"></i>
            )}
          </button>
        </div>
      </form>
      {showDeleteModal && <DeleteModalx setShowDeleteModal={setShowDeleteModal} loading={loading3} text="Thanks for contacting support. Close ticket?" requestFunction={closeTicket} />}
    </div>
  );
};

export default TicketView;
