import React from 'react';
import './request.scss';
import { Link } from 'react-router-dom';
import AddCustomer from './add-customer/addCustomer';
import Overview from '../dashboard-components/overview/overview';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import MakeRequest from './makeRequest';
import RequestList from './requests-list/requestList';
import AvailableDrivers from './available-drivers/availableDrivers';
import AvailableDriversX from './available-driversX/availableDriversX';
import AvailableDriversXX from './available-driversXX/availableDriversXX';

const Request = () => {
    return ( 
        <BrowserRouter>
            <div className="request">
            <Overview />

            <Switch>
                <Route exact path='/dashboard/request' component={RequestList} />
                <Route path='/dashboard/request/make-request' component={MakeRequest} />
                <Route path='/dashboard/request/drivers' component={AvailableDrivers} />
                <Route path='/dashboard/request/bike-drivers' component={AvailableDriversX} />
                <Route path='/dashboard/request/other-drivers' component={AvailableDriversXX} />
            </Switch>

        </div>
        </BrowserRouter>
        
     );
}
 
export default Request;