import React, { useState } from "react";
import "./admins.scss";
import Overview from "../../components/dashboard-components/overview/overview";
import { useQuery } from "react-query";
import Loader from "../../components/loader/loader";
import axios from "axios";
import { baseUrl } from "../../config.json";
import { toast } from "react-toastify";
import AddDriver from "./add-enterprise/addAdmin";
import ViewEnterprise from "./view-enterprise/viewEnterprise";
import { getAllAdmins } from "../../services/enterpriseServices";
import AddEnterprise from "./add-enterprise/addAdmin";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  ChakraProvider,
} from "@chakra-ui/react";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`,
  },
};

const Admins = () => {
  const [driverView, setDriverView] = useState(false);
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);

  const { data, error, isLoading } = useQuery("getAllAdmins", getAllAdmins);
  if (isLoading) {
    console.log("loading");
  } else if (data) {
    console.log("getAllAdmins", data.data);
  } else {
    console.log(error);
  }

  const getDriverDetails = async (id) => {
    await setUserId(id);
    setDriverView(true);
    console.log("hi", id);
  };

  const deleteAdmin = (id) => {
    // setUserId(id);
    axios
      .delete(`${baseUrl}/admin/${id}/delete-admin`, config)
      .then((res) =>
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        })
      )
      .then((res) => window.location.reload())
      .catch((err) =>
        toast.error("An error occured, please try again", {
          position: toast.POSITION.TOP_CENTER,
        })
      );
  };

  const verify = async (id) => {
    setLoading(true);
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`,
      },
      // body: JSON.stringify({ title: 'React PUT Request Example' })
    };
    try {
      await fetch(`${baseUrl}/admin/verify-enterprise/${id}`, requestOptions)
        .then((res) => res.json())
        .then((res) =>
          toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER,
          })
        )
        .then((res) => setLoading(false))
        .then((res) => window.location.reload());
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const banDriver = async (id) => {
    //   const id = e.target.id
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`,
      },
      // body: JSON.stringify({ title: 'React PUT Request Example' })
    };
    try {
      await fetch(`${baseUrl}/admin/ban-enterprise/${id}`, requestOptions)
        .then((res) => res.json())
        .then((res) =>
          toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER,
          })
        )
        .then((res) => window.location.reload());
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    //  const id = e.target.id
    //  await axios.put(`${baseUrl}/enterprise/driver/${id}/ban-driver`, config)
    //        .then(res => console.log(res.data))
    //        .catch(err => console.log(err))
    //  try {
    //     const res = await axios.put(`${baseUrl}/enterprise/driver/${id}/ban-driver`, config);
    //     toast.success(res.data.data.message, {
    //         position: toast.POSITION.TOP_CENTER
    //     })
    //  } catch (error) {
    //    toast.error(error?.response?.data?.message, {
    //        position: toast.POSITION.TOP_CENTER
    //    })
    //  }
  };

  // const banCustomer = (id) => {
  //     // setUserId(id)

  //     axios.put(`${baseUrl}/admin/ban-customer/${id}`, config)
  //     .then(res => toast.success(res.data.data, {
  //       position: toast.POSITION.TOP_CENTER
  //     }))
  //     .catch(err => toast.error("An error occured, please try again", {
  //       position: toast.POSITION.TOP_CENTER
  //     }))
  // }

  return (
    <ChakraProvider>
      <div className="customers">
        <Overview />

        <div className="heading">
          <div className="left-items">
            <div className="title">
              {/* <i onClick={() => window.location.href = '/dashboard'} className="fas fa-home"></i> */}
              <span>Admins</span>
            </div>
            <p className="request-count">{data?.data?.data.length}</p>
            {/* <p className="export-btn">Export</p> */}
          </div>
          <div className="right-items">
            <AddEnterprise />
          </div>
        </div>

        <TableContainer>
          <Table textAlign="left" variant="simple">
            <Thead>
              <Tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Address</th>
                <th>Role</th>
                <th>Action</th>
              </Tr>
            </Thead>
            <Tbody>
            {isLoading ? (
            <Loader />
          ) : (
            data?.data?.data.map(
              (
                {
                  name,
                  phoneNumber,
                  profilePicture,
                  email,
                  isVerified,
                  address,
                  userClass,
                  _id: id,
                },
                index
              ) => {
                return (
                  <tr className="content-row" key={index}>
                    <td style={{textAlign: "left !important"}}>
                      <p>{name}</p>
                    </td>
                    <td>{phoneNumber.slice(0,7)}..</td>
                    <td>{email.slice(0,7)}..</td>
                    <td>{address.slice(0,7)}..</td>
                    <td>{userClass}</td>
                    <td className="flx">
                      <i
                        onClick={() => deleteAdmin(id)}
                        style={{
                          cursor: "pointer",
                          fontSize: "1.1em",
                          color: "#113E82",
                          margin: "0 5px",
                          backgroundColor: "#e0f5f5",
                          padding: "6px",
                          borderRadius: "2px",
                        }}
                        className="fas fa-trash"
                      ></i>
                    </td>
                  </tr>
                );
              }
            )
          )}
            </Tbody>
          </Table>
        </TableContainer>

        
        {driverView && (
          <ViewEnterprise userId={userId} setCustomerView={setDriverView} />
        )}
      </div>
    </ChakraProvider>
  );
};

export default Admins;
