import React, { useState } from 'react';
import './addMerchant.scss';
import MerchantModal from './merchant-modal/merchantModal';

const AddMerchant = () => {
   const [modalShown, setModalShown] = useState(false);
    return ( 
        <div className="add-customer">
           <p className='add-action' onClick={() => setModalShown(true)}><i className="fas fa-plus"></i>Add New Merchant</p>
           {modalShown && <MerchantModal setModalShown={setModalShown} />}
            
        </div>
     );
}
 
export default AddMerchant;