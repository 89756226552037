import { Box, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";

const PaginatedTable = ({rowTitles, tableBody}) => {
    return (
        <Box>
            <TableContainer>
                <Table fontSize={".9rem"} mt={4} borderRadius="8px" variant='simple' bg="#fff">
                    <Thead>
                    <Tr>
                        {rowTitles?.map((i, index) => {
                            return (
                                <Th key={index}>{i}</Th>
                            )
                        })}
                    </Tr>
                    </Thead>
                    <Tbody>
                        {tableBody}
                    </Tbody>
                    
                </Table>
            </TableContainer>

            {/* <Flex padding={"0 1rem"} mt={3} justifyContent={"space-between"} alignItems="center">
                <Text>Showing 1 - 20 of 100</Text>
                <Flex>
                <Text>&lt; 1 &gt;</Text>
                </Flex>
            </Flex> */}
        </Box>
    )
}

export default PaginatedTable;