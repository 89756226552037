import axios from 'axios';
import { useState, useEffect } from 'react';
import './profile.scss';
import {baseUrl} from "../../../config.json";
import AppLoader from '../../../components/appLoader/appLoader';

const config = {
  headers: {
     "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
     }
}

const Profilee = (props) => {
    const [deets, setDeets] = useState(null);
    const [loading, setLoading] = useState(true)

    console.log(props)
    const id = props.location.query.id

    useEffect(() => {
        axios.get(`${baseUrl}/admin/enterprise-details/${id}`, config)
        // .then(res => console(res?.data))
        .then(res => setDeets(res?.data?.data))
        .then(res => setLoading(false))
        .catch(err => console.log(err))
    }, [])

    return (
        <div className="profile">
            {loading ? <AppLoader /> :
            <>
            <div className="cover">
      <div className="top-head">
        <i className="fas fa-home"></i>
        <p className="top-head-left">Settings</p>
      </div>

      <p className="pg-title">Account Settings</p>
      <div className="img-deet-wrap" style={{backgroundColor: "fff"}}>
      <div className="img-deet">
        <div className="img" style={{position: "relative", backgroundImage: `url(${deets? deets?.profilePicture : null})`, backgroundSize: 'cover', backgroundPosition: "center"}}>
        {/* <input style={{
          backgroundColor: "red",
          width: "120px",
          height: "120px",
          borderRadius: "50%",
          position: "absolute",
          opacity: "0",
          zIndex: "2",
          cursor: "pointer"
        }} type="file" required /> */}
        {/* <i
        style={{backgroundColor: "#35aa78",
                borderRadius: "50%",
                padding: "8px",
                color: "#fff",
                zIndex: "1",
                fontSize: "1.3em",
                position: "absolute",
                top: "60%",
                right: "0",
                cursor: "pointer"
              }}
        className="far fa-edit"></i>
        */}
        </div>
        
        <div className="deet">
          <p className="name">{deets?.name}</p>
          <p className="account-number">{deets?.phoneNumber}</p>
          <p className="mail">{deets?.email}</p>
        </div>
      </div>
      {/* {ppUploadBtn && <button onClick={uploadImg}>{loadingx && <ButtonLoader />}Update profile image</button>} */}
      </div>
      

      <div className="form">
        <div className="personal-info">
          <div className="title">Personal Information</div>
          <form className="inputs">
            <div className="input">
              <label htmlFor="full-name">Full Name</label>
              <br />
              <i className="far fa-user"></i>
              <input id="full-name" value={deets?.name} type="text" />
            </div>
            <div className="input">
              <label htmlFor="phone-number">Phone Number</label>
              <br />
              <i className="fas fa-phone-alt"></i>
              <input id="phone-number" value={deets?.phoneNumber} type="tel" />
            </div>
            <div className="input">
              <label htmlFor="email">Email Address</label>
              <br />
              <i className="far fa-envelope"></i>
              <input id="email" value={deets?.email} type="email" />
            </div>
            <div className="input">
              <label htmlFor="address">Address</label>
              <br />
              <i className="fas fa-location-arrow"></i>
              <input id="address" value={deets?.address} type="text" />
            </div>
          </form>
          <br />
          <br />
          {/* <button onClick={handlePersonalSubmit} type="submit">{loading4 && <ButtonLoader />} Save</button> */}
          
        </div>

        <div className="business-info">
          <div className="title">Business Information</div>
          <form>
          <div className="all-inputs">
            <div className="left-inputs">
              <div className="input">
                <label htmlFor="plate-number">Business Name</label>
                <br />
                <input value={deets?.businessInformation?.businessName} required id="plate-number" type="text" />
              </div>

              <div className="input">
                <label htmlFor="vehicle-type">Business Phone Number</label>
                <br />
                <input value={deets?.businessInformation?.businessPhoneNumber} required id="vehicle-type" type="tel" />
              </div>

              <div className="input">
                <label htmlFor="cac-reg">CAC Registration Number</label>
                <input value={deets?.businessInformation?.CACRegNumber} required type="text" id="cac-reg" />
              </div>
            </div>

          </div>
          {/* <button type="submit">{loading && <ButtonLoader />} Save</button> */}
          </form>
          
        </div>

        {/* <div className="business-info">
          <div className="title">Drivers License</div>
          <form>
          <div className="all-inputs">
            <div className="left-inputs">
              <div className="input">
                <label htmlFor="plate-number">Driver's license number</label>
                <br />
                <input required value={deets?.driverLicenseInformation?.driverLicenseNumber} id="plate-number" type="text" />
              </div>

              <div className="input">
                <label htmlFor="vehicle-type">Issue Date</label>
                <br />
                <input required value={deets?.driverLicenseInformation?.issueDate} id="vehicle-type" type="date" />
              </div>

              <div className="input">
                <label htmlFor="cac-reg">Expiry Date</label>
                <input required value={deets?.driverLicenseInformation?.expiryDate} min={new Date().toISOString().split('T')[0]} type="date" id="cac-reg" />
              </div>
            </div>
            
            <div className="right-inputs">

              <div className="files">
              <div className="img-upload field-div">
                <div className="selector">
                  <div className="action">
                    <input type="file" className="mvdwn" id="file" />
                    <i className="fas fa-upload"></i>
                    <p>Upload Drivers License</p>
                  </div>
                  <div  style={{height: '142px', borderRadius: '0 0 5px 5px', backgroundImage: `url(${deets ? deets?.driverLicenseImage : null})`, backgroundSize: 'cover'}} className="preview"></div>
                </div>
              </div>

              </div>

            </div>
          </div>
          </form>
          
        </div> */}

        {/* <Documents /> */}
        

        <div className="business-info">
          <div className="title">Vehicle Information</div>
          <form>
          <div className="all-inputs">
            <div className="left-inputs">
              <div className="input">
              <label htmlFor="vehicle-type">Vehicle Types</label>
              <br />
              <select 
              className="field" 
            value={deets?.vehicleType}
              required type="text" 
              name="vehicle-type">
                {deets?.vehicles.map((i, index) => {
                  return (
                      <option value={i}>{i}</option>
                  )
                })}
                {/* {datax?.data?.data.filter(d => d.vehicleType !== "bike").map(({pricePerKilometer, vehicleType, _id: id, wehaulCommission, wehaulConstant}) => {
                  return (
                    <option key={id} value={vehicleType}>{capitalizeFirstLetter(vehicleType)}</option>
                  )
                })} */}
              </select>
            </div>
        </div>

              

          </div>
          {/* <button type="submit">{loading2 && <ButtonLoader />} Save</button> */}
          </form>
          
        </div>

        <div className="acct-details">
        <div className="title">Account Details</div>
          <form>
          <div className="inputs">
                <div className="input">
                  <label htmlFor="account-name">Account Name</label>
                  <br />
                  <i className="far fa-user"></i>
                  <input
                    id="account-name"
                    type="text"
                    required
                    value={deets?.accountInformation?.accountName}
                    // onChange={e => setAccountName(e.target.value)}
                  />
                </div>
                <div className="input">
                  <label htmlFor="account-number">Account Number</label>
                  <br />
                  <i className="fas fa-calculator"></i>
                  <input
                    id="account-number"
                    type="tel"
                    required
                    value={deets?.accountInformation?.accountNumber}
                    // onChange={e => setAccountNumber(e.target.value)}
                  />
                </div>
                <div className="input">
                  <label htmlFor="bank-name">Bank Name</label>
                  <br />
                  <i className="fas fa-university"></i>
                  <select 
                    style ={{marginTop: "10px",
                      background: "#F9FBFF",
                      border: "1px solid #40D192",
                      borderRadius: "6px",
                      padding: "14px 30px",
                      boxSizing: "border-box",
                      width: "100%",
                      outline: "none"}}
                    id="bank-name"
                    type="text"
                    required
                    value={deets?.accountInformation?.bankName}
                    // onChange={e => setBankName(e.target.value)}
                  >
                    <option>{deets?.accountInformation?.bankName}</option>
                    {/* {banksData.map(({name}, index) =>{
                      return (
                        <option key={index} value={name}>{name}</option>
                      )
                    })} */}
                  </select>
                </div>
                <div className="input">
                  <label htmlFor="account-type">Account Type</label>
                  <br />
                  <i className="fas fa-money-check-alt"></i>
                  <select
                  style ={{marginTop: "10px",
                  background: "#F9FBFF",
                  border: "1px solid #40D192",
                  borderRadius: "6px",
                  padding: "14px 30px",
                  boxSizing: "border-box",
                  width: "100%",
                  outline: "none"}}
                  name="account-type"
                  id="account-type"
                  required
                  value={deets?.accountInformation?.accountType}
                //   onChange={e => setAccountType(e.target.value)}
                  >
                    <option>{deets?.accountInformation?.accountType}</option>
                    {/* <option value="savings">Savings</option>
                    <option value="current">Current</option> */}
                  </select>
                </div>
              </div><br />
              {/* <button type="submit">{loading3 && <ButtonLoader />} Save</button> */}
          </form>
              
            </div>
      </div>
      </div>
          </>}
        </div>
    )
}

export default Profilee;