import {baseUrl} from '../config.json';
import axios from 'axios';

const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
       }
 }

export async function getAllCustomers() {
    return axios.get(`${baseUrl}/admin/retrieve-customers`, config)
}

export async function getAllOfflineDrivers() {
    return axios.get(`${baseUrl}/wehaul/driver/all`, config)
}

export async function getSingleCustomer(userId) {
    return axios.get(`${baseUrl}/merchant/view-customer-details/${userId}`, config)
}

export async function addNewCustomer(formdata) {
    try {
        const { data } = await axios.post(`${baseUrl}/merchant/add-customer`, config, formdata);
        console.log('doing', data)
     } catch (error) {
        console.log(error)
     }
}