import React, { useState } from "react";
import "./truckRequest.scss";
import { useSelector } from "react-redux";
import "rc-slider/assets/index.css";
import { useQuery } from "react-query";
import axios from "axios";
// import { makeTruckRequest } from "../../../services/requestServices";
import states from "../../../utils/states";
import RequestPreview from "../requestPreview/requestPreview";
import AutoComplete from "../../autoComplete/autocomplete";
import { getTrucks } from "../../../services/routeServices";
import Compressor from "compressorjs";
import TLoader from "../../tLoader/tloader";
import { DistanceMatrixService } from "@react-google-maps/api";
import { toast } from "react-toastify";
import {baseUrl} from '../../../config.json';
import AutoComplete2 from "../../autoComplete2/autocomplete2";

const config = {
  headers: {
     "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
     }
}

const dTypes = ["food", "fragile", "medical", "animal/pets", "documents", "other"]

const TruckRequest = () => {
  const [showPreview, setShowPreview] = useState(false);
  const [showTripEst, setShowtripEst] = useState(false);
  const [resolved, setResolved] = useState(false);
  const [compressedFile, setCompressedFile] = useState(null);
  const [truckType, setTruckType] = useState("");
  const [loader, setLoader] = useState(false);

  const { data, error, isLoading } = useQuery("getTrucks", getTrucks);
  if (isLoading) {
    console.log("loading all trucks");
  } else if (data) {
    console.log("gotten all trucks", data.data.data);
  } else {
    console.log(error);
  }

  const currentUser = useSelector(({ userData }) => userData.currentUser);
  console.log(currentUser.data);
  const { name } = currentUser.data;

  const [distance, setDistance] = useState("");
  const [distanceVal, setDistanceVal] = useState("");
  const [duration, setDuration] = useState("");

  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [coordinates2, setCoordinates2] = useState({
    lat: null,
    lng: null,
  });

  const [receiversName, setReceiversName] = useState("");
  const [receiversPhone, setReceiversPhone] = useState("");
  const [sendersName, setSendersName] = useState('');
  const [sendersPhone, setSendersPhone] = useState('');
  const [cusName, setCusName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pickUpAd, setPickUpAd] = useState("");
  const [destAd, setDestAd] = useState("");
  const [pickUpState, setPickUpState] = useState("");
  const [destState, setDestState] = useState("");
  const [pickUpDate, setPickUpDate] = useState("");
  const [pickUpTime, setPickUpTime] = useState("");
  const [reqTitle, setReqTitle] = useState("");
  const [goodsImage, setGoodsImage] = useState([]);
  const [goodsImagePrev, setGoodsImagePrev] = useState([]);
  const [titledDesc, setTitledDesc] = useState("");
  const [finalFormData, setFinalFormData] = useState(null);
  const [dType, setDType] = useState("");
  const [expressDelivery, setExpressDelivery] = useState("");

  const [loadingEst, setLoadingEst] = useState(false);

  const setImgPreview = (e) => {
    const blob = URL.createObjectURL(e.target.files[0]);
    setGoodsImagePrev(blob);

    setGoodsImage([...goodsImage, blob]);
  };

  const formData = {
    pickUpAddress: pickUpAd,
    destinationAddress: destAd,
    isExpress: expressDelivery,
    deliveryType: dType,
    requestTitle: reqTitle,
    pickUpState: pickUpState,
    destinationState: destState,
    vehicleType: truckType,
    pickUpDate: pickUpDate,
    pickUpTime: pickUpTime,
    goodsDescription: titledDesc,
    receiverDetails: {
      name: receiversName,
      phone: receiversPhone,
    },
    senderDetails: {
      name: sendersName,
      phone: sendersPhone
    },
    pickUpCoordinates: {
      "lat": coordinates.lat,
      "lng": coordinates.lng
    },
    destinationCoordinates: {
      "lat": coordinates2.lat,
      "lng": coordinates2.lng
    },
    goodsImage: [...goodsImage, JSON.stringify(compressedFile)],
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFinalFormData(formData);
    setShowPreview(true);
  };

  const resolveTrip = (response) => {
    console.log(response, "this shows it works");
    setShowtripEst(true);
    setDistance(response?.rows[0]?.elements[0]?.distance?.text);
    setDistanceVal(response?.rows[0]?.elements[0]?.distance?.value);
    setDuration(response?.rows[0]?.elements[0]?.duration?.text);
  };

  // const resolveTrip = async (e) => {
  //   setShowtripEst(true);
  //   setLoadingEst(true);
  //   try {
  //     const res = await axios.get(
  //       `https://maps.googleapis.com/maps/api/distancematrix/json?units=metric&origins=${coordinates.lat},${coordinates.lng}&destinations=${coordinates2.lat},${coordinates2.lng}&key=AIzaSyDqaACTEI6Is63g-O3c3QIXKgqGYNId-x0`
  //     );
  //     console.log(res.data);
  //     setDistance(res?.data?.rows[0]?.elements[0]?.distance?.text);
  //     setDistanceVal(res?.data?.rows[0]?.elements[0]?.distance?.value);
  //     setDuration(res?.data?.rows[0]?.elements[0]?.duration?.text);
  //     setLoadingEst(false);
  //     setResolved(true);
  //   } catch (error) {
  //     console.log(error);
  //       toast.error('unable to retrieve trip estimate, try again later.', {
  //         position: toast.POSITION.TOP_CENTER
  //     })
  //   }
  // };

  function SubmitButton() {
    if (
      receiversName &&
      receiversPhone &&
      pickUpState &&
      pickUpAd &&
      destAd &&
      dType &&
      expressDelivery &&
      destState &&
      pickUpDate &&
      pickUpTime &&
      reqTitle &&
      goodsImage &&
      titledDesc
    ) {
      return (
        <button onClick={handleSubmit} type="submit">
          Create Request
        </button>
      );
    } else {
      return (
        <button disabled type="submit">
          Create Request
        </button>
      );
    }
  }

  // function ResolveButton() {
  //   if (
  //     receiversName &&
  //     receiversPhone &&
  //     pickUpState &&
  //     pickUpAd &&
  //     destAd &&
  //     destState &&
  //     pickUpDate &&
  //     pickUpTime &&
  //     reqTitle &&
  //     goodsImage &&
  //     titledDesc
  //   ) {
  //     return (
  //       <p className="get-trip-est" onClick={resolveTrip}>
  //         get trip estimate
  //       </p>
  //     );
  //   } else if (distance && duration) {
  //     return null;
  //   } else {
  //     return null;
  //   }
  // }

  const lowerBoundValue = (
    data?.data?.data[0].pricePerKilometer.minPrice *
    (distanceVal / 1000)
  ).toFixed();

  const upperBoundValue = (
    data?.data?.data[0].pricePerKilometer.maxPrice *
    data?.data?.data[0].wehaulConstantValue *
    (distanceVal / 1000)
  ).toFixed();

  const previewTemp = [
    {
      label: "Receiver's Name",
      value: receiversName,
    },
    {
      label: "Receiver's Phone Number",
      value: receiversPhone,
    },
    {
      label: "Pick up Address",
      value: pickUpAd,
    },
    {
      label: "Destination Address",
      value: destAd,
    },
    {
      label: "Delivery Type",
      value: dType
    },
    {
      label: "Express Delivery",
      value: expressDelivery
    },
    {
      label: "Sender's name",
      value: sendersName,
    },
    {
      label: "Sender's phone",
      value: sendersPhone,
    },

    {
      label: "Pickup State",
      value: pickUpState,
    },
    {
      label: "Destination State",
      value: destState,
    },
    {
      label: "Vehicle Type",
      value: "truck",
    },
    {
      label: "Pickup Date",
      value: pickUpDate,
    },
    {
      label: "Pickup Time",
      value: pickUpTime,
    },
    {
      label: "Request title",
      value: reqTitle,
    },
    {
      label: "Trip Estimate",
      value: `N${parseInt(lowerBoundValue).toLocaleString()} - N${parseInt(
        upperBoundValue
      ).toLocaleString()}`,
    },
  ];

  const handleCompressedUpload = (e) => {
    const blob = URL.createObjectURL(e.target.files[0]);
    setGoodsImagePrev(blob);

    setGoodsImage([...goodsImage, blob]);
    const image = e.target.files[0];
    new Compressor(image, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // setppUploadBtn(true);
        // compressedResult has the compressed file.
        setCompressedFile(compressedResult);
        console.log(compressedResult);
      },
    });
  };

  const makeTruckRequest = async (formData, history) => {
    setLoader(true)
    try {
      const res = await axios.post(`${baseUrl}/admin/request/make-truck-request`, formData, config);
      toast.success(res.data.message, {
       position: toast.POSITION.TOP_CENTER
    })
    setLoader(false)
    console.log(res.data)
    window.location.href = '/dashboard/request'
    } catch(error) {
       if(error) {
          toast.error(error.response.data.message, {
             position: toast.POSITION.TOP_CENTER
          })
       }
       setLoader(false)
    }
 }

 function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

  return (
    <div className="truck-request">
      <form onSubmit={handleSubmit}>
        <div className="field-div">
          <label htmlFor="pickup-state">Pickup State</label>
          <br />
          <select
            className="field select"
            value={pickUpState}
            onChange={(e) => setPickUpState(e.target.value)}
            required
            name="pickup-state"
          >
            <option>--select pickup state--</option>
            {states.map((state, index) => {
              return (
                <option key={index} value={state}>
                  {capitalizeFirstLetter(state)}
                </option>
              );
            })}
          </select>
        </div>

        <div className="field-div">
          <label htmlFor="destination-state">Destination state</label>
          <br />
          <select
            className="field select"
            value={destState}
            onChange={(e) => setDestState(e.target.value)}
            required
            name="destination-state"
          >
            <option>--select destination state--</option>
            {states.map((state, index) => {
              return (
                <option key={index} value={state}>
                  {capitalizeFirstLetter(state)}
                </option>
              );
            })}
          </select>
        </div>

        <AutoComplete2
          setCoordinates={setCoordinates}
          setCoordinates2={setCoordinates2}
          setPickUpAd={setPickUpAd}
          setDestAd={setDestAd}
          vehicleData={data?.data?.data[2]}
        />

        <div className="field-div">
          <label htmlFor="vehicle-type">Truck Type</label>
          <br />
          <select
            className="field select"
            onChange={(e) => setTruckType(e.target.value)}
            required
            type="text"
            name="vehicle-type"
          >
            <option>--select Truck type--</option>
            {data?.data?.data.map(
              ({
                pricePerKilometer,
                vehicleType,
                _id: id,
                wehaulCommission,
                wehaulConstant,
              }) => {
                return (
                  <option
                    key={id}
                    value={vehicleType}
                  >
                    {vehicleType}
                  </option>
                );
              }
            )}
          </select>
        </div>

        <div className="field-div">
          <label htmlFor="pickup-date">Pickup Date</label>
          <br />
          <input
            className="field"
            min={new Date().toISOString().split("T")[0]}
            value={pickUpDate}
            onChange={(e) => setPickUpDate(e.target.value)}
            required
            type="date"
            name="pickup-date"
          />
        </div>

        <div className="field-div">
          <label htmlFor="pickup-time">Pickup-time</label>
          <br />
          <input
            className="field"
            onChange={(e) => setPickUpTime(e.target.value)}
            value={pickUpTime}
            required
            type="time"
            name="pickup-time"
          />
        </div>

        <div className="field-div">
          <label htmlFor="customer-name">Receiver's name</label>
          <br />
          <input
            className="customer-name field"
            value={receiversName}
            onChange={(e) => setReceiversName(e.target.value)}
            required
            name="customer-name"
            id=""
          />
        </div>

        <div className="field-div">
          <label htmlFor="phone-number">Receiver's Phone Number</label>
          <br />
          <input
            className="field"
            value={receiversPhone}
            onChange={(e) => setReceiversPhone(e.target.value)}
            required
            type="tel"
            name="phone-number"
          />
        </div>

        <div className="field-div">
          <label htmlFor="sender-name">Sender's name</label>
          <br />
          <input
            type="text"
            className="sender-name field"
            name="sender-name"
            value={sendersName}
            onChange={(e) => setSendersName(e.target.value)}
            required
          ></input>
        </div>

        <div className="field-div">
          <label htmlFor="phone-number">Sender's Phone Number</label>
          <br />
          <input
            className="field"
            type="tel"
            value={sendersPhone}
            name="phone-number"
            onChange={(e) => setSendersPhone(e.target.value)}
            required
          />
        </div>

        <div className="field-div">
          <label htmlFor="request-title">Request Title</label>
          <br />
          <input
            className="field"
            type="text"
            value={reqTitle}
            onChange={(e) => setReqTitle(e.target.value)}
            required
            name="request-title"
          />
        </div>

        <div className="field-div">
          <label htmlFor="titled-desc">Titled Description</label>
          <br />
          <textarea
            className="field"
            name="titled-desc"
            maxLength="200"
            id=""
            cols="30"
            rows="10"
            value={titledDesc}
            onChange={(e) => setTitledDesc(e.target.value)}
            required
          ></textarea>
        </div>

        <div className="field-div">
          <label htmlFor="d-type">Delivery Type</label>
          <select className="field" onChange={e => setDType(e.target.value)} required name="d-type">
            <option selected disabled>Select delivery type</option>
              {dTypes.map((d, index) => {
                return (
                  <option key={index} value={d}>{d}</option>
                )
              })}  
          </select>

          <div className="field-div">
            <label htmlFor="express">Express Delivery?</label>
            <div className="field">
            
            <input onChange={e => setExpressDelivery(e.target.value)} value="true" name="opt" type="radio" />
            <label style={{margin: "-5px 20px 0 7px", transform: "translateY(-5px)"}} htmlFor="yes">Yes</label>
            
            <input onChange={e => setExpressDelivery(e.target.value)} value="false" name="opt" type="radio" />
            <label style={{margin: "-5px 20px 0 7px", transform: "translateY(-5px)"}} htmlFor="yes">No</label>
          </div>
          </div>

        </div>

        <div className="img-upload field-div">
          <div className="selector">
            <div className="action">
              <input
                type="file"
                onChange={(e) => handleCompressedUpload(e)}
                accept="image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8"
                id="file"
              />
              <i className="fas fa-upload"></i>
              <p>Upload goods image here</p>
            </div>
            <div
              style={{
                height: "142px",
                borderRadius: "0 0 5px 5px",
                backgroundImage: `url(${goodsImagePrev})`,
                backgroundSize: "cover",
              }}
              className="preview"
            ></div>
          </div>
        </div>

        {showTripEst ? (
          <div className="field-div trip-estimate">
            <p className="titlex">Trip Estimate</p>
            {loadingEst ? (
              <TLoader />
            ) : (
              <div className="deets">
                <p className="info">
                  <i className="fas fa-map-marker-alt"></i>{" "}
                  <span className="value">{pickUpAd}</span>
                </p>
                <p className="info">
                  <i className="fas fa-map-marker-alt"></i>{" "}
                  <span className="value">{destAd}</span>
                </p>
                <p className="info">
                  <i className="fas fa-route"></i>
                  <span className="value">{distance}</span>
                </p>
                <p className="info">
                  <i className="fas fa-stopwatch"></i>
                  <span className="value">{duration}</span>
                </p>
                <p className="range">
                  N{parseInt(lowerBoundValue).toLocaleString()} - N
                  {parseInt(upperBoundValue).toLocaleString()}
                </p>
              </div>
            )}
          </div>
        ) : null}

        {/* <ResolveButton /> */}

        <DistanceMatrixService
          options={{
            destinations: [{ lat: coordinates2.lat, lng: coordinates2.lng }],
            origins: [{ lng: coordinates.lng, lat: coordinates.lat }],
            travelMode: "DRIVING",
          }}
          callback={resolveTrip}
        />

        <div className="field-div boton">
          <SubmitButton />
        </div>
      </form>

      {showPreview && (
        <RequestPreview
          icon="fas fa-truck"
          requestFunction={makeTruckRequest}
          vehicleType="truck"
          formData={finalFormData}
          lowerBoundValue={lowerBoundValue}
          setShowPreview={setShowPreview}
          previewTemp={previewTemp}
          setPhoneNumber={setPhoneNumber}
          setPickUpAd={setPickUpAd}
          setDestAd={setDestAd}
          setPickUpState={setPickUpState}
          setDestState={setDestState}
          setPickUpDate={setPickUpDate}
          setPickUpTime={setPickUpTime}
          setReqTitle={setReqTitle}
          setTitledDesc={setTitledDesc}
          loading={loader}
        />
      )}
    </div>
  );
};

export default TruckRequest;
