import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './header.scss';
import Avatar from './avatar.svg';
import { uiActionTypes } from '../../redux/constants/uiActionTypes'
import { useDispatch } from 'react-redux'
import UserModal from './userModal/userModal';
import { Link } from 'react-router-dom';
import axios from 'axios';
import io from 'socket.io-client';

let socket;

const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
       }
 }

const Header = ({navOpen, setNavOpen, setMobileNavOpen}) => {
    const currentUser = useSelector(({userData}) => userData.currentUser)
    
    // redux will be needed for this to globally store UI state
    // const [menuOpen, setMenuOpen] = useState(true);

    const [ showUserModal, setShowUserModal] = useState(false);
    const [notifs, setNotifs] = useState([]);
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    const getNotifs = async () => {
        try {
            const {data} = await axios.get(`https://wehaul-nig.herokuapp.com/api/v1/notifications`, config)
            console.log('header notif', data)
            setNotifs(data.data)
            setLoading(false)
        } catch (error) {
            console.log(error?.response)
        }
    }

    useEffect(() => {
        socket = io("https://wehaul-nig.herokuapp.com")
        socket.on("connect", () => {
            console.log(socket.connected)
        })
        // return () => {
        //     socket.off()
        // }  
      }, []);

      useEffect(() => {
        socket.on("newNotification", getNotifs)
      }, [])

    useEffect(() => {
        getNotifs()
     }, [])

    const handleMenuClicks = () => {
        setMobileNavOpen(true)
        setNavOpen(prev => !prev)
    }

    return ( 
        <div className="header">
           <div className="name-menu">
               <div onClick={handleMenuClicks} className="menu">
                   <div className="line"></div>
                   <div className="line move"></div>
                   <div className="line"></div>
               </div>
               <p className="name">Welcome {currentUser ? currentUser?.data?.name : 'user'}</p>
           </div>
           {/* <div className="search">
               <div className="input">
                   <input type="text" placeholder='Search' />
                   <i className="fas fa-search"></i>
               </div>
           </div> */}
           <div className="notif-profile">
               <div className="notifs">
                 <Link to="/dashboard/ticket"><div className="notif-badge"><i className="fas fa-comments"></i></div></Link>
                 {/* <Link style={{textDecoration: "none"}} to="/dashboard/notifications"><div className="notif-badge"><i className="far fa-bell"></i>{!loading && <p className="badge">{notifs.filter(n => n.isOpened === false).length}</p>}</div></Link> */}
               </div>
               <div className="profile" onClick={() => setShowUserModal(prev => !prev)}>
                   <div className="img" style={{backgroundImage: `url(${currentUser? currentUser?.data?.profilePicture : null})`, backgroundSize: 'cover'}}></div>
                   <div className="deets">
                       <p className="user-name">{currentUser ? currentUser?.data?.name : 'user'}</p>
                       <p className="user-email">{currentUser ? currentUser?.data?.email : 'email'}</p>
                   </div>
               </div>
           </div>
           { showUserModal && <UserModal setShowUserModal={setShowUserModal} />}
        </div>
        
     );
}
 
export default Header;