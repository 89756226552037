import React from "react";
import "./newModal.scss";
import ButtonLoader from "../buttonLoader/buttonLoader";
import { useState } from "react";
import Payment from "../payments/payment";
import { useSelector } from "react-redux";
import axios from "axios";
import {baseUrl} from '../../config.json';
import { toast } from "react-toastify";
import { Spinner } from "@chakra-ui/react";

const config = {
  headers: {
     "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
     }
}

const NewModal = ({setShowDeleteModal, loading, text, requestFunction, type, depositAmount, driverId, userClass, reqId, customerId}) => {
  const [pType, setPType] = useState("");
  const [agree, setAgree] = useState(false)

  const currentUser = useSelector(({ userData }) => userData.currentUser);

  const { walletBalance, _id: id, email } = currentUser.data;

  const [loading2, setLoading2] = useState(false);

  const formData = {
    amount: depositAmount,
    feeWithCommission: depositAmount,
    userClass: userClass,
    isPromoUsed: false
}

  const selectDriver = async() => {
    setLoading2(true)
    try {
      const {data} = await axios.post(`${baseUrl}/admin/request/${reqId}/select-driver/${driverId}/customer/${customerId}`, formData, config)
      console.log(data)
      toast.success(data?.message)
      window.location.href = "/dashboard/trips"
    } catch(error) {
      console.log(error?.response?.data?.message)
    } finally {
      setLoading2(false)
    }
  }

  function Button() {
       if(!agree) {
          // return <Payment driverId={driverId} reqId={reqId} userClass={userClass} userId={id} userEmail={email} depositAmount={depositAmount} />
          return <button disabled type="submit">CONTINUE</button>
      // } else if(pType === "earnings" && agree) {
      //     return <button onClick={requestFunction} type="submit">{loading && <ButtonLoader />}CONTINUE</button>
      } else return <button onClick={selectDriver} type="submit">{loading2 ? <Spinner /> : "CONTINUE"}</button>
  }

  const processData = async (e) => {
        e.preventDefault()
        if(pType === "paystack") {

        } else if(pType === "earnings") {

        } else return
  }

  return (
    <div className="new-modal">
      <div className="wrapper">
        <div className="modal">
          <div className="top-head">
            <i className="fas fa-times" onClick={() => setShowDeleteModal(false)}></i>
            <div className="left">
              <p className="title">Assign Driver</p>
              <p className="para">Select this driver for for the trip.</p>
            </div>
            <div className="right">
              <p className="label">Price</p>
              <p className="value">₦{depositAmount.toLocaleString()}</p>
            </div>
          </div>
          <form onSubmit={processData} className="body">
            {/* <div className="input">
              <input onClick={() => setPType("earnings")} name="type" type="radio" />
              <div className="diff">
                <label>Pay from referral earnings balance</label>{" "}
              </div>
            </div>
            <div className="input">
              <input onClick={() => setPType("paystack")} name="type" type="radio" />
              <label>Pay with paystack</label>
            </div> */}
            <br />
            <div className="bodyx">
                            <p className="text">This action is permanent, Do you want to proceed?</p>
                            {type === "assign" && <>
                            {/* <p className='ctext'>Cancelling this request later may result in you being charged a small amount for driver compensation. Do you agree?</p> */}
                            <br />
                            <input type="checkbox" name='agree' required onChange={() => setAgree(prev => !prev)} />
                            <label htmlFor="agree" className="agree">I Agree</label>
                            </>}
                            {/* <div className="btns">
                                <button onClick={() => setShowDeleteModal(false)} className="cancel">CANCEL</button>
                                <button className='payx'>PAY NOW</button> */}
                                {/* <Payment driverId={driverId} reqId={reqId} userClass={userClass} userId={_id} userEmail={email} depositAmount={depositAmount}  /> */}
                                {/* <button onClick={requestFunction} className="continue">{loading ? <ButtonLoader /> : text}</button> */}
                            {/* </div> */}
                        </div>
            <div className="btn">
              <Button />
            </div>
          </form>
         
        </div>
      </div>
    </div>
  );
};

export default NewModal;
