// import axios from 'axios';
import React, { useState } from 'react';
import PlacesAutoComplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
// import { getAllVehicles } from '../../services/routeServices';
import './autocomplete.scss';

const AutoComplete = ({vehicleData, setCoordinates, setCoordinates2, setPickUpAd, setDestAd, pickUpAd, destAd}) => {

    // console.log(vehicleData)
    // const [vehicleType, setVehicleType] = useState('');
    // const [vehiclePPK, setVehiclePPK] = useState('');
    // const [vehicleConstant, setVehicleConstant] = useState('');

    // wehaul fare calculation
    // const [estFare, setEstFare] = useState('');

    const [address, setAddress] = useState('');

    const [address2, setAddress2] = useState('');

    // const { data: datax, error, isLoading } = useQuery('getAllVehicles', getAllVehicles)
    // if(isLoading) {
    //   console.log('loading all vehicles')
    // } else if(datax) {
    //     datax.data.data.map(i => {
    //         if(i.vehicleType === vehicle) {
    //            setVehicleType(i.vehicleType)
    //            setVehiclePPK(i.pricePerKilometer)
    //            setVehicleConstant(i.wehaulConstantValue)
    //         } 
    //     })
    //     console.log('gotten all vehicles', datax.data.data)
    // } else {
    //     console.log(error)
    // }

    const handleSelect = async value => {
        const results = await geocodeByAddress(value);
        const ll = await getLatLng(results[0]);
        console.log(ll)
        console.log(results)
        setAddress(value)
        setPickUpAd(value)
        setCoordinates(ll)

    }

    const handleSelect2 = async value => {
        const results2 = await geocodeByAddress(value);
        const ll2 = await getLatLng(results2[0]);
        console.log(ll2)
        setAddress2(value)
        setDestAd(value)
        setCoordinates2(ll2)
    }

    // function deg2rad(deg) {
    //     return deg * (Math.PI/180)
    //   }

    // const getDistance = () => {
    //     let p = 0.017453292519943295;    // Math.PI / 180
    //     let c = Math.cos;
    //     let a = 0.5 - c((coordinates2.lat - coordinates.lat) * p)/2 +
    //             c(coordinates.lat * p) * c(coordinates2.lat * p) *
    //             (1 - c((coordinates2.lng -coordinates.lng * p))/2);

    //             let final = 12742 * Math.asin(Math.sqrt(a));  // 2 * R; R = 6371 km
    //             console.log(a)

    //             var R = 6371; // Radius of the earth in km
    //             var dLat = deg2rad(coordinates2.lat-coordinates.lat);  // deg2rad below
    //             var dLon = deg2rad(coordinates2.lng-coordinates.lng); 
    //             var a = 
    //               Math.sin(dLat/2) * Math.sin(dLat/2) +
    //               Math.cos(deg2rad(coordinates.lat)) * Math.cos(deg2rad(coordinates2.lat)) * 
    //               Math.sin(dLon/2) * Math.sin(dLon/2)
    //               ; 
    //             var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    //             var d = R * c; // Distance in km
    //             console.log(d)
    // }

    // const getDistance = async e => {
    //     try {
    //         const { data } = await axios.get(`https://maps.googleapis.com/maps/api/distancematrix/json?units=metric&origins=${coordinates.lat},${coordinates.lng}&destinations=${coordinates2.lat},${coordinates2.lng}&key=AIzaSyA8kxgU38wJpJWddYFJszURwJ9xkE2L1NQ`)
    //         console.log(data.rows[0].elements[0]);
    //         setDistance(data.rows[0].elements[0].distance.text);
    //         setDistanceVal(data.rows[0].elements[0].distance.value);
    //         setDuration(data.rows[0].elements[0].duration.text);
    //     } catch (error) {
    //         console.log(error)
    //         toast.error('unable to retrieve trip estimate, try again later.', {
    //             position: toast.POSITION.TOP_CENTER
    //         })
    //     }
    // }



    return ( 
        <div className="auto-complete">

          <p className="title">Create New Route</p>
           <div className="field-div">
          <label htmlFor="Pickup-Address">New Route</label>
           <PlacesAutoComplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
        searchOptions={
            {componentRestrictions: {
              country: 'nga'
            }}
          }
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div key={suggestions.description}>
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { cursor: 'pointer',
                      transition: "250ms ease",
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "#fff",
                      backgroundColor: "#113E82",
                      padding: "5px 0",
                      display: "flex"
                    }
                  : { backgroundColor: '#fff', 
                      transition: "250ms ease",
                      cursor: 'pointer',
                      fontSize: "15px",
                      color: "#000 !important",
                      fontWeight: "500",
                      boxShadow: "2px 4px 8px #ffffff",
                      padding: "5px 0",
                      display: "flex"
                    };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={suggestions.description}
                  >
                    <i style={{margin: "auto 10px auto 0", paddingLeft: "5px", fontSize: '.8em'}} className="fas fa-map-marker-alt"></i><span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutoComplete>
      </div>
{/* 
           <p>Address: {address2}</p>
           <p>latitude: {coordinates2.lat}</p>
           <p>longitude: {coordinates2.lng}</p> */}

           {/* <div className="field-div">
          <label htmlFor="Pickup-Address">Destination Address</label> */}
           {/* <PlacesAutoComplete
        value={address2}
        onChange={setAddress2}
        onSelect={handleSelect2}
        searchOptions={
            {componentRestrictions: {
              country: 'nga'
            }}
          }
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div key={suggestions.description}>
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                ? { cursor: 'pointer',
                    transition: "250ms ease",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "#fff",
                    backgroundColor: "#113E82",
                    padding: "5px 0",
                    display: "flex"
                  }
                : { backgroundColor: '#ffffff', 
                    transition: "250ms ease",
                    cursor: 'pointer',
                    fontSize: "15px",
                    fontWeight: "500",
                    boxShadow: "2px 4px 8px #ffffff",
                    padding: "5px 0",
                    display: "flex"
                  };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={suggestion.description}
                  >
                    <i style={{margin: "auto 10px auto 0", paddingLeft: "5px", fontSize: '.8em'}} className="fas fa-map-marker-alt"></i><span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutoComplete> */}
      {/* </div> */}

      {/* <div className="trip-estimate">
          <p>pickup address: {address}</p>
          <p>destination address: {address2}</p>
          <p>Distance: {distance}</p>
          <p>Duration: {duration}</p>
          <p>Estimated fare: {vehicleData ? (vehicleData.wehaulConstantValue * vehicleData.pricePerKilometer * (distanceVal/1000)).toLocaleString() : 'error getting fare'}</p>
      </div>

        <button onClick={getDistance}>get km</button> */}
        </div>
     );
}
 
export default AutoComplete;