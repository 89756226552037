import { Box, Button, Divider, Flex, FormControl, FormLabel, Input, Select, Spinner, Text, useDisclosure } from "@chakra-ui/react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {devUrl, baseUrl} from '../../config.json';
import LeadsTable from "./components/leadsTable";
const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
       }
}

const Leads = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [checkView, setCheckView] = useState(false);
    const [addView, setAddView] = useState(true);
    const [detailsView, setDetailsView] = useState(false);

    const [countries, setCountries] = useState([]);

    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [country, setCountry] = useState("");

    useEffect(() => {
        axios.get(`${baseUrl}/admin/retrieve-countries`, config)
        .then(res => setCountries(res?.data?.data))
        .catch(err => console.log(err))
        .finally(setLoading(false))
    }, [loading2])

    

    const datax = {
        name,
        // email,
        phoneNumber,
        country
    }

    const addLead = async(e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const {data} = await axios.post(`${baseUrl}/admin/add-lead`, datax, config)
            console.log(data)
            toast.success(data?.message)
            onClose()
        } catch (error) {
            console.log(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Box>
            <Flex border={"1px solid #c4dcff"} mt="4" alignItems={"center"} justifyContent="space-between" bg="#fff" padding={".7rem 1rem"} borderRadius="6px">
                <Text fontWeight={"600"} fontSize="1.2rem" color="#113E82">Leads</Text>
                <Button onClick={onOpen} fontSize={"13px"} bg="#29805a" color="#fff">Add Lead</Button>
            </Flex>

            <LeadsTable refresh={loading} />

            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent margin={"0 .5rem"}>
                <ModalHeader color="#113E82">Add Lead</ModalHeader>
                <ModalCloseButton _focus={{boxShadow: "none"}} />
                <ModalBody>
                    {checkView && 
                    <form>
                        <FormControl>
                            <FormLabel>Check Phone Number</FormLabel>
                            <Input type="tel" placeholder="Enter Lead Phone Number" _placeholder={{fontSize: ".9rem"}} />
                        </FormControl>
                        <Flex pb={3} justifyContent={"flex-end"}>
                            <Button marginTop={"4rem"} type="submit" fontSize={"14px"} colorScheme={"blue"}>{loading ? <Spinner /> : "Check"}</Button>
                        </Flex>
                    </form>
                    }
                    {addView && 
                        <form onSubmit={addLead}>
                            <FormControl mb={3}>
                                <FormLabel transform={"translateY(8px)"}>Name</FormLabel>
                                <Input onChange={e => setName(e.target.value)} type="text" placeholder="Enter Lead Name" _placeholder={{fontSize: ".9rem"}} />
                            </FormControl>
                            <FormControl mb={3}>
                                <FormLabel transform={"translateY(8px)"}>Email</FormLabel>
                                <Input onChange={e => setEmail(e.target.value)} type="email" placeholder="Enter Lead Email" _placeholder={{fontSize: ".9rem"}} />
                            </FormControl>
                            <FormControl mb={3}>
                                <FormLabel transform={"translateY(8px)"}>Phone</FormLabel>
                                <Input onChange={e => setPhoneNumber(e.target.value)} type="tel" minLength={11} maxLength={11} placeholder="Enter Lead Phone Number" _placeholder={{fontSize: ".9rem"}} />
                            </FormControl>
                            <FormControl>
                                <FormLabel transform={"translateY(8px)"}>Country</FormLabel>
                                <Select onChange={e => setCountry(e.target.value)} fontSize=".9rem">
                                    {!loading2 &&
                                    countries?.map(({name}, index) => {
                                        return (
                                            <option key={index} value={name}>{name}</option>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            <Flex pb={3} justifyContent={"flex-end"}>
                                <Button marginTop={"4rem"} type="submit" fontSize={"14px"} colorScheme={"blue"}>{loading ? <Spinner /> : "Add Lead"}</Button>
                            </Flex>
                        </form>
                    }
                    {detailsView && 
                    <Box pb={3}>
                        <Flex mb={2} gap="1rem" alignItems={"center"}>
                            <Text>Name:</Text>
                            <Text>John Doe</Text>
                        </Flex>
                        <Flex mb={2} gap="1rem" alignItems={"center"}>
                            <Text>Email:</Text>
                            <Text>JohnDoe@gmail.com</Text>
                        </Flex>
                        <Flex mb={2} gap="1rem" alignItems={"center"}>
                            <Text>Phone:</Text>
                            <Text>07067548839</Text>
                        </Flex>
                        <Flex mb={2} gap="1rem" alignItems={"center"}>
                            <Text>Already a Customer?:</Text>
                            <Text>No</Text>
                        </Flex>
                        <Flex pb={2} justifyContent={"flex-end"}>
                            <Button marginTop={"4rem"} type="submit" fontSize={"14px"} colorScheme={"blue"}>{loading ? <Spinner /> : "Proceed To Request"}</Button>
                        </Flex>
                    </Box>
                    }
                </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default Leads;