import React from "react";
import "./lineLoader.css";

const LineLoader = () => {
  return (
    <div className="line-loader">
        <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    </div>
    
  );
};

export default LineLoader;
