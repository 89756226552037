import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDQbWZRzhSp584IQqlSre-GzFNu-4l2AAk",
    authDomain: "wehaul-3a62e.firebaseapp.com",
    projectId: "wehaul-3a62e",
    storageBucket: "wehaul-3a62e.appspot.com",
    messagingSenderId: "570006213811",
    appId: "1:570006213811:web:fba6c4a090f4c62773ccc6",
    measurementId: "G-T8YEVY1LEJ"
  };



  firebase.initializeApp(firebaseConfig)

  export const auth = firebase.auth();
  export const firestore = firebase.firestore();


  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({prompt: "select_account"});
  export const signInWithGoogle = () => auth.signInWithPopup(provider);

  export default firebase;