import React from 'react';
import './withdrawal.scss';
import Withdrawallist from '../../../static/withdrawals';
import RippleLoader from '../../rippleLoader/rippleLoader';
import EmptyState from '../../emptyState/emptyState';

const Withdrawals = ({data}) => {
    return ( 
        <div className="withdrawals">
            {data?.length === 0 ? <EmptyState /> : 
            <div className="cover">
            <p className='top-title'>Recent Withdrawal</p>
            <table>
                <tr className='label-row'>
                    <th>Name</th>
                    <th>User Type</th>
                    <th>Origin</th>
                    <th>Destination</th>
                    <th>Vehicle Type</th>
                    <th>Pickup Date</th>
                    <th>Driver Amount</th>
                    <th>WH Amount</th>
                </tr>
                    
                    {data?.map(({ driverAmount, name, wehaulAmount, pickUpDate, origin, destination, vehicleType, user, userType, userId, _id: id }, index) => {
                        return (
                            <tr className='content-row' key={id}>
                               <td>{user}</td>
                               <td>{userType}</td>
                               <td>{origin}</td>
                               {/* {status === 'unpaid' && <td><p className="unpaid">{status}</p></td>}
                               {status === 'paid' && <td><p className="paid">{status}</p></td>} */}
                               <td>{destination}</td>
                               <td>{vehicleType}</td>
                               <td>{pickUpDate}</td>
                               <td>N{driverAmount.toLocaleString()}</td>
                               <td>{wehaulAmount}</td>
                            </tr>
                        )
                    })}
            </table>
            </div>
            }
            
            
        </div>
     );
}
 
export default Withdrawals;