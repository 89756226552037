import React, { useState } from "react";
import "./routes.scss";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import Overview from "../../components/dashboard-components/overview/overview";
import { getAllRoutes } from "../../services/routeServices";
// import AddRoute from "./addRouteModal/addRoute";
import LineLoader from "../../components/lineLoader/lineLoader";
import Loader from "../../components/loader/loader";
import { useSelector } from "react-redux";
// import AddOtherRoutes from "./otherRoutes/addOtherRouteModal/addOtherRoute";
// import AddTruckStates from "./truckStates/addTruckStatesModal/addTruckStates";
import { toast } from "react-toastify";
import { baseUrl } from "../../config.json";
import axios from "axios";
import AddRoute from "./addRouteModal/addRoute";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`,
  },
};

const Routes = () => {
  const [showModal, setShowModal] = useState(false);

  const currentUser = useSelector(({userData}) => userData.currentUser)
    console.log(currentUser?.data)
    const { userClass } = currentUser?.data


  const { data, isLoading, error } = useQuery("getRoutes", getAllRoutes);
  if (isLoading) {
    console.log("getting routes");
  } else if (data) {
    console.log(data.data.data);
  } else {
    console.log(error);
  }

  const deleteRoute = async (id) => {
    console.log(id);
    try {
      const { data } = await axios.delete(
        `${baseUrl}/admin/delete-route/${id}`,
        config
      );
      console.log(data);
      toast.success(data.message)
      window.location.reload();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  

  return (
    <div className="routes">
      <div className="heading">
        <h1 className="title">Available Routes</h1>
        {userClass === "superAdmin" && <div className="right-items">
            <p onClick={() => window.location.href = '/dashboard/create-route'} className="add-action">
          <i className="fas fa-plus"></i>Add Routes
        </p>
        </div>}
        
      </div>

      <table>
        <tr className="label-row">
          <th>Name</th>
          <th>Description</th>
          <th></th>
        </tr>
        {isLoading ? (
          <LineLoader />
        ) : (
          data?.data?.data.map(({ name, description, _id: id }, index) => {
            return (
              <tr className="content-row" key={index}>
                <td>{name}</td>
                <td>{description}</td>
                <td></td>
                <td>
                 {userClass === "superAdmin" && <i
                    className="far fa-trash-alt"
                    onClick={() => deleteRoute(id)}
                  ></i>}
                </td>
              </tr>
            );
          })
        )}
      </table>

      {showModal && <AddRoute setShowModal={setShowModal} />}
    </div>
  );
};

export default Routes;
