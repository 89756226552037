import React from 'react';
import './buttonLoader.css';

const BattonLoader = () => {
    return ( 
            <div className="lds-ringx"><div></div><div></div><div></div><div></div></div>
    
     );
}
 
export default BattonLoader;